import { Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import i18n, { i18nRoute } from '@/plugins/i18n';

export class EntityDetailView extends Vue {
  @Getter
  protected isBooting!: boolean;

  @Watch('isBooting')
  @Watch('$route', { immediate: true })
  protected onRouteChanged() {
    if (!this.isBooting && !this.entity) {
      // NOTE(abres): The problem here was that we are lazy-loading some of the parts like posts, they are mostly not in if the original isBooting stops.
      // Therefore I disabled it.
      // this.$router.push(i18nRoute({ name: 'home' }));
    }
  }

  protected get entity(): object | undefined {
    return undefined;
  }
}
