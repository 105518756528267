import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { RobotModel, ManufacturerModel, UseCaseModel } from '@/models';
import i18n, { i18nRoute } from '@/plugins/i18n';
import { debounce } from 'typescript-debounce-decorator';

import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import filter from 'lodash/filter';

export interface IScored {
  robot: RobotModel;
  value: number;
}

const containing = (set: any[], subset: any[]) => intersection(set, subset).length > 0;

@Component<ToolRobotCompare>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.tool-robot-compare.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.tool-robot-compare.meta-description').toString() }],
    };
  },
})
class ToolRobotCompare extends Vue {
  protected readonly MAX_RESULTS = 10;

  @Getter
  protected isBooting!: boolean;

  @Getter
  protected robotBySlug!: (slug: string) => RobotModel;

  @Getter
  protected robotsByQuery!: (q: string) => RobotModel[];

  @Getter
  protected manufacturersByQuery!: (q: string) => ManufacturerModel[];

  @Getter
  protected robotsByManufacturer!: (id: number) => RobotModel[];

  @Getter
  protected robotsByKind!: (kind: string) => RobotModel[];

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Getter
  protected useCaseByID!: (id: number) => UseCaseModel[];

  @Getter
  protected useCaseBySlug!: (slug: string) => UseCaseModel;

  @Getter
  protected robotsByUseCase!: (id: number) => RobotModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected query = '';
  protected isOpen = false;
  protected isLoading = false;
  protected isCalculating = false;
  protected selectedRobots: RobotModel[] = [];
  protected robots: RobotModel[] = [];
  protected noSuggestions = true;
  protected storedSuggestions: IScored[] = [];

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.tools.breadcrumb.index').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'tools' }),
      },
      {
        text: this.$t('view.tools.breadcrumb.robot-compare').toString(),
        disabled: true,
        href: i18nRoute({ name: 'tools-compare-robots' }),
      },
    ];
  }

  protected get comparisonHeaders() {
    return [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Height (cm)', value: 'height' },
      { text: 'Width (cm)', value: 'width' },
      { text: 'Length (cm)', value: 'length' },
      { text: 'Weight (kg)', value: 'weight' },
      { text: 'Max. Payload (kg)', value: 'maxPayload' },
      { text: 'Avg. Speed (m/s)', value: 'avgSpeed' },
      { text: 'Max. Speed (m/s)', value: 'maxSpeed' },
      { text: 'CE Cert', value: 'ceCertificate' },
      { text: 'Frost Env', value: 'frostEnvironment' },
      { text: 'Clean Room', value: 'cleanRoom' },
      { text: 'Outdoor', value: 'outdoor' },
    ];
  }

  protected addRobot(robot: RobotModel) {
    this.selectedRobots.push(robot);
    this.createSuggestions();
  }

  @Watch('query')
  @debounce(200, { leading: false })
  protected onQueryChanged() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 2;
    if (this.isOpen) {
      this.isLoading = true;
      this.robots = this.robotsByQuery(q);
      const manufacturers = this.manufacturersByQuery(q);
      manufacturers.forEach(x => this.robots.push(...this.robotsByManufacturer(x.id)));
      this.robots = this.removeDuplicates(this.robots);
      this.isLoading = false;
    }
  }

  @debounce(1000, { leading: false })
  protected countResults(results: []) {
    const count = results && results.length > this.MAX_RESULTS ? `${this.MAX_RESULTS}+` : results.length;
    return { count };
  }

  @debounce(300, { leading: false })
  protected onFocus() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 4;
  }

  @debounce(300, { leading: false })
  protected onBlur() {
    this.isOpen = false;
  }

  protected createSuggestions() {
    if (this.selectedRobots.length == 0) {
      return;
    }
    this.isCalculating = true;
    const latest = this.selectedRobots[this.selectedRobots.length - 1];
    // first filter
    // const filtered = filter(this.robots, r => containing(r.useCases, latest.useCases));
    const filtered = this.robots;
    // now scoring
    const scored: Array<IScored> = [];
    filtered.forEach(x => scored.push({ robot: x, value: latest.dna(x) }));
    this.isCalculating = false;
    if (scored.length == 0) {
      this.noSuggestions = true;
    } else {
      this.noSuggestions = false;
    }
    const suggestions = orderBy(
      filter(scored, s => s.robot.slug !== latest.slug && this.selectedRobots.findIndex(e => e.id == s.robot.id) == -1),
      ['value'],
      ['desc'],
    );
    console.log(this.storedSuggestions);
    this.storedSuggestions = suggestions.slice(0, 10);
  }

  protected removeDuplicates(array: RobotModel[]) {
    const seen = new Set();
    return array.filter(item => {
      const id = item['id'];
      if (!seen.has(id)) {
        seen.add(id);
        return true;
      }
      return false;
    });
  }
}

export default ToolRobotCompare;
