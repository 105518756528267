import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/impression/';

export const TYPE_ROBOT = 'robot';
export const TYPE_MANUFACTURER = 'manufacturer';
export const TYPE_PRODUCT = 'product';
export const TYPE_COMPANY = 'company';

export interface IImpression {
  // type of entity
  type: string;
  // id of entity
  id: number;
}

class ImpressionModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new ImpressionModel(data));
  }

  public static async create(data: IImpression) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new ImpressionModel(resp.data);
  }
  constructor(protected data: IImpression) {}

  public get rawData() {
    return this.data;
  }
}

export default ImpressionModel;
