import { Vue, Component, Prop } from 'vue-property-decorator';

import { ManufacturerModel, ClickModel } from '@/models';
import { TYPE_MANUFACTURER, IClick } from '@/models/click';

@Component
class TileManufacturer extends Vue {
  @Prop()
  protected manufacturer!: ManufacturerModel;

  @Prop()
  protected type!: string;

  @Prop({ type: String, default: 'none' })
  protected source!: string;

  protected executeClick() {
    const query: IClick = {
      // source of search
      source: this.source,
      // robot
      type: TYPE_MANUFACTURER.toString(),
      id: this.manufacturer.id,
    };
    ClickModel.create(query);
  }
}

export default TileManufacturer;
