import Vue from 'vue';

export default Vue.extend({
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      titleTemplate: '%s - Lots of Bots',
    };
  },
});
