import { Vue, Component, Prop } from 'vue-property-decorator';
import { ServiceModel } from '@/models';

import { Getter } from 'vuex-class';

@Component
class TileServiceDictionary extends Vue {
  @Prop()
  protected country!: string;

  @Prop()
  protected code!: string;

  @Getter
  protected services!: ServiceModel[];
}

export default TileServiceDictionary;
