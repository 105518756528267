import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';

import cloneDeep from 'lodash/cloneDeep';

import { LOCALES } from '@/constants/i18n';
import i18n from '@/plugins/i18n';
import { VuetifyForm } from '@/plugins/vuetify';
import countries from 'i18n-iso-countries';
import { getCountryName } from '@/plugins/i18n';
import map from 'lodash/map';
import rules from '@/utils/validation';
import CompanyModel, { ICompany } from '@/models/company';
import { ManufacturerModel, RobotModel, ServiceModel } from '@/models';
import { KIND_COMPANY_SERVICE, KIND_COMPANY_COMPONENT } from '@/models/company';

const MSG_FORM_INVALID = 'component.company-edit-form.notification.form-invalid';
const MSG_SUCCESS = 'component.company-edit-form.notification.success';

@Component
class CompanyEditForm extends Vue {
  protected readonly LOCALES = LOCALES;
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  @Prop({ required: true })
  protected company!: CompanyModel;

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Getter
  protected services!: ServiceModel[];

  protected isValid = false;
  protected isLoading = false;

  protected data!: ICompany;
  // workaround because unfortunately 'data.country' does not update on custom form components
  // actually it is recommended to avoid objects, but we are going with it for now
  protected country!: string;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Action
  protected updateCompany!: (payload: { company: CompanyModel; data: ICompany }) => void;

  @Watch('company', { immediate: true })
  protected onCompanyChanged() {
    this.data = cloneDeep(this.company.rawData);
    this.country = this.data.country;
  }

  protected get allCountries() {
    const codes = countries.getAlpha3Codes();
    return map(codes, code => ({ id: code, label: getCountryName(code) }));
  }

  protected get allRobots() {
    return map(this.robots, robot => ({ id: robot.id, label: robot.name + ' (' + this.manufacturerOf(robot) + ')' }));
  }

  protected manufacturerOf(robot: RobotModel) {
    const manufacturer = this.manufacturerByID(robot.manufacturer);
    if (manufacturer) {
      return manufacturer.name;
    }
    return '';
  }

  protected get allServices() {
    return map(this.services, service => ({ id: service.id, label: service.title }));
  }

  protected get isServiceCompany() {
    return this.company.kind === KIND_COMPANY_SERVICE;
  }

  protected get isComponentCompany() {
    return this.company.kind === KIND_COMPANY_COMPONENT;
  }

  protected async save() {
    if (this.form.validate()) {
      this.isLoading = true;
      await this.updateCompany({
        company: this.company,
        data: { ...this.data, country: this.country },
      });

      this.setSnackbarNotification(i18n.t(MSG_SUCCESS).toString());

      this.isLoading = false;
      this.company.goto();
    } else {
      this.setSnackbarNotification(i18n.t(MSG_FORM_INVALID).toString());
    }
  }
}

export default CompanyEditForm;
