import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';
import { debounce } from 'typescript-debounce-decorator';

import map from 'lodash/map';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';

import { CompanyModel, ProductModel, CategoryModel, RobotModel, ManufacturerModel, SearchModel } from '@/models';
import { KIND_COMPANY_COMPONENT } from '@/models/company';
import { KIND_PRODUCT_ROBOT, KIND_PRODUCT_INFRASTRUCTURE } from '@/models/product';
import { ISearch, SOURCE_PRODUCTFINDER } from '@/models/search';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

interface IFilters {
  categories: number[];
  robots: number[];
  isRobot: boolean;
  isInfrastructure: boolean;
}

const FILTER_DEFAULTS: IFilters = {
  categories: [],
  robots: [],
  isRobot: false,
  isInfrastructure: false,
};

@Component<ProductFinder>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.product-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.product-finder.meta-description').toString() }],
    };
  },
})
class ProductFinder extends Vue {
  @Getter
  protected ut!: string;

  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected products!: ProductModel[];

  @Getter
  protected categories!: CategoryModel[];

  @Getter
  protected categoriesByKind!: CategoryModel[];

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  // Search Results
  protected results: ProductModel[] = [];

  // Search Filters
  protected filters: IFilters = clone(FILTER_DEFAULTS);

  // Sections
  protected showFilterServices = true;
  protected showFilterCategories = true;
  protected showFilterKind = true;

  @Action
  protected getProducts!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getCategories!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getProducts();
    this.getCompanies();
    this.getCategories();
    this.getAds();
  }

  @Watch('products', { immediate: true })
  @Watch('filters', { deep: true })
  protected search() {
    let results = this.products;

    // category of product
    if (this.filters.categories.length) {
      results = filter(results, r => containsFully(r.category, this.filters.categories));
    }

    if (!(this.filters.isRobot && this.filters.isInfrastructure)) {
      if (this.filters.isRobot) {
        results = filter(results, r => r.kind === KIND_PRODUCT_ROBOT);
      }
      if (this.filters.isInfrastructure) {
        results = filter(results, r => r.kind === KIND_PRODUCT_INFRASTRUCTURE);
      }
    }

    if (this.filters.isRobot && this.filters.robots.length > 0) {
      results = filter(results, r => containsFully(r.robots, this.filters.robots));
    }

    this.results = results;
    this.trackSearch();
  }

  protected get robotCategories() {
    return filter(this.categories, r => r.kind === KIND_PRODUCT_ROBOT);
  }

  protected get infrastructureCategories() {
    return filter(this.categories, r => r.kind === KIND_PRODUCT_INFRASTRUCTURE);
  }

  protected get allRobots() {
    return map(this.robots, robot => ({ id: robot.id, label: robot.name + ' (' + this.manufacturerOf(robot) + ')' }));
  }

  protected manufacturerOf(robot: RobotModel) {
    const manufacturer = this.manufacturerByID(robot.manufacturer);
    if (manufacturer) {
      return manufacturer.name;
    }
    return '';
  }

  protected get companySpotlight() {
    return filter(this.companies, m => m.isSpotlight && m.kind === KIND_COMPANY_COMPONENT);
  }

  protected clearFilters() {
    this.filters = clone(FILTER_DEFAULTS);
  }

  @debounce(1000, { leading: false })
  protected trackSearch() {
    const query: ISearch = {
      // source of search
      source: SOURCE_PRODUCTFINDER.toString(),
      ut: this.ut,
      // robot
      robots: [],
      manufacturers: [],
      useCases: [],
      ceCertificate: false,
      tuvCertificate: false,
      minPayload: 0,
      maxPayload: 0,
      minWidth: 0,
      maxWidth: 0,
      minHeight: 0,
      maxHeight: 0,
      minLength: 0,
      maxLength: 0,
      minWeight: 0,
      maxWeight: 0,
      cleanRoom: false,
      frostEnvironment: false,
      outdoor: false,
      followMe: false,
      hasSense: false,
      minSpeed: 0,
      maxSpeed: 0,
      minBatteryLifetime: 0,
      maxBatteryLifetime: 0,
      // product
      productIsRobot: this.filters.isRobot,
      productIsInfrastructure: this.filters.isInfrastructure,
      productCategories: this.filters.categories,
      products: map(this.results, r => r.id),
      productSelectedRobots: this.filters.robots,
      // service
      services: [],
      serviceCompanies: [],
      serviceCountries: [],
      // results
      normalResults: this.results.length,
      premiumResults: 0,
      goldResults: 0,
    };
    SearchModel.create(query);
  }
}

export default ProductFinder;
