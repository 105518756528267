import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';

import { EntityDetailView } from '@/utils/views';
import { RobotModel, ManufacturerModel, UserModel, ImpressionModel } from '@/models';
import { IImpression, TYPE_ROBOT } from '@/models/impression';

@Component<Robot>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.name : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Robot extends EntityDetailView {
  @Getter
  protected user!: UserModel;

  @Getter
  protected robotBySlug!: (slug: string) => RobotModel;

  @Getter
  protected robotsByManufacturer!: (id: number) => RobotModel[];

  @Getter
  protected manufacturerBySlug!: (slug: string) => ManufacturerModel;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  @Action
  protected getUseCases!: () => void;

  protected get entity(): RobotModel | undefined {
    const { robotSlug } = this.$route.params;
    if (this.manufacturer !== undefined) {
      const robot = this.robotBySlug(robotSlug);
      return robot && robot.manufacturer === this.manufacturer.id ? robot : undefined;
    }
    return undefined;
  }

  protected get manufacturer(): ManufacturerModel | undefined {
    const { manufacturerSlug } = this.$route.params;
    return this.manufacturerBySlug(manufacturerSlug);
  }

  protected get robots() {
    if (this.manufacturer && this.entity) {
      const robots = this.robotsByManufacturer(this.manufacturer!.id);
      return filter(robots, r => r.id !== this.entity!.id);
    }
    return [];
  }

  protected get isEditor() {
    this.executeImpression();
    return this.entity && this.manufacturer && this.user && this.user.manufacturers.includes(this.manufacturer.id);
  }

  protected get editMode() {
    return this.isEditor && 'edit' in this.$route.query;
  }

  protected executeImpression() {
    if (this.entity) {
      const query: IImpression = {
        type: TYPE_ROBOT.toString(),
        id: this.entity.id,
      };
      ImpressionModel.create(query);
    }
  }
}

export default Robot;
