import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { UserModel, ManufacturerModel, CompanyModel, RobotModel, ProductModel } from '@/models';
import { i18nRoute } from '@/plugins/i18n';
import map from 'lodash/map';
import AnalyticsModel from '@/models/analytics';

@Component<DashboardAnalytics>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.dashboard.title').toString(),
    };
  },
})
class DashboardAnalytics extends Vue {
  @Getter
  protected isAuthenticated!: boolean;

  @Getter
  protected isBooting!: boolean;

  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected editableManufacturers!: ManufacturerModel[];

  @Getter
  protected robotsByManufacturer!: (id: number) => RobotModel[];

  @Getter
  protected productsByCompany!: (id: number) => ProductModel[];

  @Getter
  protected robotByID!: (id: number) => RobotModel;

  @Getter
  protected productByID!: (id: number) => ProductModel;

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected products!: ProductModel[];

  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected editableCompanies!: CompanyModel[];

  @Getter
  protected user!: UserModel;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected robotData: AnalyticsModel[] = [];
  protected manufacturerData: AnalyticsModel[] = [];
  protected productData: AnalyticsModel[] = [];
  protected companyData: AnalyticsModel[] = [];

  protected isLoading = true;

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.dashboard.breadcrumb.index').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'dashboard' }),
      },
      {
        text: this.$t('view.dashboard.breadcrumb.analytics').toString(),
        disabled: true,
        href: i18nRoute({ name: 'dashboard-analytics' }),
      },
    ];
  }

  protected async mounted() {
    this.loadData();
  }

  protected get managesOneManufacturer() {
    return this.editableManufacturers.length === 1;
  }

  protected get managesOneCompany() {
    return this.editableCompanies.length === 1;
  }

  protected get managesManufacturers() {
    return this.editableManufacturers.length > 0;
  }

  protected get managesCompanies() {
    return this.editableCompanies.length > 0;
  }

  protected get manufacturer() {
    return this.editableManufacturers.length > 0 ? this.editableManufacturers[0] : null;
  }

  protected get company() {
    return this.editableCompanies.length > 0 ? this.editableCompanies[0] : null;
  }

  protected get manufacturerGoldTelephone() {
    if (this.manufacturerData.length === 0) {
      return 0;
    }
    let cnt = this.manufacturerData[0].eventTelephone;
    this.robotData.forEach(element => (cnt += element.eventTelephone));
    return cnt;
  }

  protected get manufacturerGoldWebsite() {
    if (this.manufacturerData.length === 0) {
      return 0;
    }
    let cnt = this.manufacturerData[0].eventWebsite;
    this.robotData.forEach(element => (cnt += element.eventWebsite));
    return cnt;
  }

  protected get companyGoldTelephone() {
    if (this.companyData.length === 0) {
      return 0;
    }
    let cnt = this.companyData[0].eventTelephone;
    this.productData.forEach(element => (cnt += element.eventTelephone));
    return cnt;
  }

  protected get companyGoldWebsite() {
    if (this.companyData.length === 0) {
      return 0;
    }
    let cnt = this.companyData[0].eventWebsite;
    this.productData.forEach(element => (cnt += element.eventWebsite));
    return cnt;
  }

  protected get manufacturerFinderStats() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    const list: { name: string; impressions: number; share: string }[] = [];
    if (!this.manufacturer) {
      return [];
    }
    const name = this.manufacturer.name;
    let share = 0;
    if (this.manufacturerData[0].searchImpressions > 0) {
      share = this.manufacturerData[0].searchPremium / this.manufacturerData[0].searchImpressions;
    }
    list.push({
      name: name,
      impressions: this.manufacturerData[0].searchImpressions,
      share: (share * 100).toFixed(2),
    });
    for (let i = 0; i < this.robotData.length; i++) {
      const r = this.robotByID(this.robotData[i].id);
      let share = 0;
      if (this.robotData[i].searchImpressions > 0) {
        share = (this.robotData[i].searchPremium / this.robotData[i].searchImpressions) * 100;
      }
      list.push({
        name: r.name,
        impressions: this.robotData[i].searchImpressions,
        share: share.toFixed(2),
      });
    }
    return list;
  }

  protected get companyFinderStats() {
    if (this.companyData.length === 0) {
      return [];
    }
    const list: { name: string; impressions: number; share: string }[] = [];
    if (!this.company) {
      return [];
    }
    const name = this.company.name;
    let share = 0;
    if (this.companyData[0].searchImpressions > 0) {
      share = this.companyData[0].searchPremium / this.companyData[0].searchImpressions;
    }
    list.push({
      name: name,
      impressions: this.companyData[0].searchImpressions,
      share: (share * 100).toFixed(2),
    });
    for (let i = 0; i < this.productData.length; i++) {
      const r = this.productByID(this.productData[i].id);
      let share = 0;
      if (this.productData[i].searchImpressions > 0) {
        share = (this.productData[i].searchPremium / this.productData[i].searchImpressions) * 100;
      }
      list.push({
        name: r.name,
        impressions: this.productData[i].searchImpressions,
        share: share.toFixed(2),
      });
    }
    return list;
  }

  protected get manufacturerImpressionStats() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    const list: { name: string; impressions: number }[] = [];
    if (!this.manufacturer) {
      return [];
    }
    const name = this.manufacturer.name;
    list.push({
      name: name,
      impressions: this.manufacturerData[0].impressions,
    });
    for (let i = 0; i < this.robotData.length; i++) {
      const r = this.robotByID(this.robotData[i].id);
      list.push({ name: r.name, impressions: this.robotData[i].impressions });
    }
    return list;
  }

  protected get companyImpressionStats() {
    if (this.companyData.length === 0) {
      return [];
    }
    const list: { name: string; impressions: number }[] = [];
    if (!this.company) {
      return [];
    }
    const name = this.company.name;
    list.push({
      name: name,
      impressions: this.companyData[0].impressions,
    });
    for (let i = 0; i < this.productData.length; i++) {
      const r = this.productByID(this.productData[i].id);
      list.push({ name: r.name, impressions: this.productData[i].impressions });
    }
    return list;
  }

  protected get manufacturerClickStats() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    const list: { name: string; clicks: number }[] = [];
    if (!this.manufacturer) {
      return [];
    }
    const name = this.manufacturer.name;
    list.push({
      name: name,
      clicks: this.manufacturerData[0].allClicks,
    });
    for (let i = 0; i < this.robotData.length; i++) {
      const r = this.robotByID(this.robotData[i].id);
      list.push({ name: r.name, clicks: this.robotData[i].allClicks });
    }
    return list;
  }

  protected get companyClickStats() {
    if (this.companyData.length === 0) {
      return [];
    }
    const list: { name: string; clicks: number }[] = [];
    if (!this.company) {
      return [];
    }
    const name = this.company.name;
    list.push({
      name: name,
      clicks: this.companyData[0].allClicks,
    });
    for (let i = 0; i < this.productData.length; i++) {
      const r = this.productByID(this.productData[i].id);
      list.push({ name: r.name, clicks: this.productData[i].allClicks });
    }
    return list;
  }

  protected get manufacturerGoldTelephoneEvents() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    const list: { name: string; cnt: number }[] = [];
    if (!this.manufacturer) {
      return [];
    }
    const name = this.manufacturer.name;
    list.push({
      name: name,
      cnt: this.manufacturerData[0].eventTelephone,
    });
    for (let i = 0; i < this.robotData.length; i++) {
      const r = this.robotByID(this.robotData[i].id);
      list.push({ name: r.name, cnt: this.robotData[i].eventTelephone });
    }
    return list;
  }

  protected get companyGoldTelephoneEvents() {
    if (this.companyData.length === 0) {
      return [];
    }
    const list: { name: string; cnt: number }[] = [];
    if (!this.company) {
      return [];
    }
    const name = this.company.name;
    list.push({
      name: name,
      cnt: this.companyData[0].eventTelephone,
    });
    for (let i = 0; i < this.productData.length; i++) {
      const r = this.productByID(this.productData[i].id);
      list.push({ name: r.name, cnt: this.productData[i].eventTelephone });
    }
    return list;
  }

  protected get manufacturerGoldWebsiteEvents() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    const list: { name: string; cnt: number }[] = [];
    if (!this.manufacturer) {
      return [];
    }
    const name = this.manufacturer.name;
    list.push({
      name: name,
      cnt: this.manufacturerData[0].eventWebsite,
    });
    for (let i = 0; i < this.robotData.length; i++) {
      const r = this.robotByID(this.robotData[i].id);
      list.push({ name: r.name, cnt: this.robotData[i].eventWebsite });
    }
    return list;
  }

  protected get companyGoldWebsiteEvents() {
    if (this.companyData.length === 0) {
      return [];
    }
    const list: { name: string; cnt: number }[] = [];
    if (!this.company) {
      return [];
    }
    const name = this.company.name;
    list.push({
      name: name,
      cnt: this.companyData[0].eventWebsite,
    });
    for (let i = 0; i < this.productData.length; i++) {
      const r = this.productByID(this.productData[i].id);
      list.push({ name: r.name, cnt: this.productData[i].eventWebsite });
    }
    return list;
  }

  protected get manufacturerClickSources() {
    if (this.manufacturerData.length === 0) {
      return [];
    }
    let company = this.manufacturerData[0].clicksCompany;
    let finder = this.manufacturerData[0].clicksFinder;
    let homepage = this.manufacturerData[0].clicksHomepage;
    let products = this.manufacturerData[0].clicksProducts;
    let related =
      this.manufacturerData[0].clicksRelated +
      this.manufacturerData[0].clicksWaku +
      this.manufacturerData[0].clicksUsecase +
      this.manufacturerData[0].clicksCategory;
    let tools = this.manufacturerData[0].clicksTools;

    for (let i = 0; i < this.robotData.length; i++) {
      company += this.robotData[i].clicksCompany;
      finder += this.robotData[i].clicksFinder;
      homepage += this.robotData[i].clicksHomepage;
      products += this.robotData[i].clicksProducts;
      related += this.robotData[i].clicksRelated + this.robotData[i].clicksWaku + this.robotData[i].clicksUsecase;
      tools += this.robotData[i].clicksTools;
    }

    return [
      { source: 'company', clicks: company },
      { source: 'finder', clicks: finder },
      { source: 'homepage', clicks: homepage },
      { source: 'products', clicks: products },
      { source: 'related', clicks: related },
      { source: 'tools', clicks: tools },
    ];
  }

  protected get companyClickSources() {
    if (this.companyData.length === 0) {
      return [];
    }
    let company = this.companyData[0].clicksCompany;
    let finder = this.companyData[0].clicksFinder;
    let homepage = this.companyData[0].clicksHomepage;
    let products = this.companyData[0].clicksProducts;
    let related =
      this.companyData[0].clicksRelated +
      this.companyData[0].clicksWaku +
      this.companyData[0].clicksUsecase +
      this.companyData[0].clicksCategory +
      this.companyData[0].clicksCategory;
    let tools = this.companyData[0].clicksTools;

    for (let i = 0; i < this.productData.length; i++) {
      company += this.productData[i].clicksCompany;
      finder += this.productData[i].clicksFinder;
      homepage += this.productData[i].clicksHomepage;
      products += this.productData[i].clicksProducts;
      related +=
        this.productData[i].clicksRelated +
        this.productData[i].clicksWaku +
        this.productData[i].clicksUsecase +
        this.productData[i].clicksCategory;
      tools += this.productData[i].clicksTools;
    }

    return [
      { source: 'company', clicks: company },
      { source: 'finder', clicks: finder },
      { source: 'homepage', clicks: homepage },
      { source: 'products', clicks: products },
      { source: 'related', clicks: related },
      { source: 'tools', clicks: tools },
    ];
  }

  protected get manufacturerOverallClicks() {
    if (this.manufacturerData.length === 0) {
      return 0;
    }
    let cnt = this.manufacturerData[0].allClicks;
    for (let i = 0; i < this.robotData.length; i++) {
      cnt += this.robotData[i].allClicks;
    }
    return cnt;
  }

  protected get manufacturerOverallImpressions() {
    if (this.manufacturerData.length === 0) {
      return 0;
    }
    let cnt = this.manufacturerData[0].impressions;
    for (let i = 0; i < this.robotData.length; i++) {
      cnt += this.robotData[i].impressions;
    }
    return cnt;
  }

  protected get companyOverallImpressions() {
    if (this.companyData.length === 0) {
      return 0;
    }
    let cnt = this.companyData[0].impressions;
    for (let i = 0; i < this.productData.length; i++) {
      cnt += this.productData[i].impressions;
    }
    return cnt;
  }

  protected get companyOverallClicks() {
    if (this.companyData.length === 0) {
      return 0;
    }
    let cnt = this.companyData[0].allClicks;
    for (let i = 0; i < this.productData.length; i++) {
      cnt += this.productData[i].allClicks;
    }
    return cnt;
  }

  @Watch('isBooting')
  protected loadData() {
    if (this.isBooting === false) {
      if (this.editableManufacturers.length > 0) {
        this.loadManufacturerData([this.editableManufacturers[0].id]);
        const robots = this.robotsByManufacturer(this.editableManufacturers[0].id);
        this.loadRobotData(map(robots, robot => robot.id));
      }
      if (this.editableCompanies.length > 0) {
        if (this.editableCompanies[0]) {
          this.loadCompanyData([this.editableCompanies[0].id]);
          const products = this.productsByCompany(this.editableCompanies[0].id);
          this.loadProductData(map(products, product => product.id));
        }
      }
      this.isLoading = false;
    }
  }

  protected async loadManufacturerData(ids: number[]) {
    if (ids.length > 0) {
      const results = await AnalyticsModel.get('manufacturer', ids);
      this.manufacturerData = results;
    }
  }

  protected async loadRobotData(ids: number[]) {
    if (ids.length > 0) {
      const results = await AnalyticsModel.get('robot', ids);
      this.robotData = results;
    }
  }

  protected async loadProductData(ids: number[]) {
    if (ids.length > 0) {
      const results = await AnalyticsModel.get('product', ids);
      this.productData = results;
    }
  }

  protected async loadCompanyData(ids: number[]) {
    if (ids.length > 0) {
      const results = await AnalyticsModel.get('company', ids);
      this.companyData = results;
    }
  }
}

export default DashboardAnalytics;
