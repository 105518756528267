import { Vue, Component } from 'vue-property-decorator';

import { LANGUAGES } from '@/constants/i18n';

@Component
class LanguageSelect extends Vue {
  protected LANGUAGES = LANGUAGES;

  protected onChangeLanguage(locale: string) {
    const { name, params, query, hash } = this.$route;

    // @ts-ignore
    const currentRoute = name.split(':')[1];
    const newName = `${locale}:${currentRoute}`;
    const route = { name: newName, params, query, hash };

    this.$router.push(route);
  }
}

export default LanguageSelect;
