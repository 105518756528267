export interface IVersionized {
  isDirty?: boolean;
  isRejected?: boolean;
  reasonRejected?: string;
}

export class VersionizedModel {
  protected data!: IVersionized;

  public get isDirty() {
    return this.data.isDirty;
  }

  public get isRejected() {
    return this.data.isRejected;
  }

  public get reasonRejected() {
    return this.data.reasonRejected;
  }
}
