import axios from '@/axios';

const API_ENDPOINT = '/api/v1/inquiries/';

export const KIND_RENT = 'rent';
export const KIND_BUY = 'buy';
export const KIND_LEASE = 'lease';

export interface IInquiry {
  kind: string;
  robot: number;
  firstName: string;
  lastName: string;
  company: string;
  message: string;
  email: string;
  phone: string;
}

class InquiryModel {
  public static async create(data: IInquiry) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new InquiryModel(resp.data);
  }

  constructor(protected data: IInquiry) {}
}

export default InquiryModel;
