import { Vue, Component, Prop } from 'vue-property-decorator';

const VARIANT_DARK_LINEAR = 'dark-linear';
const VARIANT_DARK_RADIAL = 'dark-radial';
const VARIANT_LIGHT_LINEAR = 'light-linear';
const VARIANT_LIGHT_RADIAL = 'light-radial';

const VARIANTS = [
  undefined,
  VARIANT_DARK_LINEAR,
  VARIANT_DARK_RADIAL,
  VARIANT_LIGHT_LINEAR,
  VARIANT_LIGHT_RADIAL,
  //
];

const SHADOWS = ['small', 'large'];

@Component
class LayoutSection extends Vue {
  @Prop({ type: Boolean, default: false })
  protected wide!: boolean;

  @Prop({ type: String, default: undefined, validator: value => VARIANTS.includes(value) })
  protected variant?: string | undefined;

  @Prop({ type: String, default: undefined, validator: value => SHADOWS.includes(value) })
  protected shadow!: string;

  protected get classes() {
    const classes = ['section'];

    if (this.variant !== undefined) {
      classes.push(`background-${this.variant}-gradient`);

      if (this.variant.startsWith('dark')) {
        classes.push('white--text');
      }
    }

    if (this.shadow) {
      classes.push('section-shadow-' + this.shadow);
    }

    return classes;
  }
}

export default LayoutSection;
