import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { EntityDetailView } from '@/utils/views';
import { ManufacturerModel, UserModel, ImpressionModel } from '@/models';
import { TYPE_MANUFACTURER, IImpression } from '@/models/impression';

@Component<Manufacturer>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.name : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Manufacturer extends EntityDetailView {
  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected user!: UserModel;

  @Getter
  protected manufacturerBySlug!: (slug: string) => ManufacturerModel;

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get entity() {
    const { manufacturerSlug } = this.$route.params;
    return this.manufacturerBySlug(manufacturerSlug);
  }

  protected get isEditor() {
    return this.entity && this.user && this.user.manufacturers.includes(this.entity.id);
  }

  protected get editMode() {
    this.executeImpression();
    return this.isEditor && 'edit' in this.$route.query;
  }

  protected get isGold() {
    return this.entity.isGold;
  }

  protected executeImpression() {
    const query: IImpression = {
      type: TYPE_MANUFACTURER.toString(),
      id: this.entity.id,
    };
    ImpressionModel.create(query);
  }
}

export default Manufacturer;
