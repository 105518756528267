import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import i18n, { i18nRoute, getCountryName } from '@/plugins/i18n';
import { VersionizedModel, IVersionized } from './types';

const API_ENDPOINT = '/api/v1/companies/';

export const KIND_COMPANY_SERVICE = 'service';
export const KIND_COMPANY_COMPONENT = 'component';

export interface ITranslations {
  [key: string]: {
    description: string;
  };
}

export interface ICompany extends IVersionized {
  id: number;
  slug: string;
  name: string;
  email: string;
  telephone: string;
  logo: string | File;
  kind: string;
  website: string;
  country: string;
  isClaimed: boolean;
  isPremium: boolean;
  isGold: boolean;
  isSpotlight: boolean;
  services: number[];
  robots: number[];
  countries: string[];
  translations: ITranslations;
}

class CompanyModel extends VersionizedModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new CompanyModel(data));
  }

  constructor(protected data: ICompany) {
    super();
  }

  public async update(data: ICompany) {
    const { logo, ...payload } = data;
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    if (logo instanceof File) {
      // @ts-ignore
      formData.append('logo', logo);
    }

    const resp = await axios.patch(API_ENDPOINT + this.id + '/', formData);
    this.data = resp.data;
    return this;
  }

  public get rawData() {
    return this.data;
  }

  public get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.data.slug;
  }

  public get name() {
    return this.data.name;
  }

  public get logo() {
    return this.data.logo;
  }

  public get telephone() {
    return this.data.telephone;
  }

  public get email() {
    return this.data.email;
  }

  public get kind() {
    return this.data.kind;
  }

  public get website() {
    return this.data.website;
  }

  public get websiteTracking() {
    return this.data.website + '?utm_source=lotsofbots&utm_medium=profile&utm_campaign=click';
  }

  public get call() {
    return 'tel:' + this.data.telephone;
  }

  public get country() {
    return getCountryName(this.data.country);
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public get isClaimed() {
    return this.data.isClaimed;
  }

  public get isPremium() {
    return this.data.isPremium;
  }

  public get isGold() {
    return this.data.isGold;
  }

  public get isSpotlight() {
    return this.data.isSpotlight;
  }

  public get robots() {
    return this.data.robots;
  }

  public get services() {
    return this.data.services;
  }

  public get countries() {
    return this.data.countries;
  }

  public matchesQuery(query: string) {
    return this.name && this.name.toLowerCase().includes(query);
  }

  public get route() {
    return i18nRoute({ name: 'company', params: { companySlug: this.slug } });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }

  public toString() {
    return this.name;
  }
}

export default CompanyModel;
