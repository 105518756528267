import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { RobotModel, ManufacturerModel } from '@/models';

@Component
class TileRobotTopListed extends Vue {
  @Prop()
  protected robot!: RobotModel;

  @Prop()
  protected objective!: string;

  @Prop()
  protected index!: number;

  @Prop({ type: Boolean, default: false })
  protected wide!: boolean;

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  protected get manufacturer() {
    return this.manufacturerByID(this.robot.manufacturer);
  }
}

export default TileRobotTopListed;
