import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { ManufacturerModel, UserModel, CompanyModel } from '@/models';
import { i18nRoute } from '@/plugins/i18n';

export interface IHeaderLink {
  to: string;
  title: string;
  icon: string;
}

export interface IHeaderMainLink extends IHeaderLink {
  children?: IHeaderLink[];
}

export const NAV_ROBOTS: IHeaderLink[] = [
  {
    to: 'robot-finder',
    title: 'layout.header.link.robot-finder',
    icon: 'mdi-robot-mower',
  },
  {
    to: 'robot-marketplace',
    title: 'layout.header.link.robot-marketplace',
    icon: 'mdi-store',
  },
  {
    to: 'tools-compare-robots',
    title: 'layout.header.link.compare-robots',
    icon: 'mdi-table-check',
  },
  {
    to: 'manufacturer-finder',
    title: 'layout.header.link.manufacturer',
    icon: 'mdi-factory',
  },
  {
    to: 'manufacturer-news',
    title: 'layout.header.link.manufacturer-news',
    icon: 'mdi-newspaper-variant-multiple-outline',
  },
  {
    to: 'use-cases',
    title: 'layout.header.link.use-cases',
    icon: 'mdi-package-variant-closed',
  },
  {
    to: 'interop',
    title: 'layout.header.link.interop',
    icon: 'mdi-traffic-light',
  },
  {
    to: 'care',
    title: 'layout.header.link.care',
    icon: 'mdi-wrench-clock',
  },
  {
    to: 'lob-report',
    title: 'layout.header.link.lob-report',
    icon: 'mdi-chart-box-multiple-outline',
  },
  {
    to: 'signup',
    title: 'layout.header.link.robot-add',
    icon: 'mdi-plus-box-outline',
  },
];

export const NAV_SERVICE: IHeaderLink[] = [
  {
    to: 'service-finder',
    title: 'layout.header.link.service-finder',
    icon: 'mdi-account-wrench',
  },
  {
    to: 'services',
    title: 'layout.header.link.all-services',
    icon: 'mdi-format-list-checkbox',
  },
  {
    to: 'signup',
    title: 'layout.header.link.service-add',
    icon: 'mdi-plus-box-outline',
  },
];

export const NAV_COMPONENTS: IHeaderLink[] = [
  {
    to: 'product-finder',
    title: 'layout.header.link.product-finder',
    icon: 'mdi-connection',
  },
  {
    to: 'product-company-finder',
    title: 'layout.header.link.product-companies',
    icon: 'mdi-factory',
  },
  {
    to: 'categories',
    title: 'layout.header.link.all-categories',
    icon: 'mdi-format-list-checkbox',
  },
  {
    to: 'signup',
    title: 'layout.header.link.component-add',
    icon: 'mdi-plus-box-outline',
  },
];

export const NAV_EDUCATION: IHeaderLink[] = [
  {
    to: 'blog',
    title: 'layout.header.link.magazine',
    icon: 'mdi-newspaper-variant-multiple-outline',
  },
  {
    to: 'faq',
    title: 'layout.header.link.faq',
    icon: 'mdi-frequently-asked-questions',
  },
  {
    to: 'glossary',
    title: 'layout.header.link.glossary',
    icon: 'mdi-message-processing',
  },
  {
    to: 'newsletter',
    title: 'layout.header.link.newsletter',
    icon: 'mdi-email-newsletter',
  },
  {
    to: 'whitepaper',
    title: 'layout.header.link.whitepaper',
    icon: 'mdi-chart-arc',
  },
  {
    to: 'lob-report',
    title: 'layout.header.link.lob-report',
    icon: 'mdi-chart-box-multiple-outline',
  },
];

export const NAV_CONSULTING: IHeaderLink[] = [
  {
    to: 'about',
    title: 'layout.header.link.about',
    icon: 'mdi-human-male-board-poll',
  },
  {
    to: 'consulting',
    icon: 'mdi-account-tie',
    title: 'layout.header.link.consulting',
  },
  {
    to: 'testimonials',
    icon: 'mdi-robot-happy-outline',
    title: 'layout.header.link.testimonials',
  },
  {
    to: 'planner',
    icon: 'mdi-floor-plan',
    title: 'layout.header.link.planner',
  },
  {
    to: 'tools',
    title: 'layout.header.link.tools',
    icon: 'mdi-chart-scatter-plot',
  },
];

export const NAV_HEADER_MAIN: IHeaderMainLink[] = [
  {
    to: 'robot-finder',
    title: 'layout.header.link.robots',
    icon: 'mdi-robot-mower',
    children: NAV_ROBOTS,
  },
  {
    to: 'service-finder',
    title: 'layout.header.link.service',
    icon: 'mdi-account-wrench',
    children: NAV_SERVICE,
  },
  {
    to: 'product-finder',
    title: 'layout.header.link.components',
    icon: 'mdi-connection',
    children: NAV_COMPONENTS,
  },
  {
    to: 'blog',
    title: 'layout.header.link.education',
    icon: 'mdi-school',
    children: NAV_EDUCATION,
  },
  {
    to: 'consulting',
    title: 'layout.header.link.consulting',
    icon: 'mdi-account-tie',
    children: NAV_CONSULTING,
  },
];

@Component
class LayoutHeader extends Vue {
  protected isDrawerOpen = false;

  @Getter
  protected isAuthenticated!: boolean;

  @Getter
  protected user!: null | UserModel;

  @Getter
  protected editableManufacturers!: ManufacturerModel[];

  @Getter
  protected editableCompanies!: CompanyModel[];

  @Action
  protected logout!: () => void;

  protected NAV_HEADER_MAIN = NAV_HEADER_MAIN;

  protected get passwordResetRoute() {
    return i18nRoute({ name: 'password-reset' });
  }

  protected get premiumProfileRoute() {
    return i18nRoute({ name: 'premium-profile' });
  }

  protected get magazinePromotionRoute() {
    return i18nRoute({ name: 'magazine-contact' });
  }

  protected get tenderRoute() {
    return i18nRoute({ name: 'tenders' });
  }

  protected get dashboardRoute() {
    return i18nRoute({ name: 'dashboard' });
  }

  protected get guidelinesRoute() {
    return i18nRoute({ name: 'guidelines' });
  }

  protected get sendFeedbackRoute() {
    return i18nRoute({ name: 'feedback' });
  }

  protected get managesOneManufacturer() {
    return this.editableManufacturers.length === 1;
  }

  protected get manufacturer() {
    return this.editableManufacturers.length > 0 ? this.editableManufacturers[0] : null;
  }
}

export default LayoutHeader;
