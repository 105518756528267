import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';
import shuffle from 'lodash/shuffle';
import take from 'lodash/take';

import { EntityDetailView } from '@/utils/views';
import { ServiceModel, CompanyModel } from '@/models';

@Component<Service>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.title : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Service extends EntityDetailView {
  protected readonly MAX_RESULTS = 20;

  @Getter
  protected serviceBySlug!: (slug: string) => ServiceModel;

  @Getter
  protected companiesByService!: (id: number) => CompanyModel[];

  @Action
  protected getServices!: () => void;

  @Action
  protected getCompanies!: () => void;

  protected async mounted() {
    this.getServices();
    this.getCompanies();
  }

  protected get entity() {
    const { serviceSlug } = this.$route.params;
    return this.serviceBySlug(serviceSlug);
  }

  protected get companies(): CompanyModel[] {
    return this.entity ? take(shuffle(this.companiesByService(this.entity.id)), this.MAX_RESULTS) : [];
  }
}

export default Service;
