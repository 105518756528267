import { Vue, Component, Prop } from 'vue-property-decorator';

import { UseCaseModel } from '@/models';

@Component
class TileUseCase extends Vue {
  @Prop()
  protected useCase!: UseCaseModel;
}

export default TileUseCase;
