import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<Consulting>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.consulting.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('page.consulting.meta-description').toString(),
        },
      ],
    };
  },
})
class Consulting extends Vue {
  protected jumpToForm() {
    window.open('#contact', '_self');
  }
}

export default Consulting;
