import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AdModel } from '@/models';
import take from 'lodash/take';
import filter from 'lodash/filter';

@Component
class TileAd extends Vue {
  @Getter
  protected ads!: AdModel[];

  @Prop({ required: true })
  protected spot!: string;

  // {prime, visual-only, banner}
  // prime... image on the left side, text and CTA on the right
  // sidebar-banner... sidebar - visual without text and CTA
  // banner... banner ad visual only
  @Prop({ required: true })
  protected type!: string;

  protected get show() {
    const ads = take(
      filter(
        filter(this.ads, a => a.spots.includes(this.spot)),
        a => !!a.isActive,
      ),
      1,
    );
    if (ads.length > 0) {
      return ads[0];
    }
    return null;
  }
}

export default TileAd;
