import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ProductModel, CompanyModel } from '@/models';

@Component
class ProductWizard extends Vue {
  @Prop({ required: true })
  protected product!: ProductModel | null;

  @Prop({ required: true })
  protected company!: CompanyModel;

  protected currentStep = 1;

  @Mutation
  protected setProduct!: (product: ProductModel | null) => void;

  @Watch('product', { immediate: true })
  protected onProductChanged() {
    this.setProduct(this.product);
  }

  protected next() {
    this.currentStep++;
  }

  protected prev() {
    this.currentStep--;
  }

  protected done() {
    throw new Error('not implemented yet.');
  }
}

export default ProductWizard;
