import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import UserModel, { ILoginPayload } from '@/models/user';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';
import { i18nRoute } from '@/plugins/i18n';

const MSG_LOGIN_ERROR = 'view.login.notification.error';

@Component<Login>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.login.title').toString(),
    };
  },
})
class Login extends Vue {
  protected readonly rules = rules;

  protected isValid = false;
  protected isLoading = false;

  protected email = '';
  protected password = '';

  protected snackbar = false;
  protected multiLine = false;

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Getter
  protected isAuthenticated!: boolean;

  @Action
  protected login!: (payload: ILoginPayload) => null | UserModel;

  @Watch('isAuthenticated', { immediate: true })
  protected onIsAuthenticated() {
    if (this.isAuthenticated) {
      this.$router.push(i18nRoute({ name: 'dashboard' }));
    }
  }

  protected async signIn() {
    if (this.form.validate()) {
      this.isLoading = true;

      const { email, password } = this;
      const user = await this.login({ email, password });
      if (user === null) {
        this.setSnackbarNotification(this.$t(MSG_LOGIN_ERROR).toString());
        this.snackbar = true;
      }

      this.isLoading = false;
    }
  }
}

export default Login;
