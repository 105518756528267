import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import { BlogPostModel } from '@/models';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import take from 'lodash/take';

@Component<BlogIndex>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.blog.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.blog.meta-description').toString() }],
    };
  },
})
class BlogIndex extends Vue {
  @Getter
  protected blogPosts!: BlogPostModel[];
  @Getter
  protected manufacturerNews!: BlogPostModel[];

  @Action
  protected getBlogPosts!: () => void;

  protected async mounted() {
    this.getBlogPosts();
  }

  protected get sponsoredPosts() {
    return orderBy(
      filter(this.blogPosts, m => m.isSponsored),
      '-date_published',
    );
  }

  protected get spotlightPosts() {
    return orderBy(
      filter(this.blogPosts, m => m.isSpotlight),
      '-date_published',
    );
  }

  protected get spotlightColumns() {
    if (this.spotlightPosts.length % 2 === 0) {
      return 2;
    }
    return 3;
  }

  protected get posts() {
    return orderBy(this.blogPosts, '-date_published');
  }

  protected get sidebarNews() {
    return take(orderBy(this.manufacturerNews, '-date_published'), 10);
  }
}

export default BlogIndex;
