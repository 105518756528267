import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { UseCaseModel } from '@/models';

@Component<UseCases>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.use-cases.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.use-cases.meta-description').toString() }],
    };
  },
})
class UseCases extends Vue {
  @Getter
  protected useCases!: UseCaseModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }
}

export default UseCases;
