import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { CategoryModel } from '@/models';

@Component<Categories>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.categories.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.categories.meta-description').toString() }],
    };
  },
})
class Categories extends Vue {
  protected async mounted() {
    this.getCategories();
    this.getCompanies();
  }

  @Getter
  protected categories!: CategoryModel[];

  @Action
  protected getCategories!: () => void;

  @Action
  protected getCompanies!: () => void;
}

export default Categories;
