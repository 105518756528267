import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter } from 'vuex-class';

import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

import { ManufacturerModel, UseCaseModel, RobotModel, BlogPostModel, CompanyModel } from '@/models';

const LATEST_BLOG_POSTS = 3;

@Component<Home>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.home.title').toString(),
      titleTemplate: '%s',
      meta: [{ name: 'description', content: this.$i18n.t('page.home.meta-description').toString() }],
    };
  },
})
class Home extends Vue {
  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected useCases!: UseCaseModel[];

  @Getter
  protected blogPosts!: BlogPostModel[];

  protected get posts() {
    return slice(orderBy(this.blogPosts, '-date_published'), 0, LATEST_BLOG_POSTS);
  }

  protected get spotlightPosts() {
    return orderBy(
      filter(this.blogPosts, m => m.isSpotlight),
      '-date_published',
    );
  }

  protected get sponsoredPosts() {
    return orderBy(
      filter(this.blogPosts, m => m.isSponsored),
      '-date_published',
    );
  }

  protected get homeManufacturers() {
    return filter(this.manufacturers, m => m.isGold || m.isPremium);
  }

  protected get homeCompanies() {
    return filter(this.companies, m => m.isGold || m.isPremium);
  }

  protected get carouselItems() {
    return filter(this.robots, m => m.isSpotlight);
  }

  protected get robotSpotlight() {
    return filter(this.robots, m => m.isSpotlight);
  }
}

export default Home;
