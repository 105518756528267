import { Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import RobotModel from '@/models/robot';
import { CompanyModel } from '@/models';
import { EntityDetailView } from '@/utils/views';

@Component<ProductCreate>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.product-create.title').toString(),
    };
  },
})
class ProductCreate extends EntityDetailView {
  @Getter
  protected companyBySlug!: (slug: string) => CompanyModel;

  @Action
  protected createRobot!: (data: FormData) => RobotModel;

  @Action
  protected getProducts!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getCategories!: () => void;

  protected async mounted() {
    this.getProducts();
    this.getCompanies();
    this.getCategories();
  }

  protected get entity() {
    const { companySlug } = this.$route.params;
    return this.companyBySlug(companySlug);
  }
}

export default ProductCreate;
