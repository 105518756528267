import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
class FilterSection extends Vue {
  @Prop({ type: String, required: true })
  protected headline!: string;

  @Prop({ type: Boolean, default: true })
  protected value!: boolean;

  protected toggle() {
    this.$emit('input', !this.value);
  }
}

export default FilterSection;
