import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';
import orderBy from 'lodash/orderBy';

import { BlogPostModel } from '@/models';

@Component<ManufacturerNews>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.manufacturer-news.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.manufacturer-news.meta-description').toString() }],
    };
  },
})
class ManufacturerNews extends Vue {
  @Getter
  protected manufacturerNews!: BlogPostModel[];

  @Action
  protected getManufacturerNews!: () => void;

  protected async mounted() {
    this.getManufacturerNews();
  }

  protected get posts() {
    return orderBy(this.manufacturerNews, '-date_published');
  }
}

export default ManufacturerNews;
