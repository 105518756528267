import { Vue, Component, Ref } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Mutation } from 'vuex-class';

import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';

const MSG_PASSWORD_RESET_ERROR = 'view.password_reset_email.notification.error';

@Component<PasswordReset>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.password-reset.title').toString(),
    };
  },
})
class PasswordReset extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected isLoading = false;
  protected isValid = false;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected get csrfToken() {
    const input: HTMLInputElement | null = document.querySelector('input[name="csrfmiddlewaretoken"]');
    return input === null ? '' : input.value;
  }

  protected submit() {
    if (this.form.validate()) {
      this.isLoading = true;
      this.form.$el.submit();
      this.isLoading = false;
    } else {
      this.setSnackbarNotification(this.$t(MSG_PASSWORD_RESET_ERROR).toString());
    }
  }
}

export default PasswordReset;
