import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';
import map from 'lodash/map';

import { EntityDetailView } from '@/utils/views';
import { CompanyModel, UserModel, RobotModel, ServiceModel, ImpressionModel } from '@/models';
import { getCountryName } from '@/plugins/i18n';
import { KIND_COMPANY_COMPONENT, KIND_COMPANY_SERVICE } from '@/models/company';
import { TYPE_COMPANY, IImpression } from '@/models/impression';

@Component<Company>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.name : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Company extends EntityDetailView {
  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected user!: UserModel;

  @Getter
  protected companyBySlug!: (slug: string) => CompanyModel;

  @Getter
  protected serviceByID!: (id: number) => ServiceModel;

  @Getter
  protected robotByID!: (id: number) => RobotModel;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getCategories!: () => void;

  @Action
  protected getProducts!: () => void;

  protected async mounted() {
    this.getCompanies();
    this.getCategories();
    this.getProducts();
  }

  protected get entity() {
    const { companySlug } = this.$route.params;
    return this.companyBySlug(companySlug);
  }

  protected get activeServices() {
    if (this.entity) {
      const services = map(
        map(this.entity.services, id => this.serviceByID(id)),
        service => service.title,
      );
      return filter(services, c => !!c);
    }
    return [];
  }

  protected get activeRobots() {
    if (this.entity) {
      const robots = map(this.entity.robots, id => this.robotByID(id));
      return filter(robots, c => !!c);
    }
    return [];
  }

  protected get activeCountries() {
    if (this.entity) {
      if (this.entity.countries.length > 0) {
        return map(this.entity.countries, id => getCountryName(id));
      }
    }
    return [];
  }

  protected get isEditor() {
    this.executeImpression();
    return this.entity && this.user && this.user.companies.includes(this.entity.id);
  }

  protected get editMode() {
    return this.isEditor && 'edit' in this.$route.query;
  }

  protected get isGold() {
    return this.entity.isGold;
  }

  protected get isServiceCompany() {
    return this.entity.kind === KIND_COMPANY_SERVICE;
  }

  protected get isComponentCompany() {
    return this.entity.kind === KIND_COMPANY_COMPONENT;
  }

  protected executeImpression() {
    const query: IImpression = {
      type: TYPE_COMPANY.toString(),
      id: this.entity.id,
    };
    ImpressionModel.create(query);
  }
}

export default Company;
