import { Vue, Component, Ref } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Mutation } from 'vuex-class';

import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

const MSG_PASSWORD_NOTMATCHING_ERROR = 'view.password_reset_email_confirm.notification.error';

@Component<PasswordResetConfirm>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.password-reset-confirm.title').toString(),
    };
  },
})
class PasswordResetConfirm extends Vue {
  protected readonly rules = rules;
  // @ts-ignore
  protected readonly isValidLink = window.passwordValidLink || false;
  protected isLoading = false;
  protected isValid = false;

  protected password1 = '';
  protected password2 = '';

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected get csrfToken() {
    const input: HTMLInputElement | null = document.querySelector('input[name="csrfmiddlewaretoken"]');
    return input === null ? '' : input.value;
  }

  protected submit() {
    this.form.$el.submit();
  }

  protected passwordsEqual(value: string) {
    if (value !== this.password1) {
      return this.$t(MSG_PASSWORD_NOTMATCHING_ERROR).toString();
    }
    return true;
  }
}

export default PasswordResetConfirm;
