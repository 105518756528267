var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-section',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"px-0 mx-0 mt-4 mb-12",attrs:{"items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('h1',{staticClass:"display-1 mb-4"},[_c('v-icon',[_vm._v("mdi-table-check")]),_vm._v(" "+_vm._s(_vm.$t('view.tool-robot-compare.headline'))),_c('sup',[_vm._v("BETA")])],1),_c('v-div',{domProps:{"innerHTML":_vm._s(_vm.$t('view.tool-robot-compare.description'))}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"my-6"}):_vm._e()],1)],1),_c('v-row',{attrs:{"clas":"mb-8 pb-8"}},[_c('v-col',{staticClass:"px-4",attrs:{"cols":"6"}},[_c('h2',{staticClass:"pb-4"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-numeric-1-circle")]),_vm._v(" "+_vm._s(_vm.$t('view.tool-robot-compare.input'))+"\n      ")],1),_c('div',{class:{ header__search: true, dense: _vm.dense }},[_c('v-menu',{attrs:{"value":_vm.isOpen,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"mdi-magnify","label":_vm.$t('view.tool-robot-similar.search.label'),"loading":_vm.isLoading,"outlined":"","dense":"","full-width":"","clearable":"","hide-details":""},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},on))]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.robots.slice(0, _vm.MAX_RESULTS)),function(robot){return _c('v-list-item',{key:'robot-' + robot.id,on:{"click":function($event){return _vm.addRobot(robot)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(robot.name)}})],1)}),1)],1)],1)]),_c('v-col',{staticClass:"px-4",attrs:{"cols":"5"}},[_c('h2',{staticClass:"pb-4"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-numeric-2-circle")]),_vm._v(" "+_vm._s(_vm.$t('view.tool-robot-compare.suggestions'))+"\n      ")],1),_c('p',[_vm._v("powered by Lots Of Bots "),_c('v-icon',[_vm._v("mdi-dna")]),_vm._v("RobotDNA")],1),(_vm.isCalculating)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('ul',_vm._l((_vm.storedSuggestions),function(s){return _c('li',{key:s.robot},[_c('a',{on:{"click":function($event){return _vm.addRobot(s.robot)}}},[_vm._v(_vm._s(s.robot.name))])])}),0),(_vm.noSuggestions)?_c('p',[_c('i',[_vm._v(_vm._s(_vm.$t('view.tool-robot-compare.no-suggestions')))])]):_vm._e()],1)],1),_c('v-row',{staticClass:"py-12"},[_c('v-col',{staticClass:"md-8"},[_c('v-data-table',{staticClass:"small-text-table compact-table",attrs:{"headers":_vm.comparisonHeaders,"items":_vm.selectedRobots},scopedSlots:_vm._u([{key:"item.outdoor",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.outdoor),callback:function ($$v) {_vm.$set(item, "outdoor", $$v)},expression:"item.outdoor"}})]}},{key:"item.ceCertificate",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.ceCertificate),callback:function ($$v) {_vm.$set(item, "ceCertificate", $$v)},expression:"item.ceCertificate"}})]}},{key:"item.frostEnvironment",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.frostEnvironment),callback:function ($$v) {_vm.$set(item, "frostEnvironment", $$v)},expression:"item.frostEnvironment"}})]}},{key:"item.cleanRoom",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.cleanRoom),callback:function ($$v) {_vm.$set(item, "cleanRoom", $$v)},expression:"item.cleanRoom"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"md-4"},[_c('tile-consulting')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }