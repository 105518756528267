import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { RobotModel, ManufacturerModel } from '@/models';

@Component
class RobotWizard extends Vue {
  @Prop({ required: true })
  protected robot!: RobotModel | null;

  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  protected currentStep = 1;

  @Mutation
  protected setRobot!: (robot: RobotModel | null) => void;

  @Watch('robot', { immediate: true })
  protected onRobotChanged() {
    this.setRobot(this.robot);
  }

  protected next() {
    this.currentStep++;
  }

  protected prev() {
    this.currentStep--;
  }

  protected done() {
    throw new Error('not implemented yet.');
  }
}

export default RobotWizard;
