import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';
import shuffle from 'lodash/shuffle';

import { ManufacturerModel } from '@/models';

@Component<ManufacturerFinder>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.manufacturer-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.manufacturer-finder.meta-description').toString() }],
    };
  },
})
class ManufacturerFinder extends Vue {
  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get goldManufacturers() {
    return filter(this.manufacturers, m => m.isGold);
  }

  protected get premiumManufacturers() {
    return filter(this.manufacturers, m => m.isPremium && !m.isGold);
  }

  protected get normalManufacturers() {
    return shuffle(filter(this.manufacturers, m => !m.isGold && !m.isPremium && m.logo));
  }
}

export default ManufacturerFinder;
