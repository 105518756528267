import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/search/';

export const SOURCE_ROBOTFINDER = 'robot-finder';
export const SOURCE_SERVICEFINDER = 'service-finder';
export const SOURCE_PRODUCTFINDER = 'product-finder';

export interface ISearch {
  // source of search
  source: string;
  ut: string;
  // robot
  robots: number[];
  manufacturers: number[];
  useCases: number[];
  ceCertificate: boolean;
  tuvCertificate: boolean;
  minPayload: number;
  maxPayload: number;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  minLength: number;
  maxLength: number;
  minWeight: number;
  maxWeight: number;
  cleanRoom: boolean;
  frostEnvironment: boolean;
  outdoor: boolean;
  followMe: boolean;
  hasSense: boolean;
  minSpeed: number;
  maxSpeed: number;
  minBatteryLifetime: number;
  maxBatteryLifetime: number;
  // product
  productIsRobot: boolean;
  productIsInfrastructure: boolean;
  productCategories: number[];
  products: number[];
  productSelectedRobots: number[];
  // service
  services: number[];
  serviceCompanies: number[];
  serviceCountries: string[];
  // results
  normalResults: number;
  premiumResults: number;
  goldResults: number;
}

class SearchModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new SearchModel(data));
  }

  public static async create(data: ISearch) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new SearchModel(resp.data);
  }
  constructor(protected data: ISearch) {}

  public get rawData() {
    return this.data;
  }
}

export default SearchModel;
