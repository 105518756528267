import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';
import { IVersionized, VersionizedModel } from './types';

const API_ENDPOINT = '/api/v1/tenders/';

export const KIND_TRANSPORT = 'transport';
export const KIND_MEDICAL = 'medical';
export const KIND_COMMISSIONING = 'commissioning';
export const KIND_CLEANING = 'cleaning';

export interface ITranslations {
  [key: string]: {
    description: string;
  };
}

export interface ITender extends IVersionized {
  id: number;
  slug: string;
  name: string;
  kind: string;
  file: string | File;
  manufacturers: number[];
  localization: string[];
  translations: ITranslations;
}

export interface ILocalization {
  label: string;
  value: string;
}

class TenderModel extends VersionizedModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new TenderModel(data));
  }

  public static async create(data: ITender) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new TenderModel(resp.data);
  }

  constructor(protected data: ITender) {
    super();
  }

  protected get readableKind(): string {
    switch (this.kind) {
      case KIND_TRANSPORT:
        return i18n.t('view.tenders.kind.transport').toString();
      case KIND_MEDICAL:
        return i18n.t('view.tenders.kind.medical').toString();
      case KIND_COMMISSIONING:
        return i18n.t('view.tenders.kind.commissioning').toString();
      case KIND_CLEANING:
        return i18n.t('view.tenders.kind.cleaning').toString();
      default:
        return i18n.t('view.tenders.kind.other').toString();
    }
  }

  protected get interestMail(): string {
    return i18n
      .t('view.tender.interest.button.link', {
        name: this.name,
      })
      .toString();
  }

  public async update(data: ITender) {
    const resp = await axios.patch(API_ENDPOINT + this.id + '/', data);
    this.data = resp.data;
    return this;
  }

  public get rawData() {
    return this.data;
  }

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.data.slug;
  }

  public get name() {
    return this.data.name;
  }

  public get kind() {
    return this.data.kind;
  }

  public get file() {
    return this.data.file;
  }

  public get manufacturers() {
    return this.data.manufacturers;
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public get localization() {
    return this.data.localization;
  }

  public hasLocalization(value: string) {
    return this.localization.includes(value);
  }

  public matchesQuery(query: string) {
    return this.name && this.name.toLowerCase().includes(query);
  }

  public get route() {
    return i18nRoute({ name: 'tender', params: { tenderSlug: this.slug } });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }

  public toString() {
    return this.name;
  }
}

export default TenderModel;
