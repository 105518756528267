import { Vue, Component, Prop } from 'vue-property-decorator';

import { CompanyModel } from '@/models';
import { i18nRoute } from '@/plugins/i18n';

@Component
class TileProductCreate extends Vue {
  @Prop()
  protected company!: CompanyModel;

  protected get route() {
    if (this.company) {
      return i18nRoute({ name: 'product:create', params: { companySlug: this.company.slug } });
    }
    return '';
  }
}

export default TileProductCreate;
