import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment-timezone';

@Component
class Since extends Vue {
  @Prop({ type: String, required: true })
  protected date!: string;

  @Prop({ type: Number, required: false, default: 1000 })
  protected updateInterval!: number;

  protected since = '';
  protected interval?: number;

  protected created() {
    this.update();
    this.interval = setInterval(this.update, this.updateInterval);
  }

  protected destroyed() {
    clearInterval(this.interval);
  }

  protected update() {
    this.since = moment(this.date).fromNow();
  }
}

export default Since;
