import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

import { i18nRoute } from '@/plugins/i18n';

@Component<Tools>({
  metaInfo(): MetaInfo {
    return {
      // TODO(abresk) edit here + description
      title: this.$i18n.t('page.tools.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.tools.meta-description').toString() }],
    };
  },
})
class Tools extends Vue {
  protected get basicsMenu() {
    return [
      {
        title: 'view.dashboard.tools.new.title',
        text: 'view.dashboard.tools.new.description',
        icon: 'mdi-robot-love',
        link: i18nRoute({ name: 'tools-new-robots' }),
        action: 'view.dashboard.tools.new.cta',
      },
      {
        title: 'view.dashboard.tools.compare.title',
        text: 'view.dashboard.tools.compare.description',
        icon: 'mdi-table-check',
        link: i18nRoute({ name: 'tools-compare-robots' }),
        action: 'view.dashboard.tools.compare.cta',
      },
      {
        title: 'view.dashboard.tools.similar.title',
        text: 'view.dashboard.tools.similar.description',
        icon: 'mdi-dna',
        link: i18nRoute({ name: 'tools-similar-robots-index' }),
        action: 'view.dashboard.tools.similar.cta',
      },
      {
        title: 'view.dashboard.tools.roi.title',
        text: 'view.dashboard.tools.roi.description',
        icon: 'mdi-calculator',
        link: i18nRoute({ name: 'tools-roi' }),
        action: 'view.dashboard.tools.roi.cta',
      },
      {
        title: 'view.dashboard.tools.request.title',
        text: 'view.dashboard.tools.request.description',
        icon: 'mdi-crosshairs-question',
        link: i18nRoute({ name: 'consulting' }),
        action: 'view.dashboard.tools.request.cta',
      },
    ];
  }
}

export default Tools;
