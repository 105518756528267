import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import { BlogPostModel } from '@/models';

@Component<PressIndex>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.press.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.press.meta-description').toString() }],
    };
  },
})
class PressIndex extends Vue {
  @Getter
  protected pressPosts!: BlogPostModel[];

  @Action
  protected getPressPosts!: () => void;

  protected async mounted() {
    this.getPressPosts();
  }
}

export default PressIndex;
