import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CompanyModel, ProductModel } from '@/models';

@Component
class ListProducts extends Vue {
  @Prop({ required: true })
  protected company!: CompanyModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  @Getter
  protected productsByCompany!: (id: number) => ProductModel[];

  protected get companyProducts() {
    return this.productsByCompany(this.company.id);
  }
}

export default ListProducts;
