import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { ManufacturerModel, UseCaseModel, RobotModel, EventModel } from '@/models';
import { IEvent, TYPE_ROBOT, SOURCE_PROFILE_PAGE, EVENT_WEBSITE, EVENT_TELEPHONE } from '@/models/event';

import {
  SENSORS,
  LOCALIZATIONS,
  ASSET_KIND_IMAGE,
  ASSET_KIND_YOUTUBE_VIDEO,
  ASSET_KIND_DOCUMENT,
  KIND_CLEANING,
  KIND_COMMISSIONING,
  KIND_MEDICAL,
  KIND_TRANSPORT,
} from '@/models/robot';

@Component
class RobotDetails extends Vue {
  protected readonly dialog = false;

  protected readonly SENSORS = SENSORS;
  protected readonly LOCALIZATIONS = LOCALIZATIONS;
  protected readonly ASSET_KIND_IMAGE = ASSET_KIND_IMAGE;
  protected readonly ASSET_KIND_YOUTUBE_VIDEO = ASSET_KIND_YOUTUBE_VIDEO;
  protected readonly ASSET_KIND_DOCUMENT = ASSET_KIND_DOCUMENT;
  protected readonly KIND_TRANSPORT = KIND_TRANSPORT;
  protected readonly KIND_MEDICAL = KIND_MEDICAL;
  protected readonly KIND_COMMISSIONING = KIND_COMMISSIONING;
  protected readonly KIND_CLEANING = KIND_CLEANING;

  protected isLoadingAssets = false;

  protected showTelephone = false;

  @Prop({ required: true })
  protected robot!: RobotModel;

  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  @Getter
  protected useCaseByID!: (id: number) => UseCaseModel[];

  @Watch('robot', { immediate: true })
  protected async onRobotChanged() {
    if (this.robot) {
      this.isLoadingAssets = true;
      await this.robot.listAssets();
      this.isLoadingAssets = false;
    }
  }

  protected get useCases() {
    if (this.robot) {
      const useCases = map(this.robot.useCases, id => this.useCaseByID(id));
      return filter(useCases, c => !!c);
    }
    return [];
  }

  protected switchTelephone() {
    this.executeTelephone();
    this.showTelephone = !this.showTelephone;
  }

  protected get telephoneNumber() {
    if (this.showTelephone) {
      return this.manufacturer.telephone;
    } else {
      return this.$i18n.t('view.premium.call.cta');
    }
  }

  protected setEditMode() {
    // @ts-ignore
    this.$router.push({
      ...this.robot.route,
      query: {
        edit: '1',
      },
    });
  }

  protected executeTelephone() {
    const query: IEvent = {
      type: TYPE_ROBOT.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_TELEPHONE.toString(),
      id: this.robot.id,
    };
    EventModel.create(query);
  }

  protected executeWebsite() {
    const query: IEvent = {
      type: TYPE_ROBOT.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_WEBSITE.toString(),
      id: this.robot.id,
    };
    EventModel.create(query);
  }
}

export default RobotDetails;
