import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { UserModel, ManufacturerModel, CompanyModel } from '@/models';
import { i18nRoute } from '@/plugins/i18n';

@Component<Dashboard>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.dashboard.title').toString(),
    };
  },
})
class Dashboard extends Vue {
  @Getter
  protected isAuthenticated!: boolean;

  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected editableManufacturers!: ManufacturerModel[];

  @Getter
  protected editableCompanies!: CompanyModel[];

  @Getter
  protected user!: UserModel;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getCompanies();
    this.getManufacturers();
  }

  protected get basicsMenu() {
    return [
      {
        title: 'view.dashboard.tools.new.title',
        text: 'view.dashboard.tools.new.description',
        icon: 'mdi-robot-love',
        link: i18nRoute({ name: 'tools-new-robots' }),
        action: 'view.dashboard.tools.new.cta',
      },
      {
        title: 'view.dashboard.tools.similar.title',
        text: 'view.dashboard.tools.similar.description',
        icon: 'mdi-dna',
        link: i18nRoute({ name: 'tools-similar-robots-index' }),
        action: 'view.dashboard.tools.similar.cta',
      },
      {
        title: 'view.dashboard.tools.request.title',
        text: 'view.dashboard.tools.request.description',
        icon: 'mdi-crosshairs-question',
        link: i18nRoute({ name: 'consulting' }),
        action: 'view.dashboard.tools.request.cta',
      },
      {
        title: 'view.dashboard.tools.list.title',
        text: 'view.dashboard.tools.list.description',
        icon: 'mdi-format-list-checks',
        action: 'view.dashboard.tools.list.cta',
      },
      {
        title: 'view.dashboard.tools.roi.title',
        text: 'view.dashboard.tools.roi.description',
        icon: 'mdi-calculator',
        action: 'view.dashboard.tools.roi.cta',
      },
      {
        title: 'view.dashboard.tools.workbench.title',
        text: 'view.dashboard.tools.workbench.description',
        icon: 'mdi-wrench',
        action: 'view.dashboard.tools.workbench.cta',
      },
    ];
  }

  protected get settingsMenu() {
    return [
      {
        title: 'view.dashboard.settings.password.title',
        text: 'view.dashboard.settings.password.description',
        icon: 'mdi-form-textbox-password',
        link: i18nRoute({ name: 'password-reset' }),
        action: 'view.dashboard.settings.password.cta',
      },
      {
        title: 'view.dashboard.settings.feedback.title',
        text: 'view.dashboard.settings.feedback.description',
        link: i18nRoute({ name: 'feedback' }),
        icon: 'mdi-comment-quote',
        action: 'view.dashboard.settings.feedback.cta',
      },
    ];
  }

  protected get manufacturerMenu() {
    if (this.manufacturer === null) {
      return [];
    }
    return [
      {
        title: 'view.dashboard.manufacturer.profile.title',
        text: 'view.dashboard.manufacturer.profile.description',
        icon: 'mdi-factory',
        link: i18nRoute({
          name: 'manufacturer',
          params: { manufacturerSlug: this.manufacturer.slug },
        }),
        action: 'view.dashboard.manufacturer.profile.cta',
      },
      {
        title: 'view.dashboard.manufacturer.analytics.title',
        text: 'view.dashboard.manufacturer.analytics.description',
        icon: 'mdi-google-analytics',
        link: i18nRoute({ name: 'dashboard-analytics' }),
        action: 'view.dashboard.manufacturer.analytics.cta',
      },
      {
        title: 'view.dashboard.manufacturer.premium.title',
        text: 'view.dashboard.manufacturer.premium.description',
        icon: 'mdi-podium-gold',
        link: i18nRoute({ name: 'premium-profile' }),
        action: 'view.dashboard.manufacturer.premium.cta',
      },
      {
        title: 'view.dashboard.manufacturer.interop.title',
        text: 'view.dashboard.manufacturer.interop.description',
        icon: 'mdi-home-analytics',
        link: i18nRoute({ name: 'interop' }),
        action: 'view.dashboard.manufacturer.interop.cta',
      },
      {
        title: 'view.dashboard.manufacturer.magazine.title',
        text: 'view.dashboard.manufacturer.mangazine.description',
        icon: 'mdi-post',
        link: i18nRoute({ name: 'magazine-contact' }),
        action: 'view.dashboard.manufacturer.magazine.cta',
      },
      {
        title: 'view.dashboard.manufacturer.guidelines.title',
        text: 'view.dashboard.manufacturer.guidelines.description',
        icon: 'mdi-file-sign',
        link: i18nRoute({ name: 'guidelines' }),
        action: 'view.dashboard.manufacturer.guidelines.cta',
      },
    ];
  }

  protected get companyMenu() {
    if (this.company === null) {
      return [];
    }
    return [
      {
        title: 'view.dashboard.manufacturer.profile.title',
        text: 'view.dashboard.manufacturer.profile.description',
        icon: 'mdi-factory',
        link: i18nRoute({
          name: 'company',
          params: { companySlug: this.company.slug },
        }),
        action: 'view.dashboard.manufacturer.profile.cta',
      },
      {
        title: 'view.dashboard.manufacturer.analytics.title',
        text: 'view.dashboard.manufacturer.analytics.description',
        icon: 'mdi-google-analytics',
        link: i18nRoute({ name: 'dashboard-analytics' }),
        action: 'view.dashboard.manufacturer.analytics.cta',
      },
      {
        title: 'view.dashboard.manufacturer.premium.title',
        text: 'view.dashboard.manufacturer.premium.description',
        icon: 'mdi-podium-gold',
        link: i18nRoute({ name: 'premium-profile' }),
        action: 'view.dashboard.manufacturer.premium.cta',
      },
      {
        title: 'view.dashboard.manufacturer.interop.title',
        text: 'view.dashboard.manufacturer.interop.description',
        icon: 'mdi-home-analytics',
        link: i18nRoute({ name: 'interop' }),
        action: 'view.dashboard.manufacturer.interop.cta',
      },
      {
        title: 'view.dashboard.manufacturer.magazine.title',
        text: 'view.dashboard.manufacturer.mangazine.description',
        icon: 'mdi-post',
        link: i18nRoute({ name: 'magazine-contact' }),
        action: 'view.dashboard.manufacturer.magazine.cta',
      },
      {
        title: 'view.dashboard.manufacturer.guidelines.title',
        text: 'view.dashboard.manufacturer.guidelines.description',
        icon: 'mdi-file-sign',
        link: i18nRoute({ name: 'guidelines' }),
        action: 'view.dashboard.manufacturer.guidelines.cta',
      },
    ];
  }

  protected get managesOneManufacturer() {
    return this.editableManufacturers.length === 1;
  }

  protected get managesOneCompany() {
    return this.editableCompanies.length === 1;
  }

  protected get managesManufacturers() {
    return this.editableManufacturers.length > 0;
  }

  protected get managesCompanies() {
    return this.editableCompanies.length > 0;
  }

  protected get manufacturer() {
    return this.editableManufacturers.length > 0 ? this.editableManufacturers[0] : null;
  }

  protected get company() {
    return this.editableCompanies.length > 0 ? this.editableCompanies[0] : null;
  }
}

export default Dashboard;
