import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import i18n, { i18nRoute, getCountryName } from '@/plugins/i18n';
import { VersionizedModel, IVersionized } from './types';

const API_ENDPOINT = '/api/v1/manufacturers/';

export interface ITranslations {
  [key: string]: {
    description: string;
  };
}

export interface IManufacturer extends IVersionized {
  id: number;
  slug: string;
  name: string;
  email: string;
  telephone: string;
  logo: string | File;
  website: string;
  yearFounded: number;
  country: string;
  isClaimed: boolean;
  isPremium: boolean;
  isGold: boolean;
  isSpotlight: boolean;
  translations: ITranslations;
}

class ManufacturerModel extends VersionizedModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new ManufacturerModel(data));
  }

  constructor(protected data: IManufacturer) {
    super();
  }

  public async update(data: IManufacturer) {
    const { logo, ...payload } = data;
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    if (logo instanceof File) {
      // @ts-ignore
      formData.append('logo', logo);
    }

    const resp = await axios.patch(API_ENDPOINT + this.id + '/', formData);
    this.data = resp.data;
    return this;
  }

  public get rawData() {
    return this.data;
  }

  public get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.data.slug;
  }

  public get name() {
    return this.data.name;
  }

  public get logo() {
    return this.data.logo;
  }

  public get telephone() {
    return this.data.telephone;
  }

  public get email() {
    return this.data.email;
  }

  public get website() {
    return this.data.website;
  }

  public get websiteTracking() {
    return this.data.website + '?utm_source=lotsofbots&utm_medium=profile&utm_campaign=click';
  }

  public get call() {
    return 'tel:' + this.data.telephone;
  }

  public get country() {
    return getCountryName(this.data.country);
  }

  public get yearFounded() {
    return this.data.yearFounded;
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public get isClaimed() {
    return this.data.isClaimed;
  }

  public get isPremium() {
    return this.data.isPremium;
  }

  public get isGold() {
    return this.data.isGold;
  }

  public get isSpotlight() {
    return this.data.isSpotlight;
  }

  public matchesQuery(query: string) {
    return this.name && this.name.toLowerCase().includes(query);
  }

  public get route() {
    return i18nRoute({ name: 'manufacturer', params: { manufacturerSlug: this.slug } });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }

  public toString() {
    return this.name;
  }
}

export default ManufacturerModel;
