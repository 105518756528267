import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/usecases/';

export interface ITranslations {
  [key: string]: {
    slug: string;
    title: string;
    description: string;
  };
}

export interface IUseCase {
  id: number;
  translations: ITranslations;
  robotKinds: string[];
}

class UseCaseModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new UseCaseModel(data));
  }

  constructor(protected data: IUseCase) {}

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.translations.slug;
  }

  public get title() {
    return this.translations.title;
  }

  public get robotKinds() {
    return this.data.robotKinds;
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public matchesQuery(query: string) {
    return this.title && this.title.toLowerCase().includes(query);
  }

  protected get route() {
    return i18nRoute({ name: 'use-case', params: { useCaseSlug: this.slug } });
  }

  protected get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }
}

export default UseCaseModel;
