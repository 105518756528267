import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import filter from 'lodash/filter';
import map from 'lodash/map';

import countries from 'i18n-iso-countries';
import { getCountryName } from '@/plugins/i18n';
import { ServiceModel } from '@/models';

interface Country {
  id: string;
  label: string;
}

interface IChunk {
  letter: string;
  entries: Country[];
}

@Component<ServiceDictionary>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.service-dictionary.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.service-dictionary.meta-description').toString() }],
    };
  },
})
class ServiceDictionary extends Vue {
  protected readonly ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  @Action
  protected getServices!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Getter
  protected services!: ServiceModel[];

  protected async mounted() {
    this.getCompanies();
    this.getServices();
  }

  protected get allCountries() {
    const codes = countries.getAlpha3Codes();
    return map(codes, code => ({ id: code, label: getCountryName(code) }));
  }

  protected get countriesByFirstLetters() {
    const chunks: IChunk[] = [];
    this.ALPHABET.forEach(letter => {
      chunks.push({
        letter,
        entries: filter(this.allCountries, e => e.label.toUpperCase().startsWith(letter)),
      });
    });
    return chunks;
  }
}

export default ServiceDictionary;
