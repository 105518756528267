import { Component, Watch, Ref } from 'vue-property-decorator';
import { Mutation, Action } from 'vuex-class';

import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

import { LOCALES, LOCALE_EN, LOCALE_DE } from '@/constants/i18n';
import RobotModel, {
  ITranslations,
  KIND_CLEANING,
  KIND_COMMISSIONING,
  KIND_MEDICAL,
  KIND_TRANSPORT,
} from '@/models/robot';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';

import RobotWizardStep from './RobotWizardStep';

const MSG_FORM_INVALID = 'view.robot-create.notification.form-invalid';

interface IRobotBasicInformation {
  manufacturer: number;
  name: string;
  width: number;
  height: number;
  length: number;
  weight: number;
  kind: string;
  maxAreaPressure: number;
  avgSpeed: number;
  maxSpeed: number;
  batteryLifetime: number;
  maxPayload: number;
  translations: ITranslations;
}

const defaultBasicInformation: IRobotBasicInformation = {
  name: '',
  manufacturer: 0,
  maxPayload: 0,
  width: 0,
  height: 0,
  length: 0,
  weight: 0,
  maxAreaPressure: 0,
  avgSpeed: 0,
  maxSpeed: 0,
  batteryLifetime: 0,
  kind: KIND_TRANSPORT,
  translations: {
    [LOCALE_EN]: {
      description: '',
    },
    [LOCALE_DE]: {
      description: '',
    },
  },
};

@Component
class RobotWizardStepBasic extends RobotWizardStep {
  protected readonly LOCALES = LOCALES;
  protected readonly rules = rules;
  protected readonly KINDS = [
    {
      value: KIND_TRANSPORT,
      text: 'view.robot.kind.transport',
    },
    {
      value: KIND_MEDICAL,
      text: 'view.robot.kind.medical',
    },
    {
      value: KIND_COMMISSIONING,
      text: 'view.robot.kind.commissioning',
    },
    {
      value: KIND_CLEANING,
      text: 'view.robot.kind.cleaning',
    },
  ];

  protected isValid = false;
  protected isLoading = false;

  protected data: IRobotBasicInformation = cloneDeep(defaultBasicInformation);

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Action
  protected createRobot!: (data: IRobotBasicInformation) => RobotModel;

  @Action
  protected updateRobot!: (payload: { robot: RobotModel; data: IRobotBasicInformation }) => RobotModel;

  @Watch('robot', { immediate: true })
  protected async onRobotChanged() {
    if (this.robot === null) {
      // @ts-ignore
      this.data = cloneDeep(defaultBasicInformation);
    } else {
      // @ts-ignore
      this.data = cloneDeep(pick(this.robot.rawData, Object.keys(defaultBasicInformation)));
    }
  }

  protected async save() {
    if (this.form.validate()) {
      this.isLoading = true;

      // @ts-ignore
      const manufacturer = this.manufacturer.data.id;
      const data = { ...this.data, manufacturer };

      let robot = null;
      if (this.robot === null) {
        robot = await this.createRobot(data);
      } else {
        robot = await this.updateRobot({
          robot: this.robot,
          data,
        });
      }
      await this.setRobot(robot);

      this.nextStep();

      this.isLoading = false;
    } else {
      this.setSnackbarNotification(this.$t(MSG_FORM_INVALID).toString());
    }
  }
}

export default RobotWizardStepBasic;
