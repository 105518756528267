import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import { EntityDetailView } from '@/utils/views';

import { CompanyModel, ServiceModel } from '@/models';
import { getCountryName } from '@/plugins/i18n';
import { KIND_COMPANY_SERVICE } from '@/models/company';

const contains = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

@Component<ServiceLanding>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n
        .t('page.service-landing.title', { country: this.countryString, service: this.entity.title })
        .toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n
            .t('page.service-landing.meta-description', { country: this.countryString, service: this.entity.title })
            .toString(),
        },
      ],
    };
  },
})
class ServiceLanding extends EntityDetailView {
  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected services!: ServiceModel[];

  @Getter
  protected serviceBySlug!: (slug: string) => ServiceModel;

  // Search Results
  protected results: CompanyModel[] = [];

  @Action
  protected getServices!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getServices();
    this.getCompanies();
    this.getAds();
  }

  protected country = '';

  protected get entity(): ServiceModel {
    const { country } = this.$route.params;
    const { service } = this.$route.params;
    if (country !== undefined && service !== undefined) {
      this.country = country;
      return this.serviceBySlug(service);
    }
    return this.serviceBySlug(service);
  }

  protected get entities() {
    if (this.entity === undefined) {
      return undefined;
    }
    let results = this.companies;
    results = filter(results, m => m.kind === KIND_COMPANY_SERVICE && !!m.logo);
    results = filter(results, r => !!r.services.find(_ => _ === this.entity.id));
    results = filter(results, r => contains(r.countries, [this.country.toUpperCase()]));

    this.results = results;
    return this.results;
  }

  protected get companySpotlight() {
    return filter(this.entities, m => m.isSpotlight && m.kind === KIND_COMPANY_SERVICE);
  }

  protected get countryString() {
    return getCountryName(this.country.toUpperCase());
  }
}

export default ServiceLanding;
