import { Vue, Component, Ref } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';

import { RobotModel, LeadModel, QueryModel } from '@/models';
import { SRC_LEADTOOL, KIND_ROBOT } from '@/models/lead';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';

import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';
import { LOCALE_EN, LOCALE_DE } from '@/constants/i18n';
import { ILead } from '@/models/lead';
import { IQuery } from '@/models/query';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

const USECASE_ID_TRANSPORT_PALLET = 4;
const USECASE_ID_TRANSPORT_BOX = 5;
const USECASE_ID_TRANSPORT_CART = 6;
const USECASE_ID_PICKING = 2;
const USECASE_ID_CLEANING = 3;

const MSG_FORM_INVALID = 'component.company-edit-form.notification.form-invalid';

@Component
class LeadTool extends Vue {
  @Ref() protected readonly formUsecaseTransport!: VuetifyForm;
  @Ref() protected readonly formUsecasePicking!: VuetifyForm;
  @Ref() protected readonly formUsecaseCleaning!: VuetifyForm;
  @Ref() protected readonly formContact!: VuetifyForm;

  @Getter
  protected robots!: RobotModel[];

  @Action
  protected getRobots!: () => void;

  @Action
  protected createLead!: (data: ILead) => LeadModel;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected readonly rules = rules;

  protected stage = 0;
  protected thankYou = false;
  protected results: RobotModel[] = [];

  protected showSolutionDesign = false;
  protected showBusinessCase = false;
  protected showServices = false;

  protected readonly usecases = [
    {
      id: 'transport',
      icon: 'mdi-map-marker-distance',
      title: 'Transport',
    },
    {
      id: 'picking',
      icon: 'mdi-dolly',
      title: 'Picking',
    },
    {
      id: 'cleaning',
      icon: 'mdi-vacuum',
      title: 'Cleaning',
    },
  ];

  protected selectedUsecase: string | null = null;
  protected selectedTransportItem = null;

  // Params - Transport
  protected transportDistance = null;
  protected transportPerHour = null;

  // Params - Picking
  protected projectType = null;
  protected pickedGoodsType = null;
  protected shiftsPerDay = null;
  protected availableSpace = null;
  protected orderLinesPerDay = null;
  protected linesPerOrder = null;
  protected picksPerLine = null;

  // Params - Cleaning
  protected cleaningArea = null;
  protected cleaningCyclesPerDay = null;

  // Params - General
  protected maxWeightPerTransport = null;
  protected isOutdoorUsecase = false;
  protected isCleanroomUsecase = false;
  protected isFrozenUsecase = false;

  // User Info
  protected leadName = null;
  protected leadEmail = null;
  protected leadCompany = null;
  protected leadCountry = null;

  // Forms
  protected isValidContact = false;

  protected progress = 0;
  protected progressInterval = 0;
  protected isLoadingContactForm = false;

  protected async mounted() {
    this.getRobots();
  }

  protected get showCalculateRobotsButton() {
    return (
      (this.selectedUsecase === 'transport' && this.selectedTransportItem) ||
      (this.selectedUsecase && this.selectedUsecase !== 'transport')
    );
  }

  protected get selectedUsecaseID(): number | null {
    switch (this.selectedUsecase) {
      case 'transport':
        switch (this.selectedTransportItem!) {
          case 'pallet':
            return USECASE_ID_TRANSPORT_PALLET;
          case 'box':
            return USECASE_ID_TRANSPORT_BOX;
          case 'cart':
            return USECASE_ID_TRANSPORT_CART;
          default:
            break;
        }
        break;
      case 'picking':
        return USECASE_ID_PICKING;
      case 'cleaning':
        return USECASE_ID_CLEANING;
      default:
        break;
    }
    return null;
  }

  protected get limitedResults() {
    return this.results.slice(0, 7);
  }

  protected get robotsWithImages() {
    let robotsWithImages = this.robots.filter(robot => robot.image != null);
    if (this.selectedUsecaseID) {
      robotsWithImages = this.robots.filter(robot => robot.useCases.includes(this.selectedUsecaseID || 1));
    }
    return robotsWithImages;
  }

  protected calculateRobots() {
    if (this.selectedUsecase === 'transport' && this.formUsecaseTransport.validate() === false) {
      return;
    }
    if (this.selectedUsecase === 'picking' && this.formUsecasePicking.validate() === false) {
      return;
    }
    if (this.selectedUsecase === 'cleaning' && this.formUsecaseCleaning.validate() === false) {
      return;
    }
    window.scrollTo(0, 0);
    this.stage = 1;
    this.progress = 0;
    this.progressInterval = setInterval(this.progressRobotCalculation, 200);
    this.search();
    this.createQuery();
  }

  protected progressRobotCalculation() {
    if (this.progress < 100) {
      this.progress += 2;
    } else {
      clearInterval(this.progressInterval);
      this.stage = 2;
    }
  }

  protected search() {
    let results = this.robots;

    // use-cases
    if (this.selectedUsecaseID) {
      results = filter(results, r => containsFully(r.useCases, [this.selectedUsecaseID]));
    }

    // max. payload
    if (this.maxWeightPerTransport) {
      results = filter(results, r => r.maxPayload >= this.maxWeightPerTransport!);
    }

    // environment
    if (this.isCleanroomUsecase) {
      results = filter(results, r => !!r.cleanRoom);
    }
    if (this.isFrozenUsecase) {
      results = filter(results, r => !!r.frostEnvironment);
    }
    if (this.isOutdoorUsecase) {
      results = filter(results, r => !!r.outdoor);
    }

    this.results = results;
  }

  protected async save() {
    if (this.formContact.validate()) {
      this.isLoadingContactForm = true;

      const usecaseParameters = {
        isCleanroom: this.isCleanroomUsecase,
        isFrost: this.isFrozenUsecase,
        isOutdoor: this.isOutdoorUsecase,
      };

      // Transport & Picking
      if (this.maxWeightPerTransport) {
        Object.assign(usecaseParameters, { maxWeightPerTransport: this.maxWeightPerTransport });
      }

      // Transport
      if (this.selectedTransportItem) {
        Object.assign(usecaseParameters, { selectedTransportItem: this.selectedTransportItem });
      }
      if (this.transportDistance) {
        Object.assign(usecaseParameters, { transportDistance: this.transportDistance });
      }
      if (this.transportPerHour) {
        Object.assign(usecaseParameters, { transportPerHour: this.transportPerHour });
      }

      // Picking
      if (this.pickedGoodsType) {
        Object.assign(usecaseParameters, { pickedGoodsType: this.pickedGoodsType });
      }
      if (this.shiftsPerDay) {
        Object.assign(usecaseParameters, { shiftsPerDay: this.shiftsPerDay });
      }
      if (this.availableSpace) {
        Object.assign(usecaseParameters, { availableSpace: this.availableSpace });
      }
      if (this.orderLinesPerDay) {
        Object.assign(usecaseParameters, { orderLinesPerDay: this.orderLinesPerDay });
      }
      if (this.linesPerOrder) {
        Object.assign(usecaseParameters, { linesPerOrder: this.linesPerOrder });
      }
      if (this.picksPerLine) {
        Object.assign(usecaseParameters, { picksPerLine: this.picksPerLine });
      }
      if (this.projectType) {
        Object.assign(usecaseParameters, { projectType: this.projectType });
      }

      // Cleaning
      if (this.cleaningArea) {
        Object.assign(usecaseParameters, { cleaningArea: this.cleaningArea });
      }
      if (this.cleaningCyclesPerDay) {
        Object.assign(usecaseParameters, { cleaningCyclesPerDay: this.cleaningCyclesPerDay });
      }

      const robotIDs = this.results.map(robot => {
        return robot.id;
      });
      const manufacturers = Array.from(
        new Set(
          this.results.map(robot => {
            return robot.manufacturer;
          }),
        ),
      );

      const data: ILead = {
        id: 0,
        kind: KIND_ROBOT,
        source: SRC_LEADTOOL,
        robots: robotIDs,
        manufacturers: manufacturers,
        useCases: [this.selectedUsecaseID!],
        leadCompany: this.leadCompany!,
        leadName: this.leadName!,
        leadTelephone: '',
        leadEmail: this.leadEmail!,
        leadCountry: this.leadCountry!,
        leadData: JSON.stringify(usecaseParameters),
        services: [],
        companies: [],
        products: [],
        translations: {
          [LOCALE_EN]: {
            description: '',
          },
          [LOCALE_DE]: {
            description: '',
          },
        },
      };
      try {
        const response = await this.createLead(data);
        this.thankYou = true;
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingContactForm = false;
      }
    } else {
      // Form Invalide
      this.setSnackbarNotification(this.$t(MSG_FORM_INVALID).toString());
    }
  }

  protected async createQuery() {
    const usecaseParameters = {
      isCleanroom: this.isCleanroomUsecase,
      isFrost: this.isFrozenUsecase,
      isOutdoor: this.isOutdoorUsecase,
    };

    // Transport & Picking
    if (this.maxWeightPerTransport) {
      Object.assign(usecaseParameters, { maxWeightPerTransport: this.maxWeightPerTransport });
    }

    // Transport
    if (this.selectedTransportItem) {
      Object.assign(usecaseParameters, { selectedTransportItem: this.selectedTransportItem });
    }
    if (this.transportDistance) {
      Object.assign(usecaseParameters, { transportDistance: this.transportDistance });
    }
    if (this.transportPerHour) {
      Object.assign(usecaseParameters, { transportPerHour: this.transportPerHour });
    }

    // Picking
    if (this.pickedGoodsType) {
      Object.assign(usecaseParameters, { pickedGoodsType: this.pickedGoodsType });
    }
    if (this.shiftsPerDay) {
      Object.assign(usecaseParameters, { shiftsPerDay: this.shiftsPerDay });
    }
    if (this.availableSpace) {
      Object.assign(usecaseParameters, { availableSpace: this.availableSpace });
    }
    if (this.orderLinesPerDay) {
      Object.assign(usecaseParameters, { orderLinesPerDay: this.orderLinesPerDay });
    }
    if (this.linesPerOrder) {
      Object.assign(usecaseParameters, { linesPerOrder: this.linesPerOrder });
    }
    if (this.picksPerLine) {
      Object.assign(usecaseParameters, { picksPerLine: this.picksPerLine });
    }
    if (this.projectType) {
      Object.assign(usecaseParameters, { projectType: this.projectType });
    }

    // Cleaning
    if (this.cleaningArea) {
      Object.assign(usecaseParameters, { cleaningArea: this.cleaningArea });
    }
    if (this.cleaningCyclesPerDay) {
      Object.assign(usecaseParameters, { cleaningCyclesPerDay: this.cleaningCyclesPerDay });
    }

    const robotIDs = this.results.map(robot => {
      return robot.id;
    });
    const manufacturers = Array.from(
      new Set(
        this.results.map(robot => {
          return robot.manufacturer;
        }),
      ),
    );

    const data: IQuery = {
      id: 0,
      kind: 'robot',
      robots: robotIDs,
      manufacturers: manufacturers,
      useCases: [this.selectedUsecaseID!],
      data: JSON.stringify(usecaseParameters),
      services: [],
      companies: [],
    };
    try {
      QueryModel.create(data);
    } catch (error) {
      console.error(error);
    }
  }
}

export default LeadTool;
