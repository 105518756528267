import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import orderBy from 'lodash/orderBy';
import i18n from '@/plugins/i18n';

import { UseCaseModel, RobotModel } from '@/models';

const OBJ_SPEED = 'speed';
const OBJ_PAYLOAD = 'payload';
const OBJ_WEIGHT = 'weight';
const OBJ_BATTERY = 'battery';

const LIST_LENGTH = 10;

@Component<TopList>({
  metaInfo(): MetaInfo {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: 'description',
          content: this.useCase ? this.pageDescription : '',
        },
      ],
    };
  },
})
class TopList extends Vue {
  @Getter
  protected useCaseBySlug!: (slug: string) => UseCaseModel;

  @Getter
  protected robotsByUseCase!: (id: number) => RobotModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get pageDescription(): string {
    return i18n
      .t('page.top-list.description', {
        usecase: this.useCase.title,
        objective: this.localizedObjective,
      })
      .toString();
  }

  protected get pageTitle(): string {
    return i18n
      .t('page.top-list.title', {
        usecase: this.useCase.title,
        objective: this.localizedObjective,
      })
      .toString();
  }

  protected get localizedObjective(): string {
    switch (this.objective) {
      case OBJ_BATTERY:
        return i18n.t('view.top-list.objective.battery').toString();
      case OBJ_PAYLOAD:
        return i18n.t('view.top-list.objective.payload').toString();
      case OBJ_SPEED:
        return i18n.t('view.top-list.objective.speed').toString();
      case OBJ_WEIGHT:
        return i18n.t('view.top-list.objective.weight').toString();
    }
    return i18n.t('view.top-list.objective.unknown').toString();
  }

  protected get useCase(): UseCaseModel {
    const { usecase } = this.$route.params;
    return this.useCaseBySlug(usecase);
  }

  protected get objective() {
    const { objective } = this.$route.params;
    return objective;
  }

  protected get description(): string {
    switch (this.objective) {
      case OBJ_BATTERY:
        return i18n.t('view.top-list.description.battery', { usecase: this.useCase.title }).toString();
      case OBJ_PAYLOAD:
        return i18n.t('view.top-list.description.payload', { usecase: this.useCase.title }).toString();
      case OBJ_SPEED:
        return i18n.t('view.top-list.description.speed', { usecase: this.useCase.title }).toString();
      case OBJ_WEIGHT:
        return i18n.t('view.top-list.description.weight', { usecase: this.useCase.title }).toString();
    }
    return i18n.t('view.top-list.description.unknown', { usecase: this.useCase.title }).toString();
  }

  protected get robots(): RobotModel[] {
    return this.useCase ? this.robotsByUseCase(this.useCase.id) : [];
  }

  protected get orderedRobots(): RobotModel[] {
    switch (this.objective) {
      case OBJ_BATTERY:
        return this.byBattery;
      case OBJ_PAYLOAD:
        return this.byPayload;
      case OBJ_SPEED:
        return this.bySpeed;
      case OBJ_WEIGHT:
        return this.byWeight;
    }
    return this.robots;
  }

  protected get bySpeed(): RobotModel[] {
    const sorted = orderBy(this.robots, robot => {
      return robot.avgSpeed;
    });
    return sorted.reverse().slice(0, LIST_LENGTH);
  }

  protected get byBattery(): RobotModel[] {
    const sorted = orderBy(this.robots, robot => {
      return robot.batteryLifetime;
    });
    return sorted.reverse().slice(0, LIST_LENGTH);
  }

  protected get byWeight(): RobotModel[] {
    const sorted = orderBy(this.robots, robot => {
      return robot.weight;
    });
    return sorted.reverse().slice(0, LIST_LENGTH);
  }

  protected get bySize(): RobotModel[] {
    const sorted = orderBy(this.robots, robot => {
      return robot.height * robot.width * robot.length;
    });
    return sorted.reverse().slice(0, LIST_LENGTH);
  }

  protected get byPayload(): RobotModel[] {
    const sorted = orderBy(this.robots, robot => {
      return robot.maxPayload;
    });
    return sorted.reverse().slice(0, LIST_LENGTH);
  }
}

export default TopList;
