import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import { Getter } from 'vuex-class';

import { RobotModel } from '@/models';

@Component
class ToolsRobotSearch extends Vue {
  protected readonly MAX_RESULTS = 10;

  protected query = '';
  protected isOpen = false;
  protected isLoading = false;

  @Prop({ type: Boolean, default: false })
  protected dense!: boolean;

  @Getter
  protected robotsByQuery!: (q: string) => RobotModel[];

  protected robots: RobotModel[] = [];

  @Watch('query')
  @debounce(200, { leading: false })
  protected onQueryChanged() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 2;
    if (this.isOpen) {
      this.isLoading = true;
      this.robots = this.robotsByQuery(q);
      this.isLoading = false;
    }
  }

  @debounce(1000, { leading: false })
  protected countResults(results: []) {
    const count = results && results.length > this.MAX_RESULTS ? `${this.MAX_RESULTS}+` : results.length;
    return { count };
  }

  @debounce(300, { leading: false })
  protected onFocus() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 4;
  }

  @debounce(300, { leading: false })
  protected onBlur() {
    this.isOpen = false;
  }
}

export default ToolsRobotSearch;
