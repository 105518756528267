import { Vue, Component, Prop } from 'vue-property-decorator';

export interface IListItem {
  value: string;
  label: string;
}

@Component
class ListProperties extends Vue {
  @Prop({ required: true })
  protected items!: IListItem[];

  @Prop({ type: Function, required: true })
  protected exists!: (value: string) => boolean;
}

export default ListProperties;
