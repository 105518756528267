import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<Landing>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.newsletter.title').toString(),
    };
  },
})
class Landing extends Vue {}

export default Landing;
