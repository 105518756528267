import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import { VuetifyForm } from '@/plugins/vuetify';
import { i18nRoute } from '@/plugins/i18n';

import UserModel, { ISignupPayload } from '@/models/user';
import rules from '@/utils/validation';

const MSG_ERROR = 'view.signup.notification.error';
const MSG_ERROR_ALREADY_REGISTERED = 'view.signup.notification.error.already-registered';

@Component<Signup>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.signup.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.signup.title.meta-description').toString() }],
    };
  },
})
class Signup extends Vue {
  protected readonly rules = rules;

  protected isLoading = false;
  protected isValid = false;
  protected showSuccess = false;

  protected password2 = '';

  protected readonly kinds = [
    {
      id: 'manufacturer',
      icon: 'mdi-robot-mower',
      title: this.$t('view.signup.form.kind.manufacturer'),
    },
    {
      id: 'service',
      icon: 'mdi-account-wrench',
      title: this.$t('view.signup.form.kind.service'),
    },
    {
      id: 'product',
      icon: 'mdi-connection',
      title: this.$t('view.signup.form.kind.product'),
    },
    {
      id: 'enduser',
      icon: 'mdi-account-hard-hat',
      title: this.$t('view.signup.form.kind.enduser'),
    },
  ];

  protected data: ISignupPayload = {
    firstName: '',
    lastName: '',
    company: '',
    position: '',
    email: '',
    phone: '',
    password: '',
    kind: '',
  };

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Getter
  protected isAuthenticated!: boolean;

  @Watch('isAuthenticated', { immediate: true })
  protected onIsAuthenticated() {
    if (this.isAuthenticated) {
      this.$router.push('/');
    }
  }

  protected get loginRoute() {
    return i18nRoute({ name: 'login' });
  }

  protected async performSignup() {
    if (this.form.validate()) {
      this.isLoading = true;

      try {
        await UserModel.signup(this.data);
      } catch (err) {
        this.isLoading = false;

        if (err.response.status === 400) {
          // bad request
          const { nonFieldErrors } = err.response.data;
          if (nonFieldErrors && nonFieldErrors.includes('already-exists')) {
            this.setSnackbarNotification(this.$t(MSG_ERROR_ALREADY_REGISTERED).toString());
            return;
          }
        }

        this.setSnackbarNotification(this.$t(MSG_ERROR).toString());
        return;
      }

      this.isLoading = false;
      this.showSuccess = true;
    }
  }

  protected get termsLabel() {
    const terms = this.$router.resolve(i18nRoute({ name: 'terms' })).href;
    const privacy = this.$router.resolve(i18nRoute({ name: 'privacy' })).href;
    const guidelines = this.$router.resolve(i18nRoute({ name: 'guidelines' })).href;
    return this.$t('view.signup.field.terms.label', { terms, privacy, guidelines });
  }
}

export default Signup;
