import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter } from 'vuex-class';

import { TenderModel, UserModel } from '@/models';

@Component<Tender>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.tender.title').toString(),
    };
  },
})
class Tender extends Vue {
  @Getter
  protected isAuthenticated!: boolean;

  @Getter
  protected user!: null | UserModel;

  @Getter
  protected tenderBySlug!: (slug: string) => TenderModel;

  @Watch('isAuthenticated', { immediate: true })
  protected onIsAuthenticated() {
    if (!this.isAuthenticated) {
      this.$router.push('/');
    }
  }

  @Watch('isAuthenticated', { immediate: true })
  protected get isEligible(): boolean {
    const { tenderSlug } = this.$route.params;
    const tender = this.tenderBySlug(tenderSlug);
    if (this.user) {
      if (this.user.manufacturers.length > 0) {
        const id = this.user.manufacturers[0];
        if (tender.manufacturers.includes(id)) {
          return true;
        }
      }
    }
    return false;
  }

  protected get tender(): TenderModel {
    const { tenderSlug } = this.$route.params;
    const tender = this.tenderBySlug(tenderSlug);
    return tender;
  }
}

export default Tender;
