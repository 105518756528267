import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<Care>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.care.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.care.meta-description').toString() }],
    };
  },
})
class Care extends Vue {}

export default Care;
