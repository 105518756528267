import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { i18nRoute } from '@/plugins/i18n';

import { RobotModel, ManufacturerModel, ClickModel } from '@/models';
import { TYPE_ROBOT, IClick } from '@/models/click';

@Component
class TileRobotExtended extends Vue {
  @Prop()
  protected robot1!: RobotModel;

  @Prop()
  protected robot2!: RobotModel;

  @Prop()
  protected score!: number;

  @Prop({ type: Boolean, default: false })
  protected wide!: boolean;

  @Prop({ type: String, default: 'none' })
  protected source!: string;

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  protected get manufacturer() {
    return this.manufacturerByID(this.robot1.manufacturer);
  }

  protected get comparisonRoute() {
    return i18nRoute({
      name: 'robot-comparison',
      params: { robotSlug1: this.robot1.slug, robotSlug2: this.robot2.slug },
    });
  }

  protected executeClicks() {
    const query: IClick = {
      // source of search
      source: this.source,
      // robot
      type: TYPE_ROBOT.toString(),
      id: this.robot1.id,
    };
    ClickModel.create(query);

    const query2: IClick = {
      // source of search
      source: this.source,
      // robot
      type: TYPE_ROBOT.toString(),
      id: this.robot2.id,
    };
    ClickModel.create(query);
  }
}

export default TileRobotExtended;
