import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';
import { IVersionized, VersionizedModel } from './types';

const API_ENDPOINT = '/api/v1/robots/';

export const ASSET_KIND_IMAGE = 'image';
export const ASSET_KIND_DOCUMENT = 'document';
export const ASSET_KIND_YOUTUBE_VIDEO = 'youtube';

export const KIND_TRANSPORT = 'transport';
export const KIND_MEDICAL = 'medical';
export const KIND_COMMISSIONING = 'commissioning';
export const KIND_CLEANING = 'cleaning';

export interface IRobotAsset {
  id?: number;
  isPublished?: boolean;
  kind?: string;
  title?: string;
  file?: string | File | null;
  youtubeVideoId?: string | null;
  order: number | null;
}

export interface ITranslations {
  [key: string]: {
    description: string;
  };
}

export interface IRobot extends IVersionized {
  id: number;
  slug: string;
  name: string;
  kind: string;
  image: string | File;
  manufacturer: number;
  isPremium: boolean;
  isSpotlight: boolean;
  useCases: number[];
  ceCertificate: boolean | null;
  tuvCertificate: boolean | null;
  followMe: boolean | null;
  outdoor: boolean | null;
  cleanRoom: boolean | null;
  frostEnvironment: boolean | null;
  maxPayload: number;
  width: number;
  height: number;
  length: number;
  weight: number;
  avgSpeed: number;
  maxSpeed: number;
  batteryLifetime: number;
  maxAreaPressure: number;
  cleaningAreaHour: number;
  cleaningAreaCharge: number;
  cleaningAutoWater: boolean | null;
  cleaningAutoDrain: boolean | null;
  transportMinPickupSpace: number;
  sensors: string[];
  localization: string[];
  translations: ITranslations;
  hasSense: boolean;
}

export interface ISensor {
  label: string;
  value: string;
}

export const SENSORS: ISensor[] = [
  {
    label: 'models.robot.sensors.laser',
    value: 'laser',
  },
  {
    label: 'models.robot.sensors.infrared',
    value: 'infrared',
  },
  {
    label: 'models.robot.sensors.camera-3d',
    value: 'camera-3d',
  },
  {
    label: 'models.robot.sensors.camera-2d',
    value: 'camera-2d',
  },
  {
    label: 'models.robot.sensors.proximity',
    value: 'proximity',
  },
  {
    label: 'models.robot.sensors.bumpers',
    value: 'bumpers',
  },
];

export interface ILocalization {
  label: string;
  value: string;
}

export const LOCALIZATIONS: ILocalization[] = [
  {
    label: 'models.robot.localization.inertial',
    value: 'inertial',
  },
  {
    label: 'models.robot.localization.magnets',
    value: 'magnets',
  },
  {
    label: 'models.robot.localization.line',
    value: 'line',
  },
  {
    label: 'models.robot.localization.markers',
    value: 'markers',
  },
  {
    label: 'models.robot.localization.visual',
    value: 'visual',
  },
  {
    label: 'models.robot.localization.gnss',
    value: 'gnss',
  },
  {
    label: 'models.robot.localization.transponder',
    value: 'transponder',
  },
];

class RobotModel extends VersionizedModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new RobotModel(data));
  }

  public static async create(data: IRobot) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new RobotModel(resp.data);
  }

  protected assets!: IRobotAsset[];

  constructor(protected data: IRobot) {
    super();
  }

  public async update(data: IRobot) {
    const resp = await axios.patch(API_ENDPOINT + this.id + '/', data);
    this.data = resp.data;
    return this;
  }

  public async listAssets() {
    const resp = await axios.get(API_ENDPOINT + this.id + '/assets/');
    this.assets = resp.data.results;
    return this.assets;
  }

  public async reorderAssets(order: number[]) {
    const resp = await axios.post(API_ENDPOINT + this.id + '/assets/reorder/', order);
    this.assets = resp.data;
    return this.assets;
  }

  public async createAsset(asset: IRobotAsset | FormData) {
    const resp = await axios.post(API_ENDPOINT + this.id + '/assets/', asset);
    this.assets.push(resp.data);
    return this.assets;
  }

  public async updateAsset(asset: IRobotAsset) {
    const { file, ...data } = asset;
    const resp = await axios.patch(`${API_ENDPOINT}${this.id}/assets/${asset.id}/`, data);
    this.assets = map(this.assets, a => (a.id === asset.id ? asset : a));
    return this.assets;
  }

  public async deleteAsset(asset: IRobotAsset) {
    const resp = await axios.delete(`${API_ENDPOINT}${this.id}/assets/${asset.id}/`);
    this.assets = filter(this.assets, a => a.id !== asset.id);
    return this.assets;
  }

  public get rawData() {
    return this.data;
  }

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.data.slug;
  }

  public get name() {
    return this.data.name;
  }

  public get kind() {
    return this.data.kind;
  }

  public get hasSense() {
    return this.data.hasSense;
  }

  public get image() {
    return this.data.image;
  }

  public get imagesAndVideos() {
    return filter(this.assets, a => a.kind !== ASSET_KIND_DOCUMENT);
  }

  public get documents() {
    return filter(this.assets, { kind: ASSET_KIND_DOCUMENT });
  }

  public get manufacturer() {
    return this.data.manufacturer;
  }

  public get isPremium() {
    return this.data.isPremium;
  }

  public get useCases() {
    return this.data.useCases;
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public get width() {
    return this.data.width;
  }

  public get height() {
    return this.data.height;
  }

  public get length() {
    return this.data.length;
  }

  public get weight() {
    return this.data.weight;
  }

  public get maxPayload() {
    return this.data.maxPayload;
  }

  public get avgSpeed() {
    return this.data.avgSpeed;
  }

  public get maxSpeed() {
    return this.data.maxSpeed;
  }

  public get batteryLifetime() {
    return this.data.batteryLifetime;
  }

  public get maxAreaPressure() {
    return this.data.maxAreaPressure;
  }

  public get ceCertificate() {
    return this.data.ceCertificate;
  }

  public get tuvCertificate() {
    return this.data.tuvCertificate;
  }

  public get followMe() {
    return this.data.followMe;
  }

  public get outdoor() {
    return this.data.outdoor;
  }

  public get cleanRoom() {
    return this.data.cleanRoom;
  }

  public get frostEnvironment() {
    return this.data.frostEnvironment;
  }

  public get cleaningAreaHour() {
    return this.data.cleaningAreaHour;
  }

  public get cleaningAreaCharge() {
    return this.data.cleaningAreaCharge;
  }

  public get cleaningAutoWater() {
    return this.data.cleaningAutoWater;
  }

  public get cleaningAutoDrain() {
    return this.data.cleaningAutoDrain;
  }

  public get transportMinPickupSpace() {
    return this.data.transportMinPickupSpace;
  }

  public get isSpotlight() {
    return this.data.isSpotlight;
  }

  public get sensors() {
    return this.data.sensors;
  }

  public hasSensor(value: string) {
    return this.sensors.includes(value);
  }

  public get localization() {
    return this.data.localization;
  }

  public hasLocalization(value: string) {
    return this.localization.includes(value);
  }

  public matchesQuery(query: string) {
    return this.name && this.name.toLowerCase().includes(query);
  }

  public get route() {
    const manufacturer = find(store.getters.manufacturers, m => m.id === this.manufacturer);
    return i18nRoute({ name: 'robot', params: { manufacturerSlug: manufacturer.slug, robotSlug: this.slug } });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }

  public get similar() {
    return () => router.push(i18nRoute({ name: 'tools-similar-robots', params: { robotSlug: this.slug } }));
  }

  public toString() {
    return this.name;
  }

  public vector() {
    return [this.avgSpeed, this.maxSpeed, this.weight, this.height, this.length, this.width];
  }

  public compare(robot: RobotModel) {
    let sumOfSquares = 0;
    const v1 = this.vector();
    const v2 = robot.vector();
    for (let i = 0; i < v1.length; i++) {
      const diff = v1[i] - v2[i];
      sumOfSquares += diff * diff;
    }
    return Math.sqrt(sumOfSquares);
  }

  public dna(robot: RobotModel) {
    // f(x)=-50*x+100
    const speed = (-50.0 * Math.abs(robot.avgSpeed - this.avgSpeed) + 100.0) / 100.0;
    // f(x)=-2*x+100
    const payload = (-0.5 * Math.abs(robot.maxPayload - this.maxPayload) + 100.0) / 100.0;
    // f(x)=-0.5*x+100
    const weight = (-0.5 * Math.abs(robot.weight - this.weight) + 100.0) / 100.0;
    // f(x)=-0.5*x+100
    const height = (-0.5 * Math.abs(robot.height - this.height) + 100.0) / 100.0;
    // f(x)=-4*x+100
    const width = (-0.5 * Math.abs(robot.width - this.width) + 100.0) / 100.0;
    // f(x)=-4*x+100
    const length = (-0.5 * Math.abs(robot.length - this.length) + 100.0) / 100.0;
    const settings =
      ((this.followMe && robot.followMe ? 25.0 : 0.0) +
        (this.frostEnvironment && robot.frostEnvironment ? 25.0 : 0.0) +
        (this.cleanRoom && robot.cleanRoom ? 25.0 : 0.0) +
        (this.outdoor && robot.outdoor ? 25.0 : 0.0)) /
      100.0;

    let useCases = 0.0;
    this.useCases.forEach(x => {
      if (robot.useCases.includes(x)) {
        useCases++;
      }
    });
    useCases = useCases / Math.max(this.useCases.length, robot.useCases.length);
    if (isNaN(useCases)) {
      useCases = 0.0;
    }

    let sensors = 0.0;
    this.sensors.forEach(x => {
      if (robot.sensors.includes(x)) {
        sensors++;
      }
    });
    sensors = sensors / Math.max(this.sensors.length, robot.sensors.length);
    if (isNaN(sensors)) {
      sensors = 0.0;
    }

    let localization = 0.0;
    this.localization.forEach(x => {
      if (robot.localization.includes(x)) {
        localization++;
      }
    });
    localization = localization / Math.max(this.localization.length, robot.localization.length);
    if (isNaN(localization)) {
      localization = 0.0;
    }

    return (
      Math.max(0.0, speed) * 0.05 +
      Math.max(0.0, payload) * 0.13 +
      Math.max(0.0, weight) * 0.13 +
      Math.max(0.0, height) * 0.13 +
      Math.max(0.0, width) * 0.13 +
      Math.max(0.0, length) * 0.13 +
      Math.max(0.0, settings) * 0.05 +
      Math.max(0.0, sensors) * 0.05 +
      Math.max(0.0, useCases) * 0.15 +
      Math.max(0.0, localization) * 0.05
    );
  }

  public generateStatement() {
    let statement = '';
    // introduction
    statement += this.sizeStatement() + ' ';
    statement += this.speedStatement() + ' ';
    // this can be mixed
    if (this.featureStatement() !== '') {
      statement += this.featureStatement() + ' ';
    }
    statement += this.localizationStatement() + ' ';
    statement += this.payloadStatement() + ' ';
    statement += this.batteryStatement() + ' ';
    statement += this.sensorStatement() + ' ';
    statement += this.certificateStatement() + ' ';
    return statement;
  }

  private featureStatement() {
    const options: string[] = ['page.robot-comparison.follow1', 'page.robot-comparison.follow2'];
    if (this.followMe === true) {
      return i18n.t(options[this.getRandomInt(options.length)]).toString();
    } else {
      return '';
    }
  }

  private sizeStatement() {
    let statement = '';
    if (this.height !== 0.0 && this.width !== 0.0 && this.height !== 0.0) {
      const options: string[] = [
        'page.robot-comparison.size1',
        'page.robot-comparison.size2',
        'page.robot-comparison.size3',
      ];
      statement +=
        i18n
          .t(options[this.getRandomInt(options.length)], {
            robot: this.name,
            height: this.height,
            width: this.width,
            length: this.length,
          })
          .toString() + ' ';
    }

    if (this.weight !== 0.0) {
      const options: string[] = [
        'page.robot-comparison.weight1',
        'page.robot-comparison.weight2',
        'page.robot-comparison.weight3',
      ];
      statement += i18n
        .t(options[this.getRandomInt(options.length)], {
          weight: this.weight,
        })
        .toString();
    }
    return statement;
  }

  private speedStatement() {
    if (this.avgSpeed !== 0.0) {
      const options: string[] = [
        'page.robot-comparison.avgSpeed1',
        'page.robot-comparison.avgSpeed2',
        'page.robot-comparison.avgSpeed3',
      ];
      let max = '';
      if (this.maxSpeed !== 0.0) {
        const optionsMax: string[] = [
          'page.robot-comparison.maxSpeed1',
          'page.robot-comparison.maxSpeed2',
          'page.robot-comparison.maxSpeed3',
        ];
        max = i18n
          .t(optionsMax[this.getRandomInt(optionsMax.length)], {
            speed: this.maxSpeed,
          })
          .toString();
      }
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          avgSpeed: this.avgSpeed,
          maxSpeed: max,
        })
        .toString();
    }
    return '';
  }

  private payloadStatement() {
    if (this.maxPayload !== 0.0) {
      const options: string[] = [
        'page.robot-comparison.payload1',
        'page.robot-comparison.payload2',
        'page.robot-comparison.payload3',
      ];
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          payload: this.maxPayload,
        })
        .toString();
    }
    return '';
  }

  private batteryStatement() {
    if (this.batteryLifetime !== 0.0) {
      const options: string[] = [
        'page.robot-comparison.battery1',
        'page.robot-comparison.battery2',
        'page.robot-comparison.battery3',
      ];
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          battery: this.batteryLifetime,
        })
        .toString();
    }
    return '';
  }

  private sensorStatement() {
    const options: string[] = ['page.robot-comparison.sensorList1', 'page.robot-comparison.sensorList2'];
    const sensors: string[] = [];
    for (const sensor of SENSORS) {
      // TODO(abresk): this doesnt work
      if (this.hasSensor(sensor.value)) {
        sensors.push(i18n.t(sensor.label).toString());
      }
    }
    if (sensors.length === 0) {
      return i18n.t('page.robot-comparison.sensorsNo', {
        robot: this.name,
      });
    }
    let sensorList = '';
    if (sensors.length === 1) {
      sensorList = sensors[0];
    } else {
      const last = sensors.pop();
      sensorList = sensors.join(', ');
      sensorList += i18n.t('page.robot-comparison.sensorConjuction').toString() + last;
    }

    return i18n
      .t(options[this.getRandomInt(options.length)], {
        robot: this.name,
        list: sensorList,
      })
      .toString();
  }

  private localizationStatement() {
    const options: string[] = ['page.robot-comparison.localizationList1', 'page.robot-comparison.localizationList2'];
    const localizations: string[] = [];
    for (const loca of LOCALIZATIONS) {
      if (this.hasLocalization(loca.value)) {
        localizations.push(i18n.t(loca.label).toString());
      }
    }
    if (localizations.length === 0) {
      return i18n.t('page.robot-comparison.localizationNo');
    }
    let localizationList = '';
    if (localizations.length === 1) {
      localizationList += localizations[0];
    } else {
      const last = localizations.pop();
      localizationList = localizations.join(', ');
      localizationList += i18n.t('page.robot-comparison.localizationConjuction').toString() + last;
    }

    return i18n
      .t(options[this.getRandomInt(options.length)], {
        list: localizationList,
      })
      .toString();
  }

  private certificateStatement() {
    // tuvCertificate: boolean | null;
    let statement = '';
    if (this.ceCertificate === true) {
      const options: string[] = ['page.robot-comparison.ceCertificate1', 'page.robot-comparison.ceCertificate2'];
      statement += i18n.t(options[this.getRandomInt(options.length)]).toString();
    } else {
      const options: string[] = ['page.robot-comparison.ceCertificateNo1', 'page.robot-comparison.ceCertificateNo2'];
      statement += i18n.t(options[this.getRandomInt(options.length)]).toString();
    }
    statement += ' ';
    if (this.tuvCertificate === true) {
      const options: string[] = ['page.robot-comparison.tuvCertificate1', 'page.robot-comparison.tuvCertificate2'];
      statement += i18n.t(options[this.getRandomInt(options.length)]).toString();
    } else {
      const options: string[] = ['page.robot-comparison.tuvCertificateNo1', 'page.robot-comparison.tuvCertificateNo2'];
      statement += i18n.t(options[this.getRandomInt(options.length)]).toString();
    }
    return statement;
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default RobotModel;
