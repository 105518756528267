import axios from '@/axios';

const API_ENDPOINT = '/api/v1/queries/';

export interface IQuery {
  id: number;
  kind: string;
  robots: number[];
  manufacturers: number[];
  useCases: number[];
  services: number[];
  companies: number[];
  data: string;
}

class QueryModel {
  public static async create(data: IQuery) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new QueryModel(resp.data);
  }

  constructor(protected data: IQuery) {}

  public get rawData() {
    return this.data;
  }

  public get id() {
    return this.data.id;
  }

  public get kind() {
    return this.data.kind;
  }

  public get manufacturers() {
    return this.data.manufacturers;
  }

  public get services() {
    return this.data.services;
  }

  public get companies() {
    return this.data.companies;
  }

  public get robots() {
    return this.data.robots;
  }

  public get useCases() {
    return this.data.useCases;
  }

  public get queryData() {
    return this.data.data;
  }

  public toString() {
    return this.id.toString();
  }
}

export default QueryModel;
