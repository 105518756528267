import { Vue, Component, Prop } from 'vue-property-decorator';

import { CategoryModel } from '@/models';

@Component
class TileCategory extends Vue {
  @Prop()
  protected category!: CategoryModel;
}

export default TileCategory;
