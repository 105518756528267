import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';
import { Action, Mutation } from 'vuex-class';

import cloneDeep from 'lodash/cloneDeep';

import { LOCALES } from '@/constants/i18n';
import i18n from '@/plugins/i18n';
import ManufacturerModel, { IManufacturer } from '@/models/manufacturer';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';

const MSG_FORM_INVALID = 'component.manufacturer-edit-form.notification.form-invalid';
const MSG_SUCCESS = 'component.manufacturer-edit-form.notification.success';

@Component
class ManufacturerEditForm extends Vue {
  protected readonly LOCALES = LOCALES;
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  protected isValid = false;
  protected isLoading = false;

  protected data!: IManufacturer;
  // workaround because unfortunately 'data.country' does not update on custom form components
  // actually it is recommended to avoid objects, but we are going with it for now
  protected country!: string;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Action
  protected updateManufacturer!: (payload: { manufacturer: ManufacturerModel; data: IManufacturer }) => void;

  @Watch('manufacturer', { immediate: true })
  protected onManufacturerChanged() {
    this.data = cloneDeep(this.manufacturer.rawData);
    this.country = this.data.country;
  }

  protected async save() {
    if (this.form.validate()) {
      this.isLoading = true;

      await this.updateManufacturer({
        manufacturer: this.manufacturer,
        data: { ...this.data, country: this.country },
      });

      this.setSnackbarNotification(i18n.t(MSG_SUCCESS).toString());

      this.isLoading = false;
      this.manufacturer.goto();
    } else {
      this.setSnackbarNotification(i18n.t(MSG_FORM_INVALID).toString());
    }
  }
}

export default ManufacturerEditForm;
