import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/posts/';

export interface ITranslations {
  [key: string]: {
    slug: string;
    title: string;
    content: string;
    metaDescription: string;
  };
}

export interface IBlogPost {
  id: number;
  headerImage: string;
  translations: ITranslations;
  tags: number[];
  datePublished: string;
  isPublished: boolean;
  kind: string;
  manufacturers: number[];
  isSpotlight: boolean;
  isSponsored: boolean;
}

export const KIND_MAGAZINE = 'magazine';
export const KIND_GLOSSARY = 'glossary';
export const KIND_FAQ = 'faq';
export const KIND_PRESS = 'press';
export const KIND_MANUFACTURER = 'manufacturer';

class BlogPostModel {
  public static async list(kind: string) {
    const resp = await axios.get(API_ENDPOINT + '?kind=' + kind);
    return map(resp.data.results, data => new BlogPostModel(data));
  }

  constructor(protected data: IBlogPost) {}

  public get rawData() {
    return this.data;
  }

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.translations.slug;
  }

  public get title() {
    return this.translations.title;
  }

  public get headerImage() {
    return this.data.headerImage;
  }

  public get content() {
    return this.translations.content;
  }

  public get metaDescription() {
    return this.translations.metaDescription;
  }

  public get tags() {
    return this.data.tags;
  }

  public get manufacturers() {
    return this.data.manufacturers;
  }

  public get datePublished() {
    return this.data.datePublished;
  }

  public get isPublished() {
    return this.data.isPublished;
  }

  public get isSpotlight() {
    return this.data.isSpotlight;
  }

  public get isSponsored() {
    return this.data.isSponsored;
  }

  public get kind() {
    return this.data.kind;
  }

  public toString() {
    return this.slug;
  }

  public matchesQuery(query: string) {
    return this.title && this.title.toLowerCase().includes(query);
  }

  public get route() {
    if (this.kind === KIND_MAGAZINE) {
      return i18nRoute({ name: 'blog-post', params: { blogPostSlug: this.slug } });
    } else if (this.kind === KIND_GLOSSARY) {
      return i18nRoute({ name: 'glossary-entry', params: { glossaryEntrySlug: this.slug } });
    } else if (this.kind === KIND_FAQ) {
      return i18nRoute({ name: 'faq-entry', params: { faqEntrySlug: this.slug } });
    } else if (this.kind === KIND_MANUFACTURER) {
      return i18nRoute({ name: 'manufacturer-news-post', params: { manufacturerNewsSlug: this.slug } });
    } else {
      return i18nRoute({ name: 'press-post', params: { pressPostSlug: this.slug } });
    }
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }
}

export default BlogPostModel;
