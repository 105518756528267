import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';
import { debounce } from 'typescript-debounce-decorator';

import map from 'lodash/map';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';
import shuffle from 'lodash/intersection';

import { CompanyModel, ServiceModel, SearchModel } from '@/models';
import countries from 'i18n-iso-countries';
import { getCountryName } from '@/plugins/i18n';
import { KIND_COMPANY_SERVICE } from '@/models/company';
import { ISearch, SOURCE_SERVICEFINDER } from '@/models/search';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

interface IFilters {
  services: number[];
  countries: string[];
}

const FILTER_DEFAULTS: IFilters = {
  services: [],
  countries: [],
};

@Component<ServiceFinder>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.service-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.service-finder.meta-description').toString() }],
    };
  },
})
class ServiceFinder extends Vue {
  // Sections
  protected showFilterServices = true;
  protected showFilterCountries = true;

  @Getter
  protected ut!: string;

  @Getter
  protected companies!: CompanyModel[];

  @Getter
  protected services!: ServiceModel[];

  // Search Results
  protected results: CompanyModel[] = [];

  // Search Filters
  protected filters: IFilters = clone(FILTER_DEFAULTS);

  @Action
  protected getServices!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getServices();
    this.getCompanies();
    this.getAds();
  }

  protected get goldCompanies() {
    return filter(this.results, m => m.isGold);
  }

  protected get premiumCompanies() {
    return filter(this.results, m => m.isPremium && !m.isGold);
  }

  protected get normalCompanies() {
    return shuffle(filter(this.results, m => !m.isGold && !m.isPremium && m.logo));
  }

  @Watch('companies', { immediate: true })
  @Watch('filters', { deep: true })
  protected search() {
    let results = this.companies;

    results = filter(results, m => m.kind === KIND_COMPANY_SERVICE && !!m.logo);

    if (this.filters.services.length) {
      results = filter(results, r => containsFully(r.services, this.filters.services));
    }

    if (this.filters.countries.length) {
      results = filter(results, r => containsFully(r.countries, this.filters.countries));
    }

    this.results = results;
    this.trackSearch();
  }

  protected get serviceCompanies() {
    return filter(this.companies, m => m.kind === KIND_COMPANY_SERVICE && m.logo);
  }

  protected get companySpotlight() {
    return filter(this.companies, m => m.isSpotlight && m.kind === KIND_COMPANY_SERVICE);
  }

  protected get allCountries() {
    const codes = countries.getAlpha3Codes();
    return map(codes, code => ({ id: code, label: getCountryName(code) }));
  }

  protected clearFilters() {
    this.filters = clone(FILTER_DEFAULTS);
  }

  @debounce(1000, { leading: false })
  protected trackSearch() {
    const query: ISearch = {
      // source of search
      source: SOURCE_SERVICEFINDER.toString(),
      ut: this.ut,
      // robot
      robots: [],
      manufacturers: [],
      useCases: [],
      ceCertificate: false,
      tuvCertificate: false,
      minPayload: 0,
      maxPayload: 0,
      minWidth: 0,
      maxWidth: 0,
      minHeight: 0,
      maxHeight: 0,
      minLength: 0,
      maxLength: 0,
      minWeight: 0,
      maxWeight: 0,
      cleanRoom: false,
      frostEnvironment: false,
      outdoor: false,
      followMe: false,
      hasSense: false,
      minSpeed: 0,
      maxSpeed: 0,
      minBatteryLifetime: 0,
      maxBatteryLifetime: 0,
      // product
      productIsRobot: false,
      productIsInfrastructure: false,
      productCategories: [],
      products: [],
      productSelectedRobots: [],
      // service
      services: this.filters.services,
      serviceCompanies: map(this.results, r => r.id),
      serviceCountries: this.filters.countries,
      // results
      normalResults: this.results.length,
      premiumResults: 0,
      goldResults: 0,
    };
    SearchModel.create(query);
  }
}

export default ServiceFinder;
