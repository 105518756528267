export const LOCALE_EN = 'en';
export const LOCALE_DE = 'de';

export const DEFAULT_LOCALE = LOCALE_EN;
export const LOCALES = [LOCALE_EN, LOCALE_DE];

export const LANGUAGES = [
  {
    value: LOCALE_EN,
    text: 'English',
  },
  {
    value: LOCALE_DE,
    text: 'Deutsch',
  },
];
