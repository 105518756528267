import Vue from 'vue';
import Vuex, { Store, ActionContext, createLogger } from 'vuex';

import max from 'lodash/max';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

import {
  get,
  set,
  mapReduce,
  findByKey,
  filterByKey,
  filterByQuery,
  filterByArrayContains,
  filterByArraysIntersect,
} from '@/utils/vuex';
import {
  UserModel,
  ManufacturerModel,
  RobotModel,
  UseCaseModel,
  NotificationModel,
  BlogPostModel,
  AdModel,
  ServiceModel,
  ProductModel,
  CompanyModel,
  CategoryModel,
  LeadModel,
} from '@/models';
import { IManufacturer } from '@/models/manufacturer';
import { ILoginPayload } from '@/models/user';
import { IRootState } from './types';
import { IRobot } from '@/models/robot';
import { IProduct } from '@/models/product';
import { ICompany } from '@/models/company';
import { ILead } from '@/models/lead';

Vue.use(Vuex);

export const ACTION_BOOT = 'ACTION_BOOT';

const NOTIFICATION_UPDATE_INTERVAL = 10 * 1000; // 10 seconds

function gut() {
  const length = 25;
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  const d = Date.now().toString();
  return result + d.substring(d.length - 10, d.length);
}

const state: IRootState = {
  isBooting: true,

  snackbarNotification: null,

  user: null,
  ut: gut(),
  notifications: [],
  manufacturers: [],
  manufacturersLoaded: false,
  services: [],
  servicesLoaded: false,
  robots: [],
  robotsLoaded: false,
  robot: null,
  useCases: [],
  useCasesLoaded: false,
  tenders: [],
  tendersLoaded: false,
  companies: [],
  companiesLoaded: false,
  products: [],
  productsLoaded: false,
  product: null,
  categories: [],
  categoriesLoaded: false,
  ads: [],
  adsLoaded: false,
  leads: [],
  leadsLoaded: false,
  // conditional loading
  blogPosts: [],
  blogPostsLoaded: false,
  pressPosts: [],
  pressPostsLoaded: false,
  glossaryEntries: [],
  glossaryEntriesLoaded: false,
  faqEntries: [],
  faqEntriesLoaded: false,
  manufacturerNews: [],
  manufacturerNewsLoaded: false,
};

const getters = {
  isBooting: get('isBooting'),

  snackbarNotification: get('snackbarNotification'),

  user: get('user'),
  ut: get('ut'),
  isAuthenticated: (currentState: IRootState) => currentState.user !== null,

  notifications: get('notifications'),

  robot: get('robot'),
  robots: get('robots'),
  robotByID: findByKey('robots', 'id'),
  robotBySlug: findByKey('robots', 'slug'),
  robotsByManufacturer: filterByKey('robots', 'manufacturer'),
  robotsByUseCase: filterByArrayContains('robots', 'useCases'),
  robotsByQuery: filterByQuery('robots'),

  product: get('product'),
  products: get('products'),
  productByID: findByKey('products', 'id'),
  productBySlug: findByKey('products', 'slug'),
  productsByCompany: filterByKey('products', 'company'),
  productsByCategory: filterByArrayContains('products', 'category'),
  productsByQuery: filterByQuery('products'),

  totalMaxPayload: mapReduce('robots', 'maxPayload', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxLength: mapReduce('robots', 'length', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxHeight: mapReduce('robots', 'height', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxWidth: mapReduce('robots', 'width', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxWeight: mapReduce('robots', 'weight', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxBatteryLifetime: mapReduce('robots', 'batteryLifetime', (values: number[]) => Math.max(0, max(values) || 0)),
  totalMaxMaxSpeed: mapReduce('robots', 'maxSpeed', (values: number[]) => Math.max(0, max(values) || 0)),

  manufacturers: get('manufacturers'),
  manufacturerByID: findByKey('manufacturers', 'id'),
  manufacturerBySlug: findByKey('manufacturers', 'slug'),
  manufacturersByQuery: filterByQuery('manufacturers'),

  companies: get('companies'),
  companyByID: findByKey('companies', 'id'),
  companyBySlug: findByKey('companies', 'slug'),
  companiesByQuery: filterByQuery('companies'),
  companiesByService: filterByArrayContains('companies', 'services'),

  ads: get('ads'),

  editableManufacturers: (currentState: IRootState) => {
    if (currentState.user !== null && currentState.user.manufacturers !== null) {
      // @ts-ignore
      return filter(currentState.manufacturers, m => currentState.user.manufacturers.includes(m.id));
    }
    return [];
  },

  editableCompanies: (currentState: IRootState) => {
    if (currentState.user !== null && currentState.user.companies !== null) {
      // @ts-ignore
      return filter(currentState.companies, m => currentState.user?.companies.includes(m.id));
    }
    return [];
  },

  useCases: get('useCases'),
  useCaseByID: findByKey('useCases', 'id'),
  useCaseBySlug: findByKey('useCases', 'slug'),
  useCasesByQuery: filterByQuery('useCases'),

  services: get('services'),
  serviceByID: findByKey('services', 'id'),
  serviceBySlug: findByKey('services', 'slug'),
  servicesByQuery: filterByQuery('services'),

  categories: get('categories'),
  categoryByID: findByKey('categories', 'id'),
  categoryBySlug: findByKey('categories', 'slug'),
  categoriesByQuery: filterByQuery('categories'),

  blogPostBySlug: findByKey('blogPosts', 'slug'),
  blogPostsByTags: filterByArraysIntersect('blogPosts', 'tags'),
  blogPosts: get('blogPosts'),

  pressPostBySlug: findByKey('pressPosts', 'slug'),
  pressPostsByTags: filterByArraysIntersect('pressPosts', 'tags'),
  pressPosts: get('pressPosts'),

  glossaryEntryBySlug: findByKey('glossaryEntries', 'slug'),
  glossaryEntries: get('glossaryEntries'),
  glossaryEntriesByQuery: filterByQuery('glossaryEntries'),

  faqEntryBySlug: findByKey('faqEntries', 'slug'),
  faqEntries: get('faqEntries'),
  faqEntriesByQuery: filterByQuery('faqEntries'),

  manufacturerNewsPostBySlug: findByKey('manufacturerNews', 'slug'),
  manufacturerNews: get('manufacturerNews'),

  // tenders: get('tenders'),
  // tendersByManufacturer: filterByArrayContains('tenders', 'manufacturers'),
  // tenderBySlug: findByKey('tenders', 'slug'),
  leads: get('leads'),
  leadByID: findByKey('leads', 'id'),
  leadsByManufacturer: filterByArrayContains('leads', 'manufacturers'),
};

const mutations = {
  bootCompleted: (currentState: IRootState) => (currentState.isBooting = false),

  setSnackbarNotification: set('notification'),

  setUser: set('user'),
  setNotifications: set('notifications'),

  setRobot: set('robot'),
  setRobots: set('robots'),
  setManufacturers: set('manufacturers'),
  setServices: set('services'),
  setUseCases: set('useCases'),
  setCompanies: set('companies'),
  setProducts: set('products'),
  setProduct: set('product'),
  setCategories: set('categories'),
  setBlogPosts: set('blogPosts'),
  setPressPosts: set('pressPosts'),
  setGlossaryEntries: set('glossaryEntries'),
  setManufacturerNews: set('manufacturerNews'),
  setFAQEntries: set('faqEntries'),
  setAds: set('ads'),
  setLeads: set('leads'),
};

const actions = {
  async getUser({ commit }: ActionContext<IRootState, IRootState>) {
    const user = await UserModel.get();
    commit('setUser', user);
  },

  async login({ commit }: ActionContext<IRootState, IRootState>, payload: ILoginPayload) {
    const user = await UserModel.login(payload);
    commit('setUser', user);
    return user;
  },

  logout({ commit }: ActionContext<IRootState, IRootState>) {
    UserModel.logout();
    commit('setUser', null);
  },

  // tslint:disable-next-line no-shadowed-variable
  async getNotifications({ state, commit }: ActionContext<IRootState, IRootState>) {
    if (state.user) {
      let notifications = await NotificationModel.list();
      notifications = orderBy(notifications, 'createdAt');
      commit('setNotifications', notifications);
    }
  },

  async markNotificationRead(
    // tslint:disable-next-line no-shadowed-variable
    { state, commit }: ActionContext<IRootState, IRootState>,
    notification: NotificationModel,
  ) {
    return await notification.markRead();
  },

  async getManufacturers({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.manufacturersLoaded) {
      const manufacturers = await ManufacturerModel.list();
      commit('setManufacturers', manufacturers);
      state.manufacturersLoaded = true;
    }
  },

  async getCompanies({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.companiesLoaded) {
      const companies = await CompanyModel.list();
      commit('setCompanies', companies);
      state.companiesLoaded = true;
    }
  },

  async updateManufacturer(
    // tslint:disable-next-line no-shadowed-variable
    { state, commit }: ActionContext<IRootState, IRootState>,
    { manufacturer, data }: { manufacturer: ManufacturerModel; data: IManufacturer },
  ) {
    await manufacturer.update(data);
  },

  async updateCompany(
    // tslint:disable-next-line no-shadowed-variable
    { state, commit }: ActionContext<IRootState, IRootState>,
    { company, data }: { company: CompanyModel; data: ICompany },
  ) {
    await company.update(data);
  },

  async getRobots({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.robotsLoaded) {
      let robots = await RobotModel.list();
      robots = orderBy(robots, ['isPremium', 'name'], ['desc', 'asc']);
      commit('setRobots', robots);
      state.robotsLoaded = true;
    }
  },

  // tslint:disable-next-line no-shadowed-variable
  async createRobot({ state, commit }: ActionContext<IRootState, IRootState>, data: IRobot) {
    const robot = await RobotModel.create(data);
    const robots = [...state.robots, robot];
    await commit('setRobots', robots);
    return robot;
  },

  async updateRobot(
    // tslint:disable-next-line no-shadowed-variable
    { state, commit }: ActionContext<IRootState, IRootState>,
    { robot, data }: { robot: RobotModel; data: IRobot },
  ) {
    return await robot.update(data);
  },

  async getProducts({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.productsLoaded) {
      let products = await ProductModel.list();
      products = orderBy(products, ['isPremium', 'name'], ['desc', 'asc']);
      commit('setProducts', products);
      state.productsLoaded = true;
    }
  },

  // tslint:disable-next-line no-shadowed-variable
  async createProduct({ state, commit }: ActionContext<IRootState, IRootState>, data: IProduct) {
    const product = await ProductModel.create(data);
    const products = [...state.products, product];
    await commit('setProducts', products);
    return product;
  },

  async updateProduct(
    // tslint:disable-next-line no-shadowed-variable
    { state, commit }: ActionContext<IRootState, IRootState>,
    { product, data }: { product: ProductModel; data: IProduct },
  ) {
    return await product.update(data);
  },

  async getUseCases({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.useCasesLoaded) {
      const useCases = await UseCaseModel.list();
      commit('setUseCases', useCases);
      state.useCasesLoaded = true;
    }
  },

  async getCategories({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.categoriesLoaded) {
      const categories = await CategoryModel.list();
      commit('setCategories', categories);
      state.categoriesLoaded = true;
    }
  },

  async getServices({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.servicesLoaded) {
      const services = await ServiceModel.list();
      commit('setServices', services);
      state.servicesLoaded = true;
    }
  },

  async getBlogPosts({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.blogPostsLoaded) {
      const blogPosts = await BlogPostModel.list('magazine');
      commit('setBlogPosts', blogPosts);
      state.blogPostsLoaded = true;
    }
  },

  async getPressPosts({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.pressPostsLoaded) {
      const pressPosts = await BlogPostModel.list('press');
      commit('setPressPosts', pressPosts);
      state.pressPostsLoaded = true;
    }
  },

  async getGlossaryEntries({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.glossaryEntriesLoaded) {
      const glossaryEntries = await BlogPostModel.list('glossary');
      commit('setGlossaryEntries', glossaryEntries);
      state.glossaryEntriesLoaded = true;
    }
  },

  async getFAQEntries({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.faqEntriesLoaded) {
      const faqEntries = await BlogPostModel.list('faq');
      commit('setFAQEntries', faqEntries);
      state.faqEntriesLoaded = true;
    }
  },

  async getManufacturerNews({ commit, state }: ActionContext<IRootState, IRootState>) {
    if (!state.manufacturerNewsLoaded) {
      const manufacturerNews = await BlogPostModel.list('manufacturer');
      commit('setManufacturerNews', manufacturerNews);
      state.manufacturerNewsLoaded = true;
    }
  },

  async getAds({ commit }: ActionContext<IRootState, IRootState>) {
    if (!state.adsLoaded) {
      const ads = await AdModel.list();
      commit('setAds', ads);
      state.adsLoaded = true;
    }
  },

  async getLeads({ commit }: ActionContext<IRootState, IRootState>) {
    const ads = await LeadModel.list();
    commit('setLeads', ads);
  },

  // tslint:disable-next-line no-shadowed-variable
  async createLead({ state, commit }: ActionContext<IRootState, IRootState>, data: ILead) {
    const lead = await LeadModel.create(data);
    return lead;
  },

  // async getTenders({ commit, state }: ActionContext<IRootState, IRootState>) {
  //   const tenders = await TenderModel.list();
  //   commit('setTenders', tenders);
  //   state.tendersLoaded = true;
  // },

  [ACTION_BOOT]: async ({ commit, dispatch }: ActionContext<IRootState, IRootState>) => {
    Promise.all([
      dispatch('getUser'),
      dispatch('getManufacturers'),
      dispatch('getServices'),
      dispatch('getRobots'),
      dispatch('getCompanies'),
      dispatch('getProducts'),
      dispatch('getCategories'),
      dispatch('getUseCases'),
      dispatch('getAds'),
    ]).then(() => {
      commit('bootCompleted');
      dispatch('getNotifications');
      window.setInterval(() => dispatch('getNotifications'), NOTIFICATION_UPDATE_INTERVAL);
    });
  },
};

const plugins = process.env.NODE_ENV === 'development' ? [createLogger()] : [];

const store: Store<IRootState> = new Vuex.Store({
  state,
  // @ts-ignore
  getters,
  mutations,
  actions,
  // @ts-ignore
  plugins,
});

export default store;
