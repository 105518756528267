import { Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import RobotModel from '@/models/robot';
import { ManufacturerModel } from '@/models';
import { EntityDetailView } from '@/utils/views';

@Component<RobotCreate>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.robot-create.title').toString(),
    };
  },
})
class RobotCreate extends EntityDetailView {
  @Getter
  protected manufacturerBySlug!: (slug: string) => ManufacturerModel;

  @Action
  protected createRobot!: (data: FormData) => RobotModel;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  @Action
  protected getUseCases!: () => void;

  protected async mounted() {
    this.getRobots();
    this.getManufacturers();
    this.getUseCases();
  }

  protected get entity() {
    const { manufacturerSlug } = this.$route.params;
    return this.manufacturerBySlug(manufacturerSlug);
  }
}

export default RobotCreate;
