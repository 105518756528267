import { Vue, Component, Ref } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import { VuetifyForm } from '@/plugins/vuetify';
import { i18nRoute } from '@/plugins/i18n';

import rules from '@/utils/validation';

@Component<ToolROI>({
  metaInfo(): MetaInfo {
    return {
      // TODO(abresk) change here
      title: this.$i18n.t('page.tool-roi.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.tool-roi.meta-description').toString() }],
    };
  },
})
class ToolROI extends Vue {
  protected readonly rules = rules;

  protected isLoading = false;
  protected isValid = false;
  protected showSuccess = false;

  protected transportStep1 = false;
  protected transportStep2 = false;
  protected transportStep3 = false;
  protected transportStep4 = false;

  protected cleaningStep1 = false;
  protected cleaningStep2 = false;
  protected cleaningStep3 = false;

  protected selectedUsecase: string | null = null;
  protected selectUsecaseTransport: string | null = null;
  // transport
  protected transportDistance = 100;
  protected transportPerHour = 10;
  protected maxWeightPerTransport = 100;
  protected numberOfShifts = 2;
  protected currentDevices = 0;
  protected currentDevicesMaintenanceCost = 4000;
  protected currentDevicesRentCost = 10000;
  protected currentManualDriverFTE = 0;
  protected currentManualDriverSalary = 35000;
  protected currentAdditionalFTEPerShift = 0;
  protected currentAdditionalSalary = 0;

  // model
  protected calcDevices = 0;
  protected calcAcquisitionCost = 0;
  protected calcMaintenanceCost = 0;
  protected calcMaintenancePercentage = 0.1;
  protected calcRobotOperators = 0;
  protected calcRobotOperatorSalary = 38500;

  // roi
  protected roiBreakEvenMonth = 0;
  protected roiIsBreakEven = false;
  protected roiMonthlyManual = 0;
  protected roiMonthlyRobot = 0;
  protected roiSavingRatio = 0;

  // constants
  protected transportSpeed = 2.0;

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected readonly usecases = [
    {
      id: 'transport',
      icon: 'mdi-map-marker-distance',
      title: 'Transport',
    },
    // {
    //   id: 'cleaning',
    //   icon: 'mdi-vacuum',
    //   title: 'Cleaning',
    // },
  ];

  protected get termsLabel() {
    const terms = this.$router.resolve(i18nRoute({ name: 'terms' })).href;
    const privacy = this.$router.resolve(i18nRoute({ name: 'privacy' })).href;
    const guidelines = this.$router.resolve(i18nRoute({ name: 'guidelines' })).href;
    return this.$t('view.signup.field.terms.label', { terms, privacy, guidelines });
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.tools.breadcrumb.index').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'tools' }),
      },
      {
        text: 'ROI',
        disabled: true,
        href: i18nRoute({ name: 'tools-roi' }),
      },
    ];
  }

  protected processModel() {
    // calcDevices
    // TODO(abresk): put in shifts and ladezeiten
    // number of m/s overall
    const mps = (this.transportDistance + this.transportPerHour) / 60 / 60;
    const robots = Math.max(1, mps / this.transportSpeed);
    this.calcDevices = Math.round(robots);
    // calcAcquisitionCost
    if (this.selectedUsecase === 'transport') {
      switch (this.selectUsecaseTransport) {
        case 'box':
          this.calcAcquisitionCost = 25000;
          break;
        case 'pallet':
          this.calcAcquisitionCost = 60000;
          break;
        case 'cart':
          this.calcAcquisitionCost = 35000;
          break;
      }
    } else if (this.selectedUsecase === 'cleaning') {
      this.calcAcquisitionCost = 35000;
    }
    // calcMaintenanceCost
    this.calcMaintenanceCost = this.calcDevices * this.calcAcquisitionCost * this.calcMaintenancePercentage;
    // calcRobotOperators
    this.calcRobotOperators = this.calcDevices * 0.1;
    this.calcRobotOperators = Math.round((this.calcRobotOperators + Number.EPSILON) * 100) / 100;
  }

  protected processModelTransport() {
    this.processModel();
    this.transportStep3 = true;
  }

  protected processModelCleaning() {
    this.processModel();
    this.cleaningStep2 = true;
  }

  protected calculateROI() {
    // pre
    this.roiIsBreakEven = false;
    // calculate manual cost
    const manualDeviceCost =
      (this.currentDevices * this.currentDevicesRentCost + this.currentDevicesMaintenanceCost * this.currentDevices) /
      12;
    const manualDriverCost = (this.numberOfShifts * this.currentManualDriverFTE * this.currentManualDriverFTE) / 12;
    const manualLaborCost =
      (this.numberOfShifts * this.currentAdditionalFTEPerShift * this.currentAdditionalSalary) / 12;

    // calcualte robot model cost
    const robotAcqCost = this.calcDevices * this.calcAcquisitionCost;
    const robotCost = (this.calcDevices * this.calcMaintenanceCost) / 12;
    const robotOpCost = (this.calcRobotOperators * this.calcRobotOperatorSalary) / 12;
    // calclate ROI
    const m = 12 * 8; // 8 yrs to calcualte
    let robotModel = robotAcqCost;
    let manualModel = 0;
    let breakeven = 0;
    for (let i = 0; i < m; i++) {
      robotModel += robotCost + robotOpCost;
      manualModel += manualDeviceCost + manualDriverCost + manualLaborCost;
      if (robotModel < manualModel && !this.roiIsBreakEven) {
        breakeven = i;
        this.roiIsBreakEven = true;
      }
    }
    // monthly cost
    this.roiMonthlyManual =
      Math.round((manualDeviceCost + manualDriverCost + manualLaborCost + Number.EPSILON) * 100) / 100;
    this.roiMonthlyRobot = Math.round((robotCost + robotOpCost + Number.EPSILON) * 100) / 100;
    this.roiSavingRatio =
      Math.round(((1 - this.roiMonthlyRobot / this.roiMonthlyManual) * 100 + Number.EPSILON) * 100) / 100;
    this.roiBreakEvenMonth = breakeven;
  }

  protected calculateROITransport() {
    this.calculateROI();
    this.transportStep4 = true;
  }

  protected calculateROICleaning() {
    this.calculateROI();
    this.cleaningStep3 = true;
  }
}

export default ToolROI;
