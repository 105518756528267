import i18n from '@/plugins/i18n';

export const isRequired = (value: any) => {
  if (value instanceof Array) {
    return value.length > 0 || i18n.t('utils.validation.errors.required').toString();
  }
  return !!value || i18n.t('utils.validation.errors.required').toString();
};

const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,32})+$/;
export const isEmail = (value: string) =>
  reEmail.test(value) || i18n.t('utils.validation.errors.invalid-email').toString();

export const reYoutubeLink = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)(?<youtubeVideoId>[^#&?]*).*/;
export const isYoutubeLink = (value: string) =>
  reYoutubeLink.test(value) || i18n.t('utils.validation.errors.invalid-youtube-link').toString();

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
export const isValidPassword = (value: string) =>
  (value.length >= 8 && /\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value)) ||
  i18n.t('utils.validation.errors.invalid-password').toString();

export const isPasswordEqual = (password: string) => (value: string) =>
  value === password || i18n.t('utils.validation.errors.passwords-not-equal').toString();

export default {
  required: isRequired,
  email: isEmail,
  youtubeLink: isYoutubeLink,
  password: isValidPassword,
  passwordEqual: isPasswordEqual,
};
