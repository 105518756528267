import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ManufacturerModel, RobotModel } from '@/models';

@Component
class ListRobots extends Vue {
  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  @Getter
  protected robotsByManufacturer!: (id: number) => RobotModel[];

  protected get robots() {
    return this.manufacturer ? this.robotsByManufacturer(this.manufacturer.id) : undefined;
  }
}

export default ListRobots;
