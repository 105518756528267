import axios from 'axios';

axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export default axios;
