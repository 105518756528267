import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

export type VuetifyForm = Vue & { validate: () => boolean; $el: HTMLFormElement };

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#0E2037',
        secondary: '#A8DBB8',
      },
    },
  },
});
