import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment-timezone';

import i18n from '@/plugins/i18n';

@Component
class DateTime extends Vue {
  @Prop({ type: String, required: true })
  protected date!: string;

  @Prop({ type: String, default: 'lll' })
  protected format!: string;

  protected get dateFormat(): string {
    moment.locale(i18n.locale);
    return moment.tz(this.date, moment.tz.guess()).format(this.format);
  }
}

export default DateTime;
