import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/event/';

export const EVENT_TELEPHONE = 'telephone';
export const EVENT_WEBSITE = 'website';
export const EVENT_EXPAND_SEARCH = 'expand_search';

export const SOURCE_PROFILE_PAGE = 'profile';
export const SOURCE_ROBOT_FINDER = 'robot_finder';
export const SOURCE_MANUFACTURER_FINDER = 'manufacturer_finder';
export const SOURCE_COMPANY_FINDER = 'company_finder';
export const SOURCE_PRODUCT_FINDER = 'product_finder';

export const TYPE_ROBOT = 'robot';
export const TYPE_MANUFACTURER = 'manufacturer';
export const TYPE_PRODUCT = 'product';
export const TYPE_COMPANY = 'company';
export const TYPE_SEARCH = 'search';

export interface IEvent {
  source: string;
  event: string;
  type: string;
  id: number;
}

class EventModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new EventModel(data));
  }

  public static async create(data: IEvent) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new EventModel(resp.data);
  }
  constructor(protected data: IEvent) {}

  public get rawData() {
    return this.data;
  }
}

export default EventModel;
