import { Component, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';

import map from 'lodash/map';

import rules, { reYoutubeLink } from '@/utils/validation';
import RobotModel, { IRobotAsset, ASSET_KIND_IMAGE, ASSET_KIND_YOUTUBE_VIDEO } from '@/models/robot';

import RobotWizardStep from './RobotWizardStep';
import { debounce } from 'typescript-debounce-decorator';

@Component
class RobotWizardStepMedia extends RobotWizardStep {
  protected readonly ASSET_KIND_IMAGE = ASSET_KIND_IMAGE;
  protected readonly ASSET_KIND_YOUTUBE_VIDEO = ASSET_KIND_YOUTUBE_VIDEO;
  protected readonly rules = rules;

  protected isLoadingAssets = false;

  protected imagesAndVideos: IRobotAsset[] = [];
  protected documents: IRobotAsset[] = [];

  protected image: File | null = null;
  protected isLoadingImage = false;
  protected youtubeVideo = '';
  protected isLoadingYoutubeVideo = false;

  protected document: File | null = null;
  protected documentTitle = '';
  protected isLoadingDocument = false;
  protected isValidDocument = false;

  @Getter
  protected robot!: RobotModel;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected computeAssets() {
    this.imagesAndVideos = this.robot.imagesAndVideos;
    this.documents = this.robot.documents;
  }

  @Watch('robot', { immediate: true })
  protected async onRobotChanged() {
    if (this.robot) {
      this.isLoadingAssets = true;
      await this.robot.listAssets();
      this.computeAssets();
      this.isLoadingAssets = false;
    }
  }

  @Watch('image')
  protected async onImageChanged() {
    if (this.image) {
      this.isLoadingImage = true;

      const asset = new FormData();
      asset.set('file', this.image);
      await this.robot.createAsset(asset);
      this.computeAssets();

      this.image = null;

      this.isLoadingImage = false;
    }
  }

  @Watch('youtubeVideo')
  protected async onYoutubeVideoChanged() {
    const match = this.youtubeVideo.match(reYoutubeLink);
    if (match) {
      this.isLoadingYoutubeVideo = true;

      // @ts-ignore
      const { youtubeVideoId } = match.groups;
      // @ts-ignore
      await this.robot.createAsset({ youtubeVideoId });
      this.computeAssets();

      this.youtubeVideo = '';

      this.isLoadingYoutubeVideo = false;
    }
  }

  protected async uploadDocument() {
    if (this.document) {
      this.isLoadingDocument = true;

      const asset = new FormData();
      asset.set('file', this.document);
      asset.set('title', this.documentTitle);
      await this.robot.createAsset(asset);
      this.computeAssets();

      this.document = null;
      this.documentTitle = '';

      this.isLoadingDocument = false;
    }
  }

  protected async deleteAsset(asset: IRobotAsset) {
    await this.robot.deleteAsset(asset);
    this.computeAssets();
  }

  protected async onAssetsReordered(assets: IRobotAsset[]) {
    this.imagesAndVideos = assets;

    // @ts-ignore
    const order: number[] = map(assets, 'id');
    await this.robot.reorderAssets(order);
    this.computeAssets();
  }

  @debounce(1000, { leading: false })
  protected async updateDocumentTitle(asset: IRobotAsset) {
    await this.robot.updateAsset(asset);
  }

  protected getFilename(path: string) {
    const name = path.split('/').pop();
    return name;
  }
}

export default RobotWizardStepMedia;
