var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-section',[(_vm.isEditor)?_c('div',{staticClass:"mb-12 d-flex"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":_vm.setEditMode}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("\n      "+_vm._s(_vm.$t('view.service-company.edit-button.label'))+"\n    ")],1)],1):_vm._e(),_c('h1',{staticClass:"display-3 my-4"},[_vm._v("\n    "+_vm._s(_vm.company.name)+"\n\n    "),(_vm.company.isPremium)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"x-large":"","color":"blue darken-1"}},on),[_vm._v("mdi-check-decagram")])]}}],null,false,2578434050)},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('view.service-company.premium.tooltip'))+"\n      ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isEditor && _vm.company.isDirty)?_c('v-chip',_vm._g({attrs:{"color":"primary","text-color":"white","small":"","label":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-vector-difference")]),_vm._v("\n          "+_vm._s(_vm.$t('view.service-company.unapproved-changes.label'))+"\n        ")],1):_vm._e()]}}])},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('view.service-company.unapproved-changes.tooltip'))+"\n      ")])])],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"7"}},[(_vm.company.description)?_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.company.description)}}):_vm._e(),(_vm.company.isPremium || _vm.company.isGold)?_c('v-btn',{staticClass:"primary mt-8 mb-8 mx-4",attrs:{"href":_vm.company.websiteTracking,"text":""},nativeOn:{"click":function($event){return _vm.executeWebsite($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-play")]),_vm._v("\n         "+_vm._s(_vm.$t('view.premium.website.cta'))+"\n      ")],1):_vm._e(),(_vm.company.isGold && _vm.company.telephone)?_c('v-btn',{staticClass:"primary mt-8 mb-8 mx-4",attrs:{"text":""},on:{"click":_vm.switchTelephone}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-phone-forward")]),_vm._v("\n         "+_vm._s(_vm.telephoneNumber)+"\n      ")],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"xs":"12","md":"3","offset-md":"2"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-img',{staticClass:"ma-2",attrs:{"src":_vm.company.logo,"contain":"","max-height":"150"}})],1),_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[(_vm.company.isGold)?_c('dialog-lead',{attrs:{"company":_vm.company,"kind":"company"}}):_vm._e()],1),_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('p',{staticClass:"mt-2"},[(!_vm.company.isClaimed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"blue--text text--darken-3",attrs:{"text":"","to":_vm.$i18nRoute({ name: 'signup' })}},on),[_c('v-icon',{attrs:{"left":"","color":"blue darken-3"}},[_vm._v("mdi-shield-check")]),_vm._v("\n                "+_vm._s(_vm.$t('view.service-company.claim-business.button.label'))+"\n              ")],1)]}}],null,false,2245575256)},[_c('span',[_vm._v(_vm._s(_vm.$t('view.service-company.claim-business.button.tooltip')))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"green--text text--darken-3"},on),[_c('v-icon',{attrs:{"left":"","color":"green darken-3"}},[_vm._v("mdi-shield-check")]),_vm._v("\n                "+_vm._s(_vm.$t('view.service-company.business-claimed.label'))+"\n              ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('view.service-company.business-claimed.tooltip')))])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }