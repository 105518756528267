import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter } from 'vuex-class';

import { TenderModel, UserModel } from '@/models';

@Component<Tenders>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.tenders.title').toString(),
    };
  },
})
class Tenders extends Vue {
  @Getter
  protected isAuthenticated!: boolean;

  @Getter
  protected user!: null | UserModel;

  @Getter
  protected tenders!: () => TenderModel[];

  @Getter
  protected tendersByManufacturer!: (id: number) => TenderModel[];

  @Watch('isAuthenticated', { immediate: true })
  protected onIsAuthenticated() {
    if (!this.isAuthenticated) {
      this.$router.push('/');
    }
  }

  protected get openTenders(): TenderModel[] {
    if (this.user) {
      if (this.user.manufacturers.length > 0) {
        const id = this.user.manufacturers[0];
        return this.tendersByManufacturer(id);
      }
    }
    return [] as TenderModel[];
  }
}

export default Tenders;
