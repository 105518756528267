import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<ThankYou>({
  metaInfo(): MetaInfo {
    return {
      // TODO(abresk): change here
      title: this.$i18n.t('page.thank-you.title').toString(),
    };
  },
})
class ThankYou extends Vue {
  public get message() {
    const { component } = this.$route.params;
    switch (component) {
      case 'consulting':
        return this.$i18n.t('view.thank-you.consulting.text').toString();
      case 'whitepaper':
        return this.$i18n.t('view.thank-you.whitepaper.text').toString();
      case 'premium':
        return this.$i18n.t('view.thank-you.premium.text').toString();
      default:
        return this.$i18n.t('view.thank-you.default.text').toString();
    }
  }
}

export default ThankYou;
