import { Vue, Component, Prop } from 'vue-property-decorator';
import { ManufacturerModel, EventModel } from '@/models';
import { TYPE_MANUFACTURER, SOURCE_PROFILE_PAGE, EVENT_TELEPHONE, EVENT_WEBSITE, IEvent } from '@/models/event';

@Component
class ManufacturerDetails extends Vue {
  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  protected showTelephone = false;

  protected setEditMode() {
    // @ts-ignore
    this.$router.push({
      ...this.manufacturer.route,
      query: {
        edit: '1',
      },
    });
  }

  protected switchTelephone() {
    this.executeTelephone();
    this.showTelephone = !this.showTelephone;
  }

  protected get telephoneNumber() {
    if (this.showTelephone) {
      return this.manufacturer.telephone;
    } else {
      return this.$i18n.t('view.premium.call.cta');
    }
  }

  protected executeTelephone() {
    const query: IEvent = {
      type: TYPE_MANUFACTURER.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_TELEPHONE.toString(),
      id: this.manufacturer.id,
    };
    EventModel.create(query);
  }

  protected executeWebsite() {
    const query: IEvent = {
      type: TYPE_MANUFACTURER.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_WEBSITE.toString(),
      id: this.manufacturer.id,
    };
    EventModel.create(query);
  }
}

export default ManufacturerDetails;
