import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<Feedback>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.feedback.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('page.feedback.meta-description').toString(),
        },
      ],
    };
  },
})
class Feedback extends Vue {}

export default Feedback;
