import { Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import slice from 'lodash/slice';
import shuffle from 'lodash/shuffle';

import { BlogPostModel } from '@/models';
import { EntityDetailView } from '@/utils/views';
import { MAX_META_DESCRIPTION_LENGTH } from '@/constants/meta';
import { i18nRoute } from '@/plugins/i18n';

const OTHER_ENTRIES = 5;

@Component<FAQEntry>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity
        ? this.entity.title + ' - ' + this.$i18n.t('page.glossary.title').toString()
        : this.$i18n.t('page.glossary.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.entity
            ? this.entity.metaDescription !== ''
              ? this.entity.metaDescription
              : this.entity.content.replace(/<(.|\n)*?>/g, '').substr(MAX_META_DESCRIPTION_LENGTH)
            : this.$i18n.t('page.glossary.meta-description').toString(),
        },
      ],
    };
  },
})
class FAQEntry extends EntityDetailView {
  @Getter
  protected faqEntryBySlug!: (slug: string) => BlogPostModel;

  @Getter
  protected faqEntries!: BlogPostModel[];

  @Action
  protected getFAQEntries!: () => void;

  protected isLoading = true;

  protected async mounted() {
    this.loadData();
  }

  @Watch('isBooting')
  protected loadData() {
    if (this.isBooting === false) {
      this.getFAQEntries();
      this.isLoading = false;
    }
  }

  @Watch('isLoading')
  protected get entity() {
    const { faqEntrySlug } = this.$route.params;
    return this.faqEntryBySlug(faqEntrySlug);
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.faq-post.breadcrumb.faq').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'faq' }),
      },
      {
        text: this.entity.title,
        disabled: true,
        href: this.entity.route,
      },
    ];
  }

  protected get otherEntries() {
    return slice(shuffle(this.faqEntries), 0, OTHER_ENTRIES);
  }
}

export default FAQEntry;
