import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { ProductModel, CompanyModel, CategoryModel, EventModel } from '@/models';
import { IEvent, TYPE_PRODUCT, SOURCE_PROFILE_PAGE, EVENT_WEBSITE, EVENT_TELEPHONE } from '@/models/event';

import {
  ASSET_KIND_IMAGE,
  ASSET_KIND_YOUTUBE_VIDEO,
  ASSET_KIND_DOCUMENT,
  KIND_PRODUCT_INFRASTRUCTURE,
  KIND_PRODUCT_ROBOT,
} from '@/models/product';

@Component
class ProductDetails extends Vue {
  protected readonly dialog = false;

  protected readonly ASSET_KIND_IMAGE = ASSET_KIND_IMAGE;
  protected readonly ASSET_KIND_YOUTUBE_VIDEO = ASSET_KIND_YOUTUBE_VIDEO;
  protected readonly ASSET_KIND_DOCUMENT = ASSET_KIND_DOCUMENT;
  protected readonly KIND_ROBOT = KIND_PRODUCT_ROBOT;
  protected readonly KIND_INFRASTRUCTURE = KIND_PRODUCT_INFRASTRUCTURE;

  protected isLoadingAssets = false;

  protected showTelephone = false;

  @Prop({ required: true })
  protected product!: ProductModel;

  @Prop({ required: true })
  protected company!: CompanyModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  @Getter
  protected categoryByID!: (id: number) => CategoryModel[];

  @Watch('product', { immediate: true })
  protected async onProductChanged() {
    if (this.product) {
      this.isLoadingAssets = true;
      await this.product.listAssets();
      this.isLoadingAssets = false;
    }
  }

  protected get categories() {
    if (this.product) {
      const categories = map(this.product.category, id => this.categoryByID(id));
      return filter(categories, c => !!c);
    }
    return [];
  }

  protected get usp1() {
    if (this.product.usp1 !== '') {
      return [this.product.usp1];
    }
    return [];
  }

  protected get usp2() {
    if (this.product.usp2 !== '') {
      return [this.product.usp2];
    }
    return [];
  }

  protected get usp3() {
    if (this.product.usp3 !== '') {
      return [this.product.usp3];
    }
    return [];
  }

  protected switchTelephone() {
    this.executeTelephone();
    this.showTelephone = !this.showTelephone;
  }

  protected get telephoneNumber() {
    if (this.showTelephone) {
      return this.company.telephone;
    } else {
      return this.$i18n.t('view.premium.call.cta');
    }
  }

  protected setEditMode() {
    // @ts-ignore
    this.$router.push({
      ...this.product.route,
      query: {
        edit: '1',
      },
    });
  }

  protected executeTelephone() {
    const query: IEvent = {
      type: TYPE_PRODUCT.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_TELEPHONE.toString(),
      id: this.product.id,
    };
    EventModel.create(query);
  }

  protected executeWebsite() {
    const query: IEvent = {
      type: TYPE_PRODUCT.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_WEBSITE.toString(),
      id: this.product.id,
    };
    EventModel.create(query);
  }
}

export default ProductDetails;
