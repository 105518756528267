import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';

interface State {
  [key: string]: any;
}

export const set = (property: string) => (state: State, payload: any) => (state[property] = payload);
export const toggle = (property: string) => (state: State) => (state[property] = !state[property]);
export const get = (property: string) => (state: State) => state[property];

export const findByKey = (property: string, key: string) => (state: State) => {
  return (value: string) => find(state[property], o => o[key] === value);
};

export const filterByKey = (property: string, key: string) => (state: State) => {
  return (value: string) => filter(state[property], o => o[key] === value);
};

export const filterByArrayContains = (property: string, key: string) => (state: State) => {
  return (value: string) => filter(state[property], o => o[key] instanceof Array && o[key].includes(value));
};

export const filterByArraysIntersect = (property: string, key: string) => (state: State) => {
  return (values: any[]) =>
    filter(state[property], o => o[key] instanceof Array && intersection(o[key], values).length > 0);
};

export const filterByQuery = (property: string) => (state: State) => {
  return (value: string) => filter(state[property], o => o.matchesQuery(value));
};

export const mapReduce = (property: string, key: string, reducer: (values: any[]) => any) => (state: State) => {
  return reducer(map(state[property], o => o[key]));
};

// @ts-ignore
export const mapFiles = files =>
  files.keys().map((key: string) => {
    const res = files(key);
    res.slug = key.slice(2, -5);
    return res;
  });
