import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/ads/';

export interface ITranslations {
  [key: string]: {
    cta: string;
    title: string;
    text: string;
    visual: string;
    url: string;
  };
}

export interface IAd {
  id: number;
  spots: string[];
  campaign: string;
  isActive: boolean;
  translations: ITranslations;
}

class AdModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new AdModel(data));
  }

  constructor(protected data: IAd) {}

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get spots() {
    return this.data.spots;
  }

  public get isActive() {
    return this.data.isActive;
  }

  public get campaign() {
    return this.data.campaign;
  }

  public get cta() {
    return this.translations.cta;
  }

  public get title() {
    return this.translations.title;
  }

  public get visual() {
    return this.translations.visual;
  }

  public get url() {
    return this.translations.url;
  }

  public get text() {
    return marked(this.translations.text || '');
  }
}

export default AdModel;
