import Vue from 'vue';
import Router from 'vue-router';

import routes from './routes';

Vue.use(Router);

// @ts-ignore
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
