import { Vue, Component, Prop } from 'vue-property-decorator';

import { CompanyModel, ClickModel } from '@/models';
import { TYPE_COMPANY, IClick } from '@/models/click';

@Component
class TileCompany extends Vue {
  @Prop()
  protected company!: CompanyModel;

  @Prop()
  protected type!: string;

  @Prop({ type: String, default: 'none' })
  protected source!: string;

  protected executeClick() {
    const query: IClick = {
      // source of search
      source: this.source,
      // robot
      type: TYPE_COMPANY.toString(),
      id: this.company.id,
    };
    ClickModel.create(query);
  }
}

export default TileCompany;
