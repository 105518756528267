import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import { i18nRoute } from '@/plugins/i18n';

import { RobotModel, UseCaseModel } from '@/models';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === set.length;

export interface IScored {
  robot: RobotModel;
  value: number;
}

@Component<ToolRobotSimilar>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.robot-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.robot-finder.meta-description').toString() }],
    };
  },
})
class ToolRobotSimilar extends Vue {
  // Sections
  protected showFilterUseCase = true;
  protected showFilterManufacturers = false;
  protected showFilterSecurity = false;
  protected showFilterMaxPayload = false;
  protected showFilterDimensions = false;
  protected showFilterEnvironment = false;
  protected showFilterOthers = false;

  @Getter
  protected robotBySlug!: (slug: string) => RobotModel;

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected useCases!: UseCaseModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get entity() {
    const { robotSlug } = this.$route.params;
    return this.robotBySlug(robotSlug);
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.tools.breadcrumb.index').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'tools' }),
      },
      {
        text: 'Robot DNA',
        disabled: true,
        href: i18nRoute({ name: 'tools-similar-robots' }),
      },
    ];
  }

  protected get calculated() {
    // first filter
    const filtered = filter(this.robots, r => containsFully(r.useCases, this.entity.useCases));
    // now scoring
    const scored: Array<IScored> = [];
    filtered.forEach(x => scored.push({ robot: x, value: Math.round(this.entity.dna(x) * 100) }));
    return orderBy(
      filter(scored, s => s.robot.slug !== this.entity.slug && s.value > 50),
      ['value'],
      ['desc'],
    );
  }
}

export default ToolRobotSimilar;
