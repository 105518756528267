import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<LobReport>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.lob-report.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.lob-report.meta-description').toString() }],
    };
  },
})
class LobReport extends Vue {}

export default LobReport;
