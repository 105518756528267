import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { NotificationModel, RobotModel, ManufacturerModel, ProductModel, CompanyModel } from '@/models';
import { Getter, Action } from 'vuex-class';

interface IContext {
  manufacturer?: ManufacturerModel | null;
  robot?: RobotModel | null;
  company?: CompanyModel | null;
  product?: ProductModel | null;
  [key: string]: any;
}

@Component
class Notification extends Vue {
  @Prop({ required: true })
  protected notification!: NotificationModel;

  protected context: IContext = {};

  @Getter
  protected robotByID!: (id: number) => RobotModel;

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Getter
  protected productByID!: (id: number) => ProductModel;

  @Getter
  protected companyByID!: (id: number) => CompanyModel;

  @Action
  protected markNotificationRead!: (notification: NotificationModel) => void;

  @Watch('notification', { immediate: true })
  protected async onNotificationChanged() {
    if (this.notification) {
      let manufacturer = null;
      let company = null;
      let product = null;
      let robot = null;

      if (this.notification.recipient !== null) {
        manufacturer = await this.manufacturerByID(this.notification.recipient);
      }
      if (this.notification.company !== null) {
        company = await this.companyByID(this.notification.company);
      }

      if (this.notification.product !== null) {
        product = await this.productByID(this.notification.product);
      }

      if (this.notification.robot !== null) {
        robot = await this.robotByID(this.notification.robot);
      }

      this.context = { ...this.notification.context, manufacturer, robot, company, product };
    }
  }

  protected get text() {
    return this.$t('models.notification.kind.' + this.notification.kind, this.context || {});
  }

  protected get avatar() {
    if (this.context) {
      if (this.context.robot) {
        return this.context.robot.image;
      }

      if (this.context.manufacturer) {
        return this.context.manufacturer.logo;
      }

      if (this.context.product) {
        return this.context.product.image;
      }

      if (this.context.company) {
        return this.context.company.logo;
      }
    }

    return null;
  }

  protected async click() {
    this.markNotificationRead(this.notification);

    if (this.context) {
      if (this.context.robot) {
        return this.context.robot.goto();
      }

      if (this.context.manufacturer) {
        return this.context.manufacturer.goto();
      }

      if (this.context.company) {
        return this.context.comapny.goto();
      }

      if (this.context.product) {
        return this.context.product.goto();
      }
    }
  }
}

export default Notification;
