import { Vue, Component, Prop } from 'vue-property-decorator';

import { ProductModel, CompanyModel } from '@/models';
import { Mutation, Getter } from 'vuex-class';

@Component
class ProductWizardStep extends Vue {
  @Prop({ type: Number, required: true })
  protected step!: number;

  @Prop({ type: Number, required: true })
  protected currentStep!: number;

  @Prop({ required: true })
  protected company!: CompanyModel;

  @Getter
  protected product!: ProductModel | null;

  @Mutation
  protected setProduct!: (product: ProductModel | null) => void;

  protected nextStep() {
    this.$emit('step:next');
  }

  protected prevStep() {
    this.$emit('step:prev');
  }
}

export default ProductWizardStep;
