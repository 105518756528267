import { Vue, Component, Prop } from 'vue-property-decorator';

import { ManufacturerModel } from '@/models';
import { i18nRoute } from '@/plugins/i18n';

@Component
class TileRobotCreate extends Vue {
  @Prop()
  protected manufacturer!: ManufacturerModel;

  protected get route() {
    if (this.manufacturer) {
      return i18nRoute({ name: 'robot:create', params: { manufacturerSlug: this.manufacturer.slug } });
    }
    return '';
  }
}

export default TileRobotCreate;
