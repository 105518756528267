import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import take from 'lodash/take';
import orderBy from 'lodash/orderBy';
import { i18nRoute } from '@/plugins/i18n';

import { RobotModel } from '@/models';

@Component<ToolRobotNew>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.tool-robot-new.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.tool-robot-new.meta-description').toString() }],
    };
  },
})
class ToolRobotNew extends Vue {
  @Getter
  protected robots!: RobotModel[];

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getRobots();
    this.getManufacturers();
  }

  @Watch('robots', { immediate: true })
  protected get sortedRobots() {
    return take(orderBy(this.robots, ['id'], ['desc']), 10);
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.tools.breadcrumb.index').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'tools' }),
      },
      {
        text: this.$t('view.tools.breadcrumb.new-robots').toString(),
        disabled: true,
        href: i18nRoute({ name: 'tools-new-robots' }),
      },
    ];
  }
}

export default ToolRobotNew;
