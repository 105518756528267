import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Location } from 'vue-router';
import countries from 'i18n-iso-countries';

import { LOCALE_EN, LOCALE_DE, DEFAULT_LOCALE, LOCALES } from '@/constants/i18n';

// locales
import en from '@/i18n/en.json';
import de from '@/i18n/de.json';

// country locales
import countriesEN from '@/i18n/en_countries.json';
import countriesDE from '@/i18n/de_countries.json';

Vue.use(VueI18n);

// @ts-ignore
countries.registerLocale(countriesEN);
countries.registerLocale(countriesDE);

export const guessLanguage = () => {
  // @ts-ignore
  const lang = (window.navigator.language || window.navigator.userLanguage || DEFAULT_LOCALE).split('-')[0];
  if (LOCALES.includes(lang)) {
    return lang;
  }
  return DEFAULT_LOCALE;
};

const i18n = new VueI18n({
  locale: guessLanguage(),
  fallbackLocale: DEFAULT_LOCALE,
  messages: { en, de },
});

export const i18nRoute = (to: Location) => ({ ...to, name: `${i18n.locale}:${to.name}` });

// patching Vue to use `$i18nRoute` within component templates
Vue.prototype.$i18nRoute = i18nRoute;

export const getCountryName = (code: string | number) => countries.getName(code, i18n.locale);

export default i18n;
