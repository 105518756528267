import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<About>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.about.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('page.about.meta-description').toString(),
        },
      ],
    };
  },
})
class About extends Vue {
  protected jumpToForm() {
    window.open('#contact', '_self');
  }
}

export default About;
