import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { EntityDetailView } from '@/utils/views';
import { CategoryModel, ProductModel } from '@/models';

@Component<Category>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.title : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Category extends EntityDetailView {
  @Getter
  protected categoryBySlug!: (slug: string) => CategoryModel;

  @Getter
  protected productsByCategory!: (id: number) => ProductModel[];

  @Action
  protected getCategories!: () => void;

  protected async mounted() {
    this.getCategories();
  }

  protected get entity() {
    const { categorySlug } = this.$route.params;
    return this.categoryBySlug(categorySlug);
  }

  protected get products(): ProductModel[] {
    return this.entity ? this.productsByCategory(this.entity.id) : [];
  }
}

export default Category;
