import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { MetaInfo } from 'vue-meta';

import { UseCaseModel, RobotModel } from '@/models';

import shuffle from 'lodash/shuffle';
import i18n from '@/plugins/i18n';

const OBJ_SPEED = 'speed';
const OBJ_PAYLOAD = 'payload';
const OBJ_WEIGHT = 'weight';
const OBJ_BATTERY = 'battery';

export interface IExploreUseCase {
  usecase: UseCaseModel;
  robots: RobotModel[][];
  topLists: string[][];
}

@Component<Explore>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.explore.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('page.explore.meta-description').toString(),
        },
      ],
    };
  },
})
class Explore extends Vue {
  @Getter
  protected useCases!: UseCaseModel[];

  @Getter
  protected robotsByUseCase!: (id: number) => RobotModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get explores(): IExploreUseCase[] {
    const objectives = [OBJ_BATTERY, OBJ_PAYLOAD, OBJ_SPEED, OBJ_WEIGHT];
    const maxComparisons = 8;
    const e: IExploreUseCase[] = [];
    this.useCases.forEach(u => {
      const robots = shuffle(this.robotsByUseCase(u.id)).slice(0, 3 * 2);
      if (robots.length % 2 !== 0 && robots.length > 0) {
        robots.pop();
      }
      const comparisons = [];
      for (let i = 0; i + 1 < robots.length && i <= maxComparisons; i += 2) {
        comparisons.push([robots[i], robots[i + 1]]);
      }
      const topLists: string[][] = [];
      objectives.forEach(o => {
        topLists.push([o, this.localizedObjective(o)]);
      });
      e.push({ usecase: u, robots: comparisons, topLists });
    });
    return e;
  }

  protected localizedObjective(obj: string): string {
    switch (obj) {
      case OBJ_BATTERY:
        return i18n.t('view.top-list.objective.battery').toString();
      case OBJ_PAYLOAD:
        return i18n.t('view.top-list.objective.payload').toString();
      case OBJ_SPEED:
        return i18n.t('view.top-list.objective.speed').toString();
      case OBJ_WEIGHT:
        return i18n.t('view.top-list.objective.weight').toString();
    }
    return i18n.t('view.top-list.objective.unknown').toString();
  }
}

export default Explore;
