import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { RobotModel, ManufacturerModel, UseCaseModel } from '@/models';
import i18n, { i18nRoute } from '@/plugins/i18n';

import map from 'lodash/map';
import shuffle from 'lodash/shuffle';
import filter from 'lodash/filter';

@Component<H2HComparison>({
  metaInfo(): MetaInfo {
    if (this.robot1 && this.manufacturer1 && this.robot2 && this.manufacturer2) {
      const title = i18n
        .t('page.robot-comparison.title', {
          robot1: this.robot1.name,
          robot2: this.robot2.name,
        })
        .toString();

      const description = i18n
        .t('page.robot-comparison.description', {
          robot1: this.robot1.name,
          manufacturer1: this.manufacturer1.name,
          robot2: this.robot2.name,
          manufacturer2: this.manufacturer2.name,
        })
        .toString();

      return {
        title,
        meta: [
          {
            name: 'description',
            content: description,
          },
        ],
      };
    }

    return {};
  },
})
class H2HComparison extends Vue {
  @Getter
  protected isBooting!: boolean;

  @Getter
  protected robotBySlug!: (slug: string) => RobotModel;

  @Getter
  protected robotsByKind!: (kind: string) => RobotModel[];

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Getter
  protected useCaseByID!: (id: number) => UseCaseModel[];

  @Getter
  protected useCaseBySlug!: (slug: string) => UseCaseModel;

  @Getter
  protected robotsByUseCase!: (id: number) => RobotModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  @Watch('isBooting')
  @Watch('$route', { immediate: true })
  protected onRouteChanged() {
    if (!this.isBooting && !(this.robot1 && this.robot2)) {
      this.$router.push(i18nRoute({ name: 'home' }));
    }
  }

  protected get useCases1() {
    if (this.robot1) {
      const useCases = map(this.robot1.useCases, id => this.useCaseByID(id));
      return filter(useCases, c => !!c);
    }
    return [];
  }

  protected get useCases2() {
    if (this.robot2) {
      const useCases = map(this.robot2.useCases, id => this.useCaseByID(id));
      return filter(useCases, c => !!c);
    }
    return [];
  }

  protected get robot1(): RobotModel | undefined {
    const { robotSlug1 } = this.$route.params;
    return this.robotBySlug(robotSlug1);
  }

  protected get robot2(): RobotModel | undefined {
    const { robotSlug2 } = this.$route.params;
    return this.robotBySlug(robotSlug2);
  }

  protected get manufacturer1(): ManufacturerModel | undefined {
    if (this.robot1 && this.robot1.manufacturer) {
      return this.manufacturerByID(this.robot1.manufacturer);
    }
    return undefined;
  }

  protected get manufacturer2(): ManufacturerModel | undefined {
    if (this.robot2 && this.robot2.manufacturer) {
      return this.manufacturerByID(this.robot2.manufacturer);
    }
    return undefined;
  }

  protected useCaseRobots(): RobotModel[] {
    if (this.robot1) {
      return this.robotsByUseCase(this.robot1.useCases[0]);
    }
    return [];
  }

  protected get similarComparisons() {
    const maxComparisons = 20;
    if (this.robot1 === undefined) {
      return [];
    }
    const comparisons = [];
    const robots = shuffle(this.useCaseRobots());
    for (let i = 0; i + 1 < robots.length && i <= maxComparisons; i += 2) {
      comparisons.push([robots[i], robots[i + 1]]);
    }
    return comparisons;
  }

  protected get speedComparison() {
    let first = '';
    let second = '';
    let firstVal = 0.0;
    let secondVal = 0.0;
    const options: string[] = ['page.robot-comparison.speedCompare1', 'page.robot-comparison.speedCompare2'];
    if (this.robot1 && this.robot2) {
      if (this.robot1.avgSpeed > this.robot2.avgSpeed) {
        first = this.robot1.name;
        second = this.robot2.name;
        firstVal = this.robot1.avgSpeed;
        secondVal = this.robot2.avgSpeed;
      }
      if (this.robot1.avgSpeed < this.robot2.avgSpeed) {
        first = this.robot2.name;
        second = this.robot1.name;
        firstVal = this.robot2.avgSpeed;
        secondVal = this.robot1.avgSpeed;
      } else {
        const optionsEqual: string[] = ['page.robot-comparison.speedEqual1', 'page.robot-comparison.speedEqual2'];
        return i18n.t(optionsEqual[this.getRandomInt(optionsEqual.length)]).toString();
      }
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          first,
          second,
          firstVal,
          secondVal,
        })
        .toString();
    }
    return i18n.t('page.robot-comparison.speedNo').toString();
  }

  protected get weightComparison() {
    let first = '';
    let second = '';
    let firstVal = 0.0;
    let secondVal = 0.0;
    const options: string[] = ['page.robot-comparison.weightCompare1', 'page.robot-comparison.weightCompare2'];
    if (this.robot1 && this.robot2) {
      if (this.robot1.weight === 0.0 || this.robot2.weight === 0.0) {
        return i18n.t('page.robot-comparison.weightNo').toString();
      }
      if (this.robot1.weight > this.robot2.weight) {
        first = this.robot1.name;
        second = this.robot2.name;
        firstVal = this.robot1.weight;
        secondVal = this.robot2.weight;
      } else if (this.robot1.weight < this.robot2.weight) {
        first = this.robot2.name;
        second = this.robot1.name;
        firstVal = this.robot2.weight;
        secondVal = this.robot1.weight;
      } else {
        const optionsEqual: string[] = ['page.robot-comparison.weightEqual1', 'page.robot-comparison.weightEqual2'];
        return i18n.t(optionsEqual[this.getRandomInt(optionsEqual.length)]).toString();
      }
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          first,
          second,
          firstVal,
          secondVal,
        })
        .toString();
    }
    return i18n.t('page.robot-comparison.weightNo').toString();
  }

  protected get sizeComparison() {
    let first = '';
    let second = '';
    let firstHeight = 0.0;
    let firstWidth = 0.0;
    let firstLength = 0.0;
    let secondHeight = 0.0;
    let secondWidth = 0.0;
    let secondLength = 0.0;
    const options: string[] = ['page.robot-comparison.sizeCompare1', 'page.robot-comparison.sizeCompare2'];
    if (this.robot1 && this.robot2) {
      if (
        this.robot1.height !== 0.0 &&
        this.robot1.width !== 0.0 &&
        this.robot1.length !== 0.0 &&
        this.robot1.height !== 0.0 &&
        this.robot1.width !== 0.0 &&
        this.robot1.length !== 0.0
      ) {
        const r1 = this.robot1.height * this.robot1.width * this.robot1.length;
        const r2 = this.robot2.height * this.robot2.width * this.robot2.length;
        if (r1 > r2) {
          first = this.robot1.name;
          second = this.robot2.name;
          firstHeight = this.robot1.height;
          firstWidth = this.robot1.width;
          firstLength = this.robot1.length;
          secondHeight = this.robot2.height;
          secondWidth = this.robot2.width;
          secondLength = this.robot2.length;
        } else if (r1 < r2) {
          first = this.robot2.name;
          second = this.robot1.name;
          firstHeight = this.robot2.height;
          firstWidth = this.robot2.width;
          firstLength = this.robot2.length;
          secondHeight = this.robot1.height;
          secondWidth = this.robot1.width;
          secondLength = this.robot1.length;
        } else {
          const optionsEqual: string[] = ['page.robot-comparison.sizeEqual1', 'page.robot-comparison.sizeEqual2'];
          return i18n.t(optionsEqual[this.getRandomInt(optionsEqual.length)]).toString();
        }
        return i18n
          .t(options[this.getRandomInt(options.length)], {
            first,
            second,
            firstHeight,
            firstWidth,
            firstLength,
            secondHeight,
            secondWidth,
            secondLength,
          })
          .toString();
      }
    }
    return i18n.t('page.robot-comparison.sizeNo').toString();
  }

  protected get batteryComparison() {
    let first = '';
    let second = '';
    let firstVal = 0.0;
    let secondVal = 0.0;
    const options: string[] = ['page.robot-comparison.batteryCompare1', 'page.robot-comparison.batteryCompare2'];
    if (this.robot1 && this.robot2) {
      if (this.robot1.batteryLifetime > this.robot2.batteryLifetime) {
        first = this.robot1.name;
        second = this.robot2.name;
        firstVal = this.robot1.batteryLifetime;
        secondVal = this.robot2.batteryLifetime;
      } else if (this.robot1.batteryLifetime < this.robot2.batteryLifetime) {
        first = this.robot2.name;
        second = this.robot1.name;
        firstVal = this.robot2.batteryLifetime;
        secondVal = this.robot1.batteryLifetime;
      } else {
        const optionsEqual: string[] = ['page.robot-comparison.batteryEqual1', 'page.robot-comparison.batteryEqual2'];
        return i18n.t(optionsEqual[this.getRandomInt(optionsEqual.length)]).toString();
      }
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          first,
          second,
          firstVal,
          secondVal,
        })
        .toString();
    }
    return i18n.t('page.robot-comparison.batteryNo').toString();
  }

  protected get payloadComparison() {
    let first = '';
    let second = '';
    let firstVal = 0.0;
    let secondVal = 0.0;
    const options: string[] = ['page.robot-comparison.payloadCompare1', 'page.robot-comparison.payloadCompare2'];
    if (this.robot1 && this.robot2) {
      if (this.robot1.maxPayload > this.robot2.maxPayload) {
        first = this.robot1.name;
        second = this.robot2.name;
        firstVal = this.robot1.maxPayload;
        secondVal = this.robot2.maxPayload;
      } else if (this.robot1.maxPayload < this.robot2.maxPayload) {
        first = this.robot2.name;
        second = this.robot1.name;
        firstVal = this.robot2.maxPayload;
        secondVal = this.robot1.maxPayload;
      } else {
        const optionsEqual: string[] = ['page.robot-comparison.payloadEqual1', 'page.robot-comparison.payloadEqual2'];
        return i18n.t(optionsEqual[this.getRandomInt(optionsEqual.length)]).toString();
      }
      return i18n
        .t(options[this.getRandomInt(options.length)], {
          first,
          second,
          firstVal,
          secondVal,
        })
        .toString();
    }
    return i18n.t('page.robot-comparison.payloadNo').toString();
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default H2HComparison;
