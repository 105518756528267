import map from 'lodash/map';

import axios from '@/axios';
import router from '@/router';
import { i18nRoute } from '@/plugins/i18n';
import UserModel from './user';

const API_ENDPOINT = '/api/v1/notifications/';

export interface INotification {
  id: number;
  createdAt: string;
  kind: string;
  recipient: number | null;
  robot: number | null;
  company: number | null;
  product: number | null;
  context: object;
  isRead: boolean;
}

const KIND_REVIEW_REJECTED = 'reviews.rejected';

class NotificationModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new NotificationModel(data));
  }

  constructor(protected data: INotification) {}

  public async markRead() {
    const resp = await axios.post(API_ENDPOINT + this.id + '/mark_read/');
    this.data = resp.data;
    return this;
  }

  public get id() {
    return this.data.id;
  }

  public get createdAt() {
    return this.data.createdAt;
  }

  public get kind() {
    return this.data.kind;
  }

  public get recipient() {
    return this.data.recipient;
  }

  public get robot() {
    return this.data.robot;
  }

  public get company() {
    return this.data.company;
  }

  public get product() {
    return this.data.product;
  }

  public get context() {
    return this.data.context;
  }

  public get isRead() {
    return this.data.isRead;
  }

  public get route() {
    return i18nRoute({ name: 'home' });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }
}

export default NotificationModel;
