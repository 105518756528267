import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ManufacturerModel } from '@/models';

import take from 'lodash/take';
import filter from 'lodash/filter';
import shuffle from 'lodash/shuffle';

@Component
class ListManufacturersSimilar extends Vue {
  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Getter('manufacturers')
  protected allManufacturers!: ManufacturerModel[];

  protected get similarManufacturers() {
    return take(shuffle(filter(this.allManufacturers, m => m.id !== this.manufacturer.id && !!m.logo)), 6);
  }
}

export default ListManufacturersSimilar;
