import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';

@Component
class ImageUpload extends Vue {
  @Ref() protected readonly input!: HTMLInputElement;

  @Prop({ default: null })
  protected value!: null | string | File;

  @Prop({ default: 'image/png, image/jpeg, image/gif' })
  protected accept!: string;

  protected previewImage: string | ArrayBuffer | null = null;

  @Watch('value', { immediate: true })
  protected onValueChanges() {
    if (typeof this.value === 'string') {
      this.previewImage = this.value;
    }
  }

  protected openFileDialog() {
    this.input.click();
  }

  protected onFileUpload(e: Event) {
    const files = [...((e.target as HTMLInputElement).files || [])];
    const file = files[0];

    // assert a valid image
    this.previewImage = null;
    if (this.accept.split(', ').includes(file.type)) {
      this.$emit('input', file);

      // generate preview image
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.previewImage = reader.result;
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  }
}

export default ImageUpload;
