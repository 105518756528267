import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { EntityDetailView } from '@/utils/views';
import { UseCaseModel, RobotModel } from '@/models';

@Component<UseCase>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.title : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class UseCase extends EntityDetailView {
  @Getter
  protected useCaseBySlug!: (slug: string) => UseCaseModel;

  @Getter
  protected robotsByUseCase!: (id: number) => RobotModel[];

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  protected async mounted() {
    this.getUseCases();
    this.getRobots();
    this.getManufacturers();
  }

  protected get entity() {
    const { useCaseSlug } = this.$route.params;
    return this.useCaseBySlug(useCaseSlug);
  }

  protected get robots(): RobotModel[] {
    return this.entity ? this.robotsByUseCase(this.entity.id) : [];
  }
}

export default UseCase;
