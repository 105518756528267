import '@babel/polyfill';
import '@/sentry';
import Vue from 'vue';

import '@/components';
import store, { ACTION_BOOT } from '@/store';
import App from './App.vue';
import router from '@/router';
import { i18n, vuetify } from '@/plugins';

Vue.config.productionTip = false;

store.dispatch(ACTION_BOOT);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
