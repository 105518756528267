import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';
import { IVersionized, VersionizedModel } from './types';

const API_ENDPOINT = '/api/v1/products/';

export const ASSET_KIND_IMAGE = 'image';
export const ASSET_KIND_DOCUMENT = 'document';
export const ASSET_KIND_YOUTUBE_VIDEO = 'youtube';

export const KIND_PRODUCT_INFRASTRUCTURE = 'infrastructure';
export const KIND_PRODUCT_ROBOT = 'robot';

export interface IProductAsset {
  id?: number;
  isPublished?: boolean;
  kind?: string;
  title?: string;
  file?: string | File | null;
  youtubeVideoId?: string | null;
  order: number | null;
}

export interface ITranslations {
  [key: string]: {
    description: string;
    usp1: string;
    usp2: string;
    usp3: string;
  };
}

export interface IProduct extends IVersionized {
  id: number;
  slug: string;
  name: string;
  kind: string;
  image: string | File;
  company: number;
  isPremium: boolean;
  isSpotlight: boolean;
  category: number[];
  robots: number[];
  translations: ITranslations;
}

class ProductModel extends VersionizedModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new ProductModel(data));
  }

  public static async create(data: IProduct) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new ProductModel(resp.data);
  }

  protected assets!: IProductAsset[];

  constructor(protected data: IProduct) {
    super();
  }

  public async update(data: IProduct) {
    const resp = await axios.patch(API_ENDPOINT + this.id + '/', data);
    this.data = resp.data;
    return this;
  }

  public async listAssets() {
    const resp = await axios.get(API_ENDPOINT + this.id + '/assets/');
    this.assets = resp.data.results;
    return this.assets;
  }

  public async reorderAssets(order: number[]) {
    const resp = await axios.post(API_ENDPOINT + this.id + '/assets/reorder/', order);
    this.assets = resp.data;
    return this.assets;
  }

  public async createAsset(asset: IProductAsset | FormData) {
    const resp = await axios.post(API_ENDPOINT + this.id + '/assets/', asset);
    this.assets.push(resp.data);
    return this.assets;
  }

  public async updateAsset(asset: IProductAsset) {
    const { file, ...data } = asset;
    const resp = await axios.patch(`${API_ENDPOINT}${this.id}/assets/${asset.id}/`, data);
    this.assets = map(this.assets, a => (a.id === asset.id ? asset : a));
    return this.assets;
  }

  public async deleteAsset(asset: IProductAsset) {
    const resp = await axios.delete(`${API_ENDPOINT}${this.id}/assets/${asset.id}/`);
    this.assets = filter(this.assets, a => a.id !== asset.id);
    return this.assets;
  }

  public get rawData() {
    return this.data;
  }

  protected get translations() {
    return this.data.translations[i18n.locale] || {};
  }

  public get id() {
    return this.data.id;
  }

  public get slug() {
    return this.data.slug;
  }

  public get name() {
    return this.data.name;
  }

  public get kind() {
    return this.data.kind;
  }

  public get image() {
    return this.data.image;
  }

  public get imagesAndVideos() {
    return filter(this.assets, a => a.kind !== ASSET_KIND_DOCUMENT);
  }

  public get documents() {
    return filter(this.assets, { kind: ASSET_KIND_DOCUMENT });
  }

  public get company() {
    return this.data.company;
  }

  public get isPremium() {
    return this.data.isPremium;
  }

  public get category() {
    return this.data.category;
  }

  public get robots() {
    return this.data.robots;
  }

  public get description() {
    return marked(this.translations.description || '');
  }

  public get usp1() {
    return this.translations.usp1;
  }

  public get usp2() {
    return this.translations.usp2;
  }

  public get usp3() {
    return this.translations.usp3;
  }

  public get isSpotlight() {
    return this.data.isSpotlight;
  }

  public matchesQuery(query: string) {
    return this.name && this.name.toLowerCase().includes(query);
  }

  public get route() {
    const company = find(store.getters.companies, m => m.id === this.company);
    return i18nRoute({ name: 'product', params: { companySlug: company.slug, productSlug: this.slug } });
  }

  public get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }

  public toString() {
    return this.name;
  }
}

export default ProductModel;
