import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';
import { debounce } from 'typescript-debounce-decorator';

import map from 'lodash/map';
import filter from 'lodash/filter';
import shuffle from 'lodash/shuffle';
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';

import { RobotModel, UseCaseModel, ManufacturerModel, SearchModel, EventModel } from '@/models';
import { IEvent, TYPE_SEARCH, SOURCE_ROBOT_FINDER, EVENT_EXPAND_SEARCH } from '@/models/event';
import { ISearch, SOURCE_ROBOTFINDER } from '@/models/search';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

interface IFilters {
  manufacturers: number[];
  useCases: number[];
  ceCertificate: boolean;
  tuvCertificate: boolean;
  payload: boolean;
  payloadRange: number[];
  width: boolean;
  widthRange: number[];
  height: boolean;
  heightRange: number[];
  length: boolean;
  lengthRange: number[];
  weight: boolean;
  weightRange: number[];
  cleanRoom: boolean;
  frostEnvironment: boolean;
  outdoor: boolean;
  followMe: boolean;
  hasSense: boolean;
  maxSpeed: boolean;
  maxSpeedRange: number[];
  batteryLifetime: boolean;
  batteryLifetimeRange: number[];
}

const FILTER_DEFAULTS: IFilters = {
  manufacturers: [],
  useCases: [],
  ceCertificate: false,
  tuvCertificate: false,
  payload: false,
  payloadRange: [0, 0],
  width: false,
  widthRange: [0, 0],
  height: false,
  heightRange: [0, 0],
  length: false,
  lengthRange: [0, 0],
  weight: false,
  weightRange: [0, 0],
  cleanRoom: false,
  frostEnvironment: false,
  outdoor: false,
  followMe: false,
  hasSense: false,
  maxSpeed: false,
  maxSpeedRange: [0, 0],
  batteryLifetime: false,
  batteryLifetimeRange: [0, 0],
};

@Component<RobotFinder>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.robot-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.robot-finder.meta-description').toString() }],
    };
  },
})
class RobotFinder extends Vue {
  // Search Results
  protected results: RobotModel[] = [];
  protected premium: RobotModel[] = [];

  // Sections
  protected showFilterUseCase = true;
  protected showFilterManufacturers = false;
  protected showFilterSecurity = false;
  protected showFilterMaxPayload = false;
  protected showFilterDimensions = false;
  protected showFilterEnvironment = false;
  protected showFilterOthers = false;

  // Search Filters
  protected filters: IFilters = clone(FILTER_DEFAULTS);

  @Getter
  protected ut!: string;

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected useCases!: UseCaseModel[];

  @Getter
  protected totalMaxPayload!: number;

  @Getter
  protected totalMaxLength!: number;

  @Getter
  protected totalMaxHeight!: number;

  @Getter
  protected totalMaxWidth!: number;

  @Getter
  protected totalMaxWeight!: number;

  @Getter
  protected totalMaxMaxSpeed!: number;

  @Getter
  protected totalMaxBatteryLifetime!: number;

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getRobots();
    this.getManufacturers();
    this.getUseCases();
    this.getAds();
  }

  @Watch('robots', { immediate: true })
  @Watch('filters', { deep: true })
  protected search() {
    let results = this.robots;
    const premium = shuffle(filter(results, r => r.isPremium));

    // use-cases
    if (this.filters.useCases.length) {
      results = filter(results, r => containsFully(r.useCases, this.filters.useCases));
    }

    // manufacturers
    if (this.filters.manufacturers.length) {
      results = filter(results, r => this.filters.manufacturers.includes(r.manufacturer));
    }

    // security
    if (this.filters.ceCertificate) {
      results = filter(results, r => !!r.ceCertificate);
    }
    if (this.filters.tuvCertificate) {
      results = filter(results, r => !!r.tuvCertificate);
    }

    // max. payload
    if (this.filters.payload) {
      const [minPayload, maxPayload] = this.filters.payloadRange;
      results = filter(results, r => minPayload <= r.maxPayload && r.maxPayload <= maxPayload);
    }

    // dimensions
    if (this.filters.width) {
      const [minWidth, maxWidth] = this.filters.widthRange;
      results = filter(results, r => minWidth <= r.width && r.width <= maxWidth);
    }
    if (this.filters.height) {
      const [minHeight, maxHeight] = this.filters.heightRange;
      results = filter(results, r => minHeight <= r.height && r.height <= maxHeight);
    }
    if (this.filters.length) {
      const [minLength, maxLength] = this.filters.lengthRange;
      results = filter(results, r => minLength <= r.length && r.length <= maxLength);
    }
    if (this.filters.weight) {
      const [minWeight, maxWeight] = this.filters.weightRange;
      results = filter(results, r => minWeight <= r.weight && r.weight <= maxWeight);
    }

    // environment
    if (this.filters.cleanRoom) {
      results = filter(results, r => !!r.cleanRoom);
    }
    if (this.filters.frostEnvironment) {
      results = filter(results, r => !!r.frostEnvironment);
    }
    if (this.filters.outdoor) {
      results = filter(results, r => !!r.outdoor);
    }

    // others
    if (this.filters.batteryLifetime) {
      const [minBatteryLifetime, maxBatteryLifetime] = this.filters.batteryLifetimeRange;
      results = filter(
        results,
        r => minBatteryLifetime <= r.batteryLifetime && r.batteryLifetime <= maxBatteryLifetime,
      );
    }
    if (this.filters.maxSpeed) {
      const [minMaxSpeed, maxMaxSpeed] = this.filters.maxSpeedRange;
      results = filter(results, r => minMaxSpeed <= r.maxSpeed && r.maxSpeed <= maxMaxSpeed);
    }
    if (this.filters.followMe) {
      results = filter(results, r => !!r.followMe);
    }
    if (this.filters.hasSense) {
      results = filter(results, r => !!r.hasSense);
    }

    this.premium = shuffle(filter(results, r => r.isPremium));
    this.results = shuffle(results);

    this.trackSearch();
  }

  @debounce(5000, { leading: false })
  protected trackSearch() {
    const query: ISearch = {
      // source of search
      source: SOURCE_ROBOTFINDER.toString(),
      ut: this.ut,
      // robot
      robots: map(this.results, r => r.id),
      manufacturers: map(this.results, r => r.manufacturer),
      useCases: this.filters.useCases,
      ceCertificate: this.filters.ceCertificate,
      tuvCertificate: this.filters.tuvCertificate,
      minPayload: this.filters.payloadRange[0],
      maxPayload: this.filters.payloadRange[1],
      minWidth: this.filters.widthRange[0],
      maxWidth: this.filters.widthRange[1],
      minHeight: this.filters.heightRange[0],
      maxHeight: this.filters.heightRange[1],
      minLength: this.filters.lengthRange[0],
      maxLength: this.filters.lengthRange[1],
      minWeight: this.filters.weightRange[0],
      maxWeight: this.filters.weightRange[1],
      cleanRoom: this.filters.cleanRoom,
      frostEnvironment: this.filters.frostEnvironment,
      outdoor: this.filters.outdoor,
      followMe: this.filters.followMe,
      hasSense: this.filters.hasSense,
      minSpeed: this.filters.maxSpeedRange[0],
      maxSpeed: this.filters.maxSpeedRange[1],
      minBatteryLifetime: this.filters.batteryLifetimeRange[0],
      maxBatteryLifetime: this.filters.batteryLifetimeRange[1],
      // product
      productIsRobot: false,
      productIsInfrastructure: false,
      productCategories: [],
      productSelectedRobots: [],
      // service
      services: [],
      serviceCompanies: [],
      serviceCountries: [],
      products: [],
      // results
      normalResults: this.results.length,
      premiumResults: this.premium.length,
      goldResults: this.premium.length,
    };
    SearchModel.create(query);
  }

  protected get robotSpotlight() {
    return filter(this.robots, m => m.isSpotlight);
  }

  protected clearFilters() {
    this.filters = clone(FILTER_DEFAULTS);
  }

  protected executeExpandSearch() {
    const query: IEvent = {
      type: TYPE_SEARCH.toString(),
      source: SOURCE_ROBOT_FINDER.toString(),
      event: EVENT_EXPAND_SEARCH.toString(),
      id: 0,
    };
    EventModel.create(query);
  }
}

export default RobotFinder;
