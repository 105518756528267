import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ManufacturerModel, BlogPostModel } from '@/models';

import take from 'lodash/take';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

@Component
class ListManufacturerNews extends Vue {
  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Getter
  protected manufacturerNews!: BlogPostModel[];

  protected get manufacturerPosts() {
    return take(
      orderBy(
        filter(this.manufacturerNews, m => m.manufacturers.includes(this.manufacturer.id)),
        '-date_published',
      ),
      5,
    );
  }
}

export default ListManufacturerNews;
