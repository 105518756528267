import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
class WButtonWithArrow extends Vue {
  @Prop({ type: Boolean, required: false })
  protected xLarge?: boolean;

  @Prop([String, Object]) protected href?: string | object;
  @Prop([String, Object]) protected to?: string | object;

  protected onClick(value: any) {
    this.$emit('click', value);
  }
}

export default WButtonWithArrow;
