import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Mutation, Action } from 'vuex-class';

import { SRC_PROFILE } from '@/models/lead';
import { ILead } from '@/models/lead';
import { RobotModel, ManufacturerModel, ProductModel, CompanyModel, LeadModel } from '@/models';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';
import { LOCALE_EN, LOCALE_DE } from '@/constants/i18n';

// TODO (abresk) change here
const MSG_INQUIRY_SUCCESS = 'component.dialog.robot.inquiry.notification.success';

@Component
class DialogLead extends Vue {
  protected rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  @Action
  protected createLead!: (data: ILead) => LeadModel;

  @Prop({ type: Boolean, default: false })
  protected rent!: boolean;

  @Prop()
  protected robot!: RobotModel;

  @Prop()
  protected manufacturer!: ManufacturerModel;

  @Prop()
  protected company!: CompanyModel;

  @Prop()
  protected product!: ProductModel;

  @Prop()
  protected kind!: string;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected isOpen = false;
  protected isValid = false;
  protected isLoading = false;

  protected leadFirstName = '';
  protected leadLastName = '';
  protected leadCompany = '';
  protected leadTelephone = '';
  protected leadEmail = '';
  protected message = '';

  // TODO (abresk) do we need this
  protected acceptPolicy = false;

  protected async submit() {
    let dialogManufacturer: number[] = [];
    let dialogRobot: number[] = [];
    let dialogCompany: number[] = [];
    let dialogProduct: number[] = [];

    if (this.form.validate()) {
      this.isLoading = true;

      if (this.kind === 'manufacturer') {
        dialogManufacturer = [this.manufacturer.id];
      }
      if (this.kind === 'robot') {
        dialogManufacturer = [this.manufacturer.id];
        dialogRobot = [this.robot.id];
      }
      if (this.kind === 'company') {
        dialogCompany = [this.company.id];
      }
      if (this.kind === 'product') {
        dialogProduct = [this.product.id];
        dialogCompany = [this.company.id];
      }

      const data: ILead = {
        id: 0,
        kind: this.kind,
        source: SRC_PROFILE,
        robots: dialogRobot,
        manufacturers: dialogManufacturer,
        useCases: [],
        leadCompany: this.leadCompany,
        leadName: this.leadFirstName + ' ' + this.leadLastName,
        leadTelephone: this.leadTelephone,
        leadEmail: this.leadEmail,
        leadCountry: '',
        leadData: '',
        services: [],
        companies: dialogCompany,
        products: dialogProduct,
        translations: {
          [LOCALE_EN]: {
            description: this.message,
          },
          [LOCALE_DE]: {
            description: this.message,
          },
        },
      };
      try {
        const response = await this.createLead(data);
        this.setSnackbarNotification(this.$t(MSG_INQUIRY_SUCCESS).toString());
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
      this.isOpen = false;
    }
  }
}

export default DialogLead;
