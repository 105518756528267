import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CompanyModel } from '@/models';

import take from 'lodash/take';
import filter from 'lodash/filter';
import shuffle from 'lodash/shuffle';

@Component
class ListCompaniesOther extends Vue {
  @Prop({ required: true })
  protected company!: CompanyModel;

  @Getter('companies')
  protected allCompanies!: CompanyModel[];

  protected get otherCompanies() {
    return take(
      shuffle(filter(this.allCompanies, m => m.id !== this.company.id && m.kind === this.company.kind && !!m.logo)),
      6,
    );
  }
}

export default ListCompaniesOther;
