import { Vue, Component, Prop } from 'vue-property-decorator';

import { ServiceModel } from '@/models';

@Component
class TileService extends Vue {
  @Prop()
  protected service!: ServiceModel;
}

export default TileService;
