import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT_USER = '/api/v1/users/me/';
const API_ENDPOINT_SIGNUP = '/api/v1/auth/signup/';
const API_ENDPOINT_LOGIN = '/api/v1/auth/login/';
const LOGOUT_URL = '/logout/';

export const USER_SERVICE = 'service';
export const USER_MANUFACTURER = 'manufacturer';
export const USER_PRODUCT = 'product';
export const USER_ENDUSER = 'enduser';

export interface IUser {
  email: string;
  manufacturers: number[];
  companies: number[];
}

export interface ILoginPayload {
  email: string;
  password: string;
}

export interface ISignupPayload extends ILoginPayload {
  firstName: string;
  lastName: string;
  company: string;
  position: string;
  phone: string;
  kind: string;
}

class UserModel {
  public static async get() {
    try {
      const resp = await axios.get(API_ENDPOINT_USER);
      return new UserModel(resp.data);
    } catch (err) {
      return null;
    }
  }

  public static async login(data: ILoginPayload) {
    try {
      const resp = await axios.post(API_ENDPOINT_LOGIN, data);
      return new UserModel(resp.data);
    } catch (err) {
      return null;
    }
  }

  public static async signup(data: ISignupPayload) {
    // intentionally not catching errors here in order
    // to be able to handle them on the view side
    const resp = await axios.post(API_ENDPOINT_SIGNUP, data);
    return new UserModel(resp.data);
  }

  public static logout() {
    window.location.pathname = LOGOUT_URL;
  }

  constructor(protected data: IUser) {}

  public get email() {
    return this.data.email;
  }

  public get manufacturers() {
    return this.data.manufacturers;
  }

  public get companies() {
    return this.data.companies;
  }

  protected get route() {
    const manufacturers = store.getters.manufacturers();

    for (const id of this.manufacturers) {
      for (const manufacturer of manufacturers) {
        if (manufacturer.id === id) {
          return manufacturer.route;
        }
      }
    }

    return i18nRoute({ name: 'home' });
  }

  protected get goto() {
    return () =>
      router.push(this.route).catch(err => {
        /* do nothing */
      });
  }
}

export default UserModel;
