import { Vue, Component, Prop } from 'vue-property-decorator';
import countries from 'i18n-iso-countries';

import map from 'lodash/map';

import { getCountryName } from '@/plugins/i18n';

interface ICountryItem {
  code: string;
  label: string;
}

@Component
class CountryField extends Vue {
  @Prop({ type: String })
  protected value!: string;

  @Prop({ type: String, required: false, default: undefined })
  protected label?: string;

  @Prop({ required: false, default: undefined })
  protected rules?: any[];

  @Prop({ type: Boolean, required: false, default: false })
  protected required!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  protected rounded!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  protected outlined!: boolean;

  protected get countries(): ICountryItem[] {
    const codes = countries.getAlpha3Codes();
    return map(codes, code => ({ code, label: getCountryName(code) }));
  }

  protected onCountryChanged(value: string) {
    this.$emit('input', value);
  }
}

export default CountryField;
