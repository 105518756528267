import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<MagazineContact>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.magazine-contact.title').toString(),
    };
  },
})
class MagazineContact extends Vue {}

export default MagazineContact;
