import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import { Getter } from 'vuex-class';

import {
  ManufacturerModel,
  RobotModel,
  UseCaseModel,
  BlogPostModel,
  CompanyModel,
  ServiceModel,
  ProductModel,
} from '@/models';

@Component
class LayoutHeaderSearch extends Vue {
  protected readonly MAX_RESULTS = 3;

  protected query = '';
  protected isOpen = false;
  protected isLoading = false;

  @Prop({ type: Boolean, default: false })
  protected dense!: boolean;

  @Getter
  protected robotsByQuery!: (q: string) => RobotModel[];

  @Getter
  protected useCasesByQuery!: (q: string) => UseCaseModel[];

  @Getter
  protected manufacturersByQuery!: (q: string) => ManufacturerModel[];

  @Getter
  protected glossaryEntriesByQuery!: (q: string) => BlogPostModel[];

  @Getter
  protected faqEntriesByQuery!: (q: string) => BlogPostModel[];

  @Getter
  protected servicesByQuery!: (q: string) => ServiceModel[];

  @Getter
  protected companiesByQuery!: (q: string) => CompanyModel[];

  @Getter
  protected productsByQuery!: (q: string) => ProductModel[];

  protected robots: RobotModel[] = [];
  protected useCases: UseCaseModel[] = [];
  protected manufacturers: ManufacturerModel[] = [];
  protected glossary: BlogPostModel[] = [];
  protected faq: BlogPostModel[] = [];
  protected services: ServiceModel[] = [];
  protected companies: CompanyModel[] = [];
  protected products: ProductModel[] = [];

  @Watch('query')
  @debounce(200, { leading: false })
  protected onQueryChanged() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 2;
    if (this.isOpen) {
      this.isLoading = true;
      this.robots = this.robotsByQuery(q);
      this.useCases = this.useCasesByQuery(q);
      this.manufacturers = this.manufacturersByQuery(q);
      this.glossary = this.glossaryEntriesByQuery(q);
      this.services = this.servicesByQuery(q);
      this.companies = this.companiesByQuery(q);
      this.faq = this.faqEntriesByQuery(q);
      this.products = this.productsByQuery(q);
      this.isLoading = false;
    }
  }
  protected countResults(results: []) {
    const count = results && results.length > this.MAX_RESULTS ? `${this.MAX_RESULTS}+` : results.length;
    return { count };
  }

  @debounce(300, { leading: false })
  protected onFocus() {
    const q = this.query.trim().toLowerCase();
    this.isOpen = q.length > 4;
  }

  @debounce(300, { leading: false })
  protected onBlur() {
    this.isOpen = false;
  }
}

export default LayoutHeaderSearch;
