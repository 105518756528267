import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import filter from 'lodash/filter';

import { RobotModel, UseCaseModel, ManufacturerModel } from '@/models';

@Component<InterOp>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.interop.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.interop.meta-description').toString() }],
    };
  },
})
class InterOp extends Vue {
  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected useCases!: UseCaseModel[];

  @Action
  protected getRobots!: () => void;

  @Action
  protected getManufacturers!: () => void;

  @Action
  protected getUseCases!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getRobots();
    this.getManufacturers();
    this.getUseCases();
    this.getAds();
  }

  protected get results() {
    const results = this.robots;
    return filter(results, r => !!r.hasSense);
  }
}

export default InterOp;
