import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import filter from 'lodash/filter';

import { BlogPostModel } from '@/models';

interface IChunk {
  letter: string;
  entries: BlogPostModel[];
}

@Component<FAQIndex>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.faq.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.faq.meta-description').toString() }],
    };
  },
})
class FAQIndex extends Vue {
  protected readonly ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  @Getter
  protected faqEntries!: BlogPostModel[];

  @Action
  protected getFAQEntries!: () => void;

  protected async mounted() {
    this.getFAQEntries();
  }

  protected get entries() {
    return this.faqEntries;
  }

  protected get faqEntriesByFirstLetters() {
    const chunks: IChunk[] = [];
    this.ALPHABET.forEach(letter => {
      chunks.push({
        letter,
        entries: filter(this.faqEntries, e => e.title.toUpperCase().startsWith(letter)),
      });
    });
    return chunks;
  }
}

export default FAQIndex;
