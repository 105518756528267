import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { NotificationModel } from '@/models';

import filter from 'lodash/filter';

@Component
class LayoutHeaderNotifications extends Vue {
  @Getter
  protected notifications!: NotificationModel[];

  protected get counter() {
    return filter(this.notifications, { isRead: false }).length;
  }
}

export default LayoutHeaderNotifications;
