import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import { ServiceModel } from '@/models';

@Component<Services>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.services.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.services.meta-description').toString() }],
    };
  },
})
class Services extends Vue {
  protected async mounted() {
    this.getServices();
    this.getCompanies();
  }

  @Getter
  protected services!: ServiceModel[];

  @Action
  protected getServices!: () => void;

  @Action
  protected getCompanies!: () => void;
}

export default Services;
