import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import shuffle from 'lodash/shuffle';

import { ManufacturerModel, UseCaseModel, ServiceModel, CategoryModel } from '@/models';

const AMOUNT_LIST_ITEMS = 7;

@Component
class LayoutFooter extends Vue {
  @Getter('manufacturers')
  protected allManufacturers!: ManufacturerModel[];

  @Getter
  protected useCases!: UseCaseModel[];

  @Getter
  protected services!: ServiceModel[];

  @Getter
  protected categories!: CategoryModel[];

  protected get manufacturers() {
    const size = Math.min(this.allManufacturers.length, AMOUNT_LIST_ITEMS);
    return shuffle(this.allManufacturers).slice(0, size);
  }

  protected get selectedCategories() {
    const size = Math.min(this.categories.length, AMOUNT_LIST_ITEMS);
    return shuffle(this.categories).slice(0, size);
  }
}

export default LayoutFooter;
