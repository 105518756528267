import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';

@Component
class LayoutSnackbarNotification extends Vue {
  protected text = '';
  protected timeout = 5000;
  protected visible = false;

  @Getter
  protected snackbarNotification!: string;

  @Mutation
  protected setSnackbarNotification!: (text: null | string) => void;

  @Watch('snackbarNotification')
  protected onSnackbarNotification() {
    if (this.snackbarNotification !== null) {
      this.visible = true;
      this.text = this.snackbarNotification;
      this.setSnackbarNotification(null);
    }
  }
}

export default LayoutSnackbarNotification;
