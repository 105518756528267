import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<PasswordResetComplete>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.password-reset-complete.title').toString(),
    };
  },
})
class PasswordResetComplete extends Vue {}

export default PasswordResetComplete;
