import { Vue, Component, Prop } from 'vue-property-decorator';

import { RobotModel, ManufacturerModel } from '@/models';
import { Mutation, Getter } from 'vuex-class';

@Component
class RobotWizardStep extends Vue {
  @Prop({ type: Number, required: true })
  protected step!: number;

  @Prop({ type: Number, required: true })
  protected currentStep!: number;

  @Prop({ required: true })
  protected manufacturer!: ManufacturerModel;

  @Getter
  protected robot!: RobotModel | null;

  @Mutation
  protected setRobot!: (robot: RobotModel | null) => void;

  protected nextStep() {
    this.$emit('step:next');
  }

  protected prevStep() {
    this.$emit('step:prev');
  }
}

export default RobotWizardStep;
