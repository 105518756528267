import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/leads/';

export const KIND_SERVICE = 'service';
export const KIND_ROBOT = 'robot';
export const KIND_PRODUCT = 'product';
export const KIND_COMPANY = 'company';
export const KIND_MANUFACTURER = 'manufacturer';

export const SRC_LEADTOOL = 'leadtool';
export const SRC_PROFILE = 'profile';

export const DATA_SELECTED_USECASE = 'selectedUsecase';
export const DATA_TRANSPORT_ITEM = 'selectedTransportItem';
export const DATA_TRANSPORT_DISTANCE = 'transportDistance';
export const DATA_TRANSPORT_PER_HOUR = 'transportPerHour';
export const DATA_PICKING_PROJECT_TYPE = 'projectType';
export const DATA_PICKING_PICKED_GOODS_TYPE = 'pickedGoodsType';
export const DATA_PICKING_SHIFTS_PER_DAY = 'shiftsPerDay';
export const DATA_PICKING_AVAILABLE_SPACE = 'availableSpace';
export const DATA_PICKING_ORDERLINES_PER_DAY = 'orderLinesPerDay';
export const DATA_PICKING_LINES_PER_ORDER = 'linesPerOrder';
export const DATA_PICKING_PICKS_PER_LINE = 'picksPerLine';
export const DATA_CLEANING_AREA = 'cleaningArea';
export const DATA_CLEANING_CYLCES_PER_DAY = 'cleaningCyclesPerDay';
export const DATA_TRANSPORT_MAX_WEIGHT = 'maxWeightPerTransport';
export const DATA_GENERAL_OUTDOOR = 'isOutdoor';
export const DATA_GENERAL_CLEANROOM = 'isCleanroom';
export const DATA_GENERAL_FROST = 'isFrost';

export interface ITranslations {
  [key: string]: {
    description: string;
  };
}

export interface ILead {
  id: number;
  kind: string;
  source: string;
  robots: number[];
  manufacturers: number[];
  useCases: number[];
  services: number[];
  companies: number[];
  products: number[];
  leadCompany: string;
  leadName: string;
  leadEmail: string;
  leadCountry: string;
  leadTelephone: string;
  leadData: string;
  translations: ITranslations;
}

class LeadModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new LeadModel(data));
  }

  public static async create(data: ILead) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new LeadModel(resp.data);
  }

  constructor(protected data: ILead) {}

  public async update(data: ILead) {
    const resp = await axios.patch(API_ENDPOINT + this.id + '/', data);
    this.data = resp.data;
    return this;
  }

  public get rawData() {
    return this.data;
  }

  public get id() {
    return this.data.id;
  }

  public get kind() {
    return this.data.kind;
  }

  public get source() {
    return this.data.source;
  }

  public get leadName() {
    return this.data.leadName;
  }

  public get leadEmail() {
    return this.data.leadEmail;
  }

  public get leadCountry() {
    return this.data.leadCountry;
  }

  public get leadTelephone() {
    return this.data.leadTelephone;
  }

  public get leadCompany() {
    return this.data.leadCompany;
  }

  public get manufacturers() {
    return this.data.manufacturers;
  }

  public get services() {
    return this.data.services;
  }

  public get companies() {
    return this.data.companies;
  }

  public get robots() {
    return this.data.robots;
  }

  public get useCases() {
    return this.data.useCases;
  }

  public get products() {
    return this.data.products;
  }

  public get leadData() {
    return this.data.leadData;
  }

  public toString() {
    return this.id.toString();
  }
}

export default LeadModel;
