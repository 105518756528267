import { Component, Watch, Ref } from 'vue-property-decorator';
import { Mutation, Action, Getter } from 'vuex-class';

import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { LOCALES, LOCALE_EN, LOCALE_DE } from '@/constants/i18n';
import ProductModel, { ITranslations, KIND_PRODUCT_INFRASTRUCTURE, KIND_PRODUCT_ROBOT } from '@/models/product';
import { CategoryModel, RobotModel, ManufacturerModel } from '@/models';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';

import ProductWizardStep from './ProductWizardStep';

const MSG_FORM_INVALID = 'view.product-create.notification.form-invalid';

interface IProductBasicInformation {
  name: string;
  company: number;
  kind: string;
  category: number[];
  robots: number[];
  translations: ITranslations;
}

const defaultBasicInformation: IProductBasicInformation = {
  name: '',
  company: 0,
  category: [],
  robots: [],
  kind: KIND_PRODUCT_ROBOT,
  translations: {
    [LOCALE_EN]: {
      description: '',
      usp1: '',
      usp2: '',
      usp3: '',
    },
    [LOCALE_DE]: {
      description: '',
      usp1: '',
      usp2: '',
      usp3: '',
    },
  },
};

@Component
class ProductWizardStepBasic extends ProductWizardStep {
  protected readonly LOCALES = LOCALES;
  protected readonly rules = rules;
  protected readonly KINDS = [
    {
      value: KIND_PRODUCT_INFRASTRUCTURE,
      text: 'view.product.kind.infrastructure',
    },
    {
      value: KIND_PRODUCT_ROBOT,
      text: 'view.product.kind.robot',
    },
  ];

  protected isValid = false;
  protected isLoading = false;

  protected data: IProductBasicInformation = cloneDeep(defaultBasicInformation);

  @Ref() protected readonly form!: VuetifyForm;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Getter
  protected categories!: CategoryModel[];

  @Getter
  protected robots!: RobotModel[];

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Action
  protected createProduct!: (data: IProductBasicInformation) => ProductModel;

  @Action
  protected updateProduct!: (payload: { product: ProductModel; data: IProductBasicInformation }) => ProductModel;

  @Watch('product', { immediate: true })
  protected async onProductChanged() {
    if (this.product) {
      // @ts-ignore
      this.data = cloneDeep(pick(this.product.rawData, Object.keys(defaultBasicInformation)));
    } else {
      // @ts-ignore
      this.data = cloneDeep(defaultBasicInformation);
    }
  }

  protected get allRobots() {
    return map(this.robots, robot => ({ id: robot.id, label: robot.name + ' (' + this.manufacturerOf(robot) + ')' }));
  }

  protected manufacturerOf(robot: RobotModel) {
    const manufacturer = this.manufacturerByID(robot.manufacturer);
    if (manufacturer) {
      return manufacturer.name;
    }
    return '';
  }

  protected get robotProduct() {
    return KIND_PRODUCT_ROBOT;
  }

  protected async save() {
    if (this.form.validate()) {
      this.isLoading = true;

      // @ts-ignore
      const company = this.company.id;
      const data = { ...this.data, company };
      let product = null;
      if (this.product) {
        product = await this.updateProduct({
          product: this.product,
          data,
        });
      } else {
        product = await this.createProduct(data);
      }
      await this.setProduct(product);

      this.nextStep();

      this.isLoading = false;
    } else {
      this.setSnackbarNotification(this.$t(MSG_FORM_INVALID).toString());
    }
  }

  protected get robotCategories() {
    return filter(this.categories, r => r.kind === KIND_PRODUCT_ROBOT);
  }

  protected get infrastructureCategories() {
    return filter(this.categories, r => r.kind === KIND_PRODUCT_INFRASTRUCTURE);
  }
}

export default ProductWizardStepBasic;
