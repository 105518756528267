import { Component, Watch, Ref } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';

import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import filter from 'lodash/filter';

import RobotModel, {
  SENSORS,
  LOCALIZATIONS,
  KIND_CLEANING,
  KIND_TRANSPORT,
  KIND_COMMISSIONING,
  KIND_MEDICAL,
} from '@/models/robot';
import rules from '@/utils/validation';

import RobotWizardStep from './RobotWizardStep';
import { UseCaseModel } from '@/models';
import { VuetifyForm } from '@/plugins/vuetify';

interface IRobotDetailInformation {
  useCases: number[];
  ceCertificate: boolean | null;
  tuvCertificate: boolean | null;
  outdoor: boolean | null;
  frostEnvironment: boolean | null;
  cleanRoom: boolean | null;
  followMe: boolean | null;
  sensors: string[];
  localization: string[];
  cleaningAreaHour: number;
  cleaningAreaCharge: number;
  cleaningAutoWater: boolean | null;
  cleaningAutoDrain: boolean | null;
  transportMinPickupSpace: number;
}

const defaultDetailInformation: IRobotDetailInformation = {
  useCases: [],
  ceCertificate: false,
  tuvCertificate: false,
  outdoor: false,
  frostEnvironment: false,
  cleanRoom: false,
  followMe: false,
  sensors: [],
  localization: [],
  cleaningAreaHour: 0,
  cleaningAreaCharge: 0,
  cleaningAutoWater: false,
  cleaningAutoDrain: false,
  transportMinPickupSpace: 0,
};

const MSG_FORM_INVALID = 'view.robot-create.notification.form-invalid';

@Component
class RobotWizardStepDetails extends RobotWizardStep {
  protected readonly SENSORS = SENSORS;
  protected readonly LOCALIZATIONS = LOCALIZATIONS;
  protected readonly KIND_CLEANING = KIND_CLEANING;
  protected readonly KIND_COMMISSIONING = KIND_COMMISSIONING;
  protected readonly KIND_MEDICAL = KIND_MEDICAL;
  protected readonly KIND_TRANSPORT = KIND_TRANSPORT;
  protected readonly rules = rules;

  protected isValid = false;
  protected isLoading = false;

  protected data: IRobotDetailInformation = cloneDeep(defaultDetailInformation);

  @Ref() protected readonly form!: VuetifyForm;

  @Getter
  protected robot!: RobotModel;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  @Getter
  protected useCases!: UseCaseModel[];

  @Action
  protected updateRobot!: (payload: { robot: RobotModel; data: IRobotDetailInformation }) => RobotModel;

  @Watch('robot', { immediate: true })
  protected async onRobotChanged() {
    if (this.robot) {
      // @ts-ignore
      this.data = cloneDeep(pick(this.robot.rawData, Object.keys(defaultDetailInformation)));
    }
  }

  protected async save() {
    if (this.form.validate()) {
      this.isLoading = true;

      const robot = await this.updateRobot({
        robot: this.robot,
        data: this.data,
      });
      this.setRobot(robot);

      this.isLoading = false;
      this.nextStep();
    } else {
      this.setSnackbarNotification(this.$t(MSG_FORM_INVALID).toString());
    }
  }

  protected get possibleUseCases() {
    return filter(this.useCases, useCase => useCase.robotKinds.includes(this.robot.kind));
  }
}

export default RobotWizardStepDetails;
