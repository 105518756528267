import { Vue, Component as VComponent } from 'vue-property-decorator';

import rules from '@/utils/validation';

@VComponent
class Newsletter extends Vue {
  protected valid = false;
  protected rules = rules;
}

export default Newsletter;
