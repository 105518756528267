import { Vue, Component, Prop } from 'vue-property-decorator';

import { ManufacturerModel } from '@/models';

@Component
class TileManufacturerPresentation extends Vue {
  @Prop()
  protected manufacturer!: ManufacturerModel;
}

export default TileManufacturerPresentation;
