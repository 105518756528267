import { Vue, Component, Prop } from 'vue-property-decorator';
import { CompanyModel, EventModel } from '@/models';
import { IEvent, TYPE_COMPANY, SOURCE_PROFILE_PAGE, EVENT_WEBSITE, EVENT_TELEPHONE } from '@/models/event';

@Component
class CompanyDetails extends Vue {
  @Prop({ required: true })
  protected company!: CompanyModel;

  @Prop({ type: Boolean, default: false })
  protected isEditor!: boolean;

  protected showTelephone = false;

  protected setEditMode() {
    // @ts-ignore
    this.$router.push({
      ...this.company.route,
      query: {
        edit: '1',
      },
    });
  }

  protected switchTelephone() {
    this.executeTelephone();
    this.showTelephone = !this.showTelephone;
  }

  protected get telephoneNumber() {
    if (this.showTelephone) {
      return this.company.telephone;
    } else {
      return this.$i18n.t('view.premium.call.cta');
    }
  }

  protected executeTelephone() {
    const query: IEvent = {
      type: TYPE_COMPANY.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_TELEPHONE.toString(),
      id: this.company.id,
    };
    EventModel.create(query);
  }

  protected executeWebsite() {
    const query: IEvent = {
      type: TYPE_COMPANY.toString(),
      source: SOURCE_PROFILE_PAGE.toString(),
      event: EVENT_WEBSITE.toString(),
      id: this.company.id,
    };
    EventModel.create(query);
  }
}

export default CompanyDetails;
