import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';
import { IVersionized, VersionizedModel } from './types';

const API_ENDPOINT = '/api/v1/analytics/';

export interface IAnalyticsSearch {
  impressions: number;
  premium: number;
}

export interface IAnalytics {
  id: number;
  // clicks
  clicksFinder: number;
  clicksHomepage: number;
  clicksCompany: number;
  clicksWaku: number;
  clicksProducts: number;
  clicksRelated: number;
  clicksTools: number;
  clicksUsecase: number;
  clicksCategory: number;
  // impressions
  impressions: number;
  // events
  eventTelephone: number;
  eventWebsite: number;
  // search
  searchImpressions: number;
  searchPremium: number;
}

class AnalyticsModel {
  public static async get(t: string, ids: number[]) {
    const resp = await axios.get(API_ENDPOINT + '?type=' + t + '&ids=' + ids.join(','));
    return map(resp.data.entries, data => new AnalyticsModel(data));
  }

  constructor(protected data: IAnalytics) {}

  public get rawData() {
    return this.data;
  }

  public get allClicks() {
    return (
      this.clicksFinder +
      this.clicksHomepage +
      this.clicksCompany +
      this.clicksWaku +
      this.clicksProducts +
      this.clicksRelated +
      this.clicksTools +
      this.clicksUsecase +
      this.clicksCategory
    );
  }

  public get id() {
    return this.data.id;
  }

  public get clicksFinder() {
    return this.data.clicksFinder;
  }

  public get clicksHomepage() {
    return this.data.clicksHomepage;
  }

  public get clicksCompany() {
    return this.data.clicksCompany;
  }

  public get clicksWaku() {
    return this.data.clicksWaku;
  }

  public get clicksProducts() {
    return this.data.clicksProducts;
  }

  public get clicksRelated() {
    return this.data.clicksRelated;
  }

  public get clicksTools() {
    return this.data.clicksTools;
  }

  public get clicksUsecase() {
    return this.data.clicksUsecase;
  }

  public get clicksCategory() {
    return this.data.clicksCategory;
  }

  public get impressions() {
    return this.data.impressions;
  }

  public get eventTelephone() {
    return this.data.eventTelephone;
  }

  public get eventWebsite() {
    return this.data.eventWebsite;
  }

  public get searchImpressions() {
    return this.data.searchImpressions;
  }

  public get searchPremium() {
    return this.data.searchPremium;
  }
}

export default AnalyticsModel;
