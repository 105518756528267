import marked from 'marked'; // @ts-ignore

import map from 'lodash/map';

import axios from '@/axios';
import store from '@/store';
import router from '@/router';
import i18n, { i18nRoute } from '@/plugins/i18n';

const API_ENDPOINT = '/api/v1/click/';

export const SOURCE_HOMEPAGE = 'homepage';
export const SOURCE_FINDER = 'finder';
export const SOURCE_RELATED = 'related';

export const TYPE_ROBOT = 'robot';
export const TYPE_MANUFACTURER = 'manufacturer';
export const TYPE_PRODUCT = 'product';
export const TYPE_COMPANY = 'company';

// robot sources: company, homepage, waku, products, related, finder, tools, usecase
// product sources: company, category, related, finder
// company sources: related, finder, category, tools
// manufacturer sources: related, finder, homepage, tools
export interface IClick {
  // source of click
  source: string;
  // type of clicked entity
  type: string;
  // id of clicked entity
  id: number;
}

class ClickModel {
  public static async list() {
    const resp = await axios.get(API_ENDPOINT);
    return map(resp.data.results, data => new ClickModel(data));
  }

  public static async create(data: IClick) {
    const resp = await axios.post(API_ENDPOINT, data);
    return new ClickModel(resp.data);
  }
  constructor(protected data: IClick) {}

  public get rawData() {
    return this.data;
  }
}

export default ClickModel;
