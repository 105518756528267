import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import take from 'lodash/take';

import { BlogPostModel, ManufacturerModel } from '@/models';

@Component<ManufacturerNewsPage>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.manufacturer-news-page.title', { entity: this.entity.name }).toString(),
      meta: [
        {
          name: 'description',
          content: this.$i18n
            .t('page.manufacturer-news-page.meta-description', { entity: this.entity.name })
            .toString(),
        },
      ],
    };
  },
})
class ManufacturerNewsPage extends Vue {
  @Getter
  protected manufacturerNews!: BlogPostModel[];

  @Getter
  protected manufacturers!: ManufacturerModel[];

  @Getter
  protected manufacturerBySlug!: (slug: string) => ManufacturerModel;

  @Action
  protected getManufacturerNews!: () => void;

  protected async mounted() {
    this.getManufacturerNews();
  }

  protected get entity() {
    const { manufacturerSlug } = this.$route.params;
    return this.manufacturerBySlug(manufacturerSlug);
  }

  protected get post() {
    return take(
      orderBy(
        filter(this.manufacturerNews, m => m.manufacturers.includes(this.entity.id)),
        '-date_published',
      ),
      5,
    );
  }
}

export default ManufacturerNewsPage;
