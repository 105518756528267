import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { ProductModel, CompanyModel, ClickModel } from '@/models';
import { TYPE_PRODUCT, IClick } from '@/models/click';

@Component
class TileProduct extends Vue {
  @Prop()
  protected product!: ProductModel;

  @Prop({ type: Boolean, default: false })
  protected wide!: boolean;

  @Prop({ type: String, default: 'none' })
  protected source!: string;

  @Getter
  protected companyByID!: (id: number) => CompanyModel;

  protected get company() {
    return this.companyByID(this.product.company);
  }

  protected executeClick() {
    const query: IClick = {
      // source of search
      source: this.source,
      // robot
      type: TYPE_PRODUCT.toString(),
      id: this.product.id,
    };
    ClickModel.create(query);
  }
}

export default TileProduct;
