import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import { BlogPostModel, ManufacturerModel } from '@/models';
import { EntityDetailView } from '@/utils/views';
import { i18nRoute } from '@/plugins/i18n';
import { MAX_META_DESCRIPTION_LENGTH } from '@/constants/meta';

@Component<ManufacturerNewsPost>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity
        ? this.entity.title + ' - ' + this.$i18n.t('page.manufacturer-news-post.title').toString()
        : this.$i18n.t('page.manufacturer-news-post.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.entity
            ? this.entity.metaDescription !== ''
              ? this.entity.metaDescription
              : this.entity.content.replace(/<(.|\n)*?>/g, '').substr(MAX_META_DESCRIPTION_LENGTH)
            : this.$i18n.t('page.manufacturer-news-post.meta-description').toString(),
        },
      ],
    };
  },
})
class ManufacturerNewsPost extends EntityDetailView {
  @Getter
  protected manufacturerNewsPostBySlug!: (slug: string) => BlogPostModel;

  @Getter
  protected manufacturerByID!: (id: number) => ManufacturerModel;

  @Action
  protected getManufacturerNews!: () => void;

  protected async mounted() {
    this.getManufacturerNews();
  }

  protected get entity() {
    const { manufacturerNewsSlug } = this.$route.params;
    return this.manufacturerNewsPostBySlug(manufacturerNewsSlug);
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.manufacturer-news-post.breadcrumb.news').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'manufacturer-news' }),
      },
      {
        text: this.entity.title,
        disabled: true,
        href: this.entity.route,
      },
    ];
  }

  protected get relatedManufacturer() {
    if (this.entity.manufacturers.length > 0) {
      return this.manufacturerByID(this.entity.manufacturers[0]);
    }
    return null;
  }
}
export default ManufacturerNewsPost;
