import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';

import slice from 'lodash/slice';
import filter from 'lodash/filter';

import { BlogPostModel } from '@/models';
import { EntityDetailView } from '@/utils/views';
import { i18nRoute } from '@/plugins/i18n';
import { MAX_META_DESCRIPTION_LENGTH } from '@/constants/meta';

const MAX_SIMILAR_POSTS = 3;

@Component<BlogPost>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity
        ? this.entity.title + ' - ' + this.$i18n.t('page.blog.title').toString()
        : this.$i18n.t('page.blog.title').toString(),
      meta: [
        {
          name: 'description',
          content: this.entity
            ? this.entity.metaDescription !== ''
              ? this.entity.metaDescription
              : this.entity.content.replace(/<(.|\n)*?>/g, '').substr(MAX_META_DESCRIPTION_LENGTH)
            : this.$i18n.t('page.blog.meta-description').toString(),
        },
      ],
    };
  },
})
class BlogPost extends EntityDetailView {
  @Getter
  protected blogPostBySlug!: (slug: string) => BlogPostModel;

  @Getter
  protected blogPostsByTags!: (tags: number[]) => BlogPostModel[];

  @Action
  protected getBlogPosts!: () => void;

  protected isLoading = true;

  protected async mounted() {
    this.loadData();
  }

  @Watch('isBooting')
  protected loadData() {
    if (this.isBooting === false) {
      this.getBlogPosts();
      this.isLoading = false;
    }
  }

  @Watch('isLoading')
  protected get entity() {
    const { blogPostSlug } = this.$route.params;
    return this.blogPostBySlug(blogPostSlug);
  }

  protected get breadcrumbItems() {
    return [
      {
        text: this.$t('view.blog-post.breadcrumb.magazine').toString(),
        disabled: false,
        exact: true,
        to: i18nRoute({ name: 'blog' }),
      },
      {
        text: this.entity.title,
        disabled: true,
        href: this.entity.route,
      },
    ];
  }

  protected get similarPosts() {
    return slice(
      filter(this.blogPostsByTags(this.entity.tags), post => post.id !== this.entity.id),
      0,
      MAX_SIMILAR_POSTS,
    );
  }
}

export default BlogPost;
