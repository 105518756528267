import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import map from 'lodash/map';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';

import { CompanyModel } from '@/models';
import countries from 'i18n-iso-countries';
import { getCountryName } from '@/plugins/i18n';
import { KIND_COMPANY_COMPONENT } from '@/models/company';

const containsFully = (set: any[], subset: any[]) => intersection(set, subset).length === subset.length;

interface IFilters {
  services: number[];
  countries: string[];
}

const FILTER_DEFAULTS: IFilters = {
  services: [],
  countries: [],
};

@Component<ProductCompanyFinder>({
  metaInfo(): MetaInfo {
    return {
      title: this.$i18n.t('page.product-company-finder.title').toString(),
      meta: [{ name: 'description', content: this.$i18n.t('page.product-company-finder.meta-description').toString() }],
    };
  },
})
class ProductCompanyFinder extends Vue {
  protected showFilterCountries = true;

  @Getter
  protected companies!: CompanyModel[];

  // Search Results
  protected results: CompanyModel[] = [];

  // Search Filters
  protected filters: IFilters = clone(FILTER_DEFAULTS);

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getAds!: () => void;

  protected async mounted() {
    this.getCompanies();
    this.getAds();
  }

  @Watch('companies', { immediate: true })
  @Watch('filters', { deep: true })
  protected search() {
    let results = this.companies;

    results = filter(results, m => m.kind === KIND_COMPANY_COMPONENT && !!m.logo);

    if (this.filters.countries.length) {
      results = filter(results, r => containsFully(r.countries, this.filters.countries));
    }

    this.results = results;
  }

  protected get companySpotlight() {
    return filter(this.companies, m => m.isSpotlight && m.kind === KIND_COMPANY_COMPONENT);
  }

  protected get allCountries() {
    const codes = countries.getAlpha3Codes();
    return map(codes, code => ({ id: code, label: getCountryName(code) }));
  }

  protected clearFilters() {
    this.filters = clone(FILTER_DEFAULTS);
  }
}

export default ProductCompanyFinder;
