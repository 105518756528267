import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import InquiryModel, { KIND_RENT, KIND_BUY } from '@/models/inquiry';
import { RobotModel } from '@/models';
import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';

const MSG_INQUIRY_SUCCESS = 'component.dialog.robot.inquiry.notification.success';

@Component
class DialogInquiry extends Vue {
  protected rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  @Prop({ type: Boolean, default: false })
  protected rent!: boolean;

  @Prop()
  protected robot!: RobotModel;

  @Mutation
  protected setSnackbarNotification!: (msg: string) => void;

  protected isOpen = false;
  protected isValid = false;
  protected isLoading = false;

  protected firstName = '';
  protected lastName = '';
  protected company = '';
  protected message = '';
  protected email = '';
  protected phone = '';
  protected acceptPolicy = false;

  protected async submit() {
    if (this.form.validate()) {
      this.isLoading = true;

      const { firstName, lastName, company, message, email, phone } = this;
      const resp = await InquiryModel.create({
        kind: this.rent ? KIND_RENT : KIND_BUY,
        robot: this.robot.id,
        firstName,
        lastName,
        company,
        message,
        email,
        phone,
      });

      this.isLoading = false;
      this.setSnackbarNotification(this.$t(MSG_INQUIRY_SUCCESS).toString());
      this.isOpen = false;
    }
  }
}

export default DialogInquiry;
