import { Vue, Component, Prop } from 'vue-property-decorator';

import { BlogPostModel } from '@/models';

@Component
class TileManufacturerNews extends Vue {
  @Prop()
  protected post!: BlogPostModel;
}

export default TileManufacturerNews;
