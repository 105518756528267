import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Getter, Action } from 'vuex-class';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { EntityDetailView } from '@/utils/views';
import { UserModel, CompanyModel, ProductModel, RobotModel, ImpressionModel } from '@/models';
import { TYPE_PRODUCT, IImpression } from '@/models/impression';

@Component<Product>({
  metaInfo(): MetaInfo {
    return {
      title: this.entity ? this.entity.name : '',
      meta: [
        {
          name: 'description',
          content: this.entity ? this.entity.description : '',
        },
      ],
    };
  },
})
class Product extends EntityDetailView {
  @Getter
  protected user!: UserModel;

  @Getter
  protected productBySlug!: (slug: string) => ProductModel;

  @Getter
  protected productsByCompany!: (id: number) => ProductModel[];

  @Getter
  protected companyBySlug!: (slug: string) => CompanyModel;

  @Getter
  protected robotByID!: (id: number) => RobotModel;

  @Action
  protected getProducts!: () => void;

  @Action
  protected getCompanies!: () => void;

  @Action
  protected getCategories!: () => void;

  protected async mounted() {
    this.getCompanies();
    this.getProducts();
    this.getCategories();
  }

  protected get entity(): ProductModel | undefined {
    const { productSlug } = this.$route.params;
    if (this.company !== undefined) {
      const product = this.productBySlug(productSlug);
      return product && product.company === this.company.id ? product : undefined;
    }
    return undefined;
  }

  protected get company(): CompanyModel | undefined {
    const { companySlug } = this.$route.params;
    return this.companyBySlug(companySlug);
  }

  protected get products() {
    if (this.company && this.entity) {
      const products = this.productsByCompany(this.company.id);
      return filter(products, r => r.id !== this.entity!.id);
    }
    return [];
  }

  protected get activeRobots() {
    if (this.entity) {
      const robots = map(this.entity.robots, id => this.robotByID(id));
      return filter(robots, c => !!c);
    }
    return [];
  }

  protected get isEditor() {
    this.executeImpression();
    return this.entity && this.company && this.user && this.user.companies.includes(this.company.id);
  }

  protected get editMode() {
    return this.isEditor && 'edit' in this.$route.query;
  }

  protected executeImpression() {
    if (this.entity) {
      const query: IImpression = {
        type: TYPE_PRODUCT.toString(),
        id: this.entity.id,
      };
      ImpressionModel.create(query);
    }
  }
}

export default Product;
